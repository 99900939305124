import Button from '@mui/material/Button';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';

import { StripeChargesApi } from 'src/api-client';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { useOnError } from 'src/hooks/useOnError';

type Props = {
  onSuccess: () => void;
};

export const TrialElement: React.FC<Props> = ({ onSuccess }) => {
  const { sendAnalyticsEvent } = useAnalytics();
  const { onError } = useOnError();

  useEffect(() => {
    sendAnalyticsEvent('user_view_trial_cta');
  }, []);

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: () => StripeChargesApi.v1StripeChargesPost(),
    onSuccess,
    onError,
  });

  return (
    <Button
      variant="contained"
      size="large"
      style={{ marginBottom: 40 }}
      onClick={() => {
        sendAnalyticsEvent('user_clicked_trial_cta');
        mutateAsync();
      }}
      disabled={isLoading}
    >
      Inicia prueba gratuita
    </Button>
  );
};
