import { ElementType, Suspense, lazy } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';

import { CabalPlusGuard } from 'src/guards/CabalPlusGuard';
import { CabalPlusGuestGuard } from 'src/guards/CabalPlusGuestGuard';
import { InvitesGuard } from 'src/guards/InvitesGuard';
import { PermissionGuard } from 'src/guards/PermissionGuard';
import { PersonalAccountGuard } from 'src/guards/PersonalAccountGuard';
import RoleBasedGuard from 'src/guards/RoleBasedGuard';
import { usePermissions } from 'src/hooks/usePermissions';
import SimpleLayout from 'src/layouts/SimpleLayout';

import LoadingScreen from '../components/LoadingScreen';
import { PATH_AFTER_LOGIN } from '../config';
import AuthGuard from '../guards/AuthGuard';
import { BusinessAccountGuard } from '../guards/BusinessAccountGuard';
import GuestGuard from '../guards/GuestGuard';
import useAuth from '../hooks/useAuth';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import DashboardLayout from '../layouts/dashboard';
import { lazyRetry } from './lazyRetry';
import { PATH_DASHBOARD } from './paths';

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { isAccountType } = usePermissions();

  return useRoutes([
    {
      path: '/',
      element: <SimpleLayout />,
      children: [
        {
          path: '/',
          element: (
            <GuestGuard>
              <SignUp />
            </GuestGuard>
          ),
        },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'plus',
          element: (
            <GuestGuard>
              <Plus />
            </GuestGuard>
          ),
        },
        {
          path: 'recuperar-contrasena',
          element: (
            <GuestGuard>
              <PasswordForgot />
            </GuestGuard>
          ),
        },
        {
          path: 'cambiar-contrasena',
          element: (
            <GuestGuard>
              <PasswordReset />
            </GuestGuard>
          ),
        },
        {
          path: 'verificar-email',
          element: (
            <GuestGuard>
              <VerifyEmail />
            </GuestGuard>
          ),
        },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: (
            <Navigate
              to={
                isAccountType('business')
                  ? PATH_AFTER_LOGIN
                  : PATH_DASHBOARD.general.app
              }
              replace
            />
          ),
          index: true,
        },
        { path: 'finalizar-registro', element: <FinishRegistration /> },
        // {
        //   path: 'cabal-card',
        //   children: [
        //     {
        //       element: <Navigate to={PATH_DASHBOARD.cabalCard.root} replace />,
        //       index: true,
        //     },
        //     {
        //       path: 'tablero',
        //       element: (
        //         <PersonalAccountGuard hasContent>
        //           <RoleBasedGuard hasContent roles={['owner']}>
        //             <CCDashboard />
        //           </RoleBasedGuard>
        //         </PersonalAccountGuard>
        //       ),
        //     },
        //     {
        //       path: 'verificar-cuenta',
        //       element: (
        //         <PersonalAccountGuard hasContent>
        //           <RoleBasedGuard hasContent roles={['owner']}>
        //             <VerifyAccount />
        //           </RoleBasedGuard>
        //         </PersonalAccountGuard>
        //       ),
        //     },
        //   ],
        // },
        {
          path: 'app',
          element: (
            <RoleBasedGuard hasContent roles={['owner']}>
              <GeneralApp />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'tutoriales',
          element: <Tutorials />,
        },
        {
          path: 'cotizaciones',
          element: (
            <BusinessAccountGuard hasContent>
              <CabalPlusGuard hasContent>
                <QuotesIndex />
              </CabalPlusGuard>
            </BusinessAccountGuard>
          ),
        },
        {
          path: 'cotizaciones/:id',
          element: (
            <BusinessAccountGuard hasContent>
              <CabalPlusGuard hasContent>
                <QuoteDetail />
              </CabalPlusGuard>
            </BusinessAccountGuard>
          ),
        },
        {
          path: 'nueva-venta',
          element: (
            <BusinessAccountGuard hasContent>
              <CabalPlusGuestGuard hasContent>
                <TransactionSaleCreate />
              </CabalPlusGuestGuard>
            </BusinessAccountGuard>
          ),
        },
        {
          path: 'venta/:id',
          element: (
            <BusinessAccountGuard hasContent>
              <CabalPlusGuestGuard hasContent>
                <TransactionSaleDetail />
              </CabalPlusGuestGuard>
            </BusinessAccountGuard>
          ),
        },
        {
          path: 'venta/:id/editar',
          element: (
            <BusinessAccountGuard hasContent>
              <CabalPlusGuestGuard hasContent>
                <TransactionSaleEdit />
              </CabalPlusGuestGuard>
            </BusinessAccountGuard>
          ),
        },
        {
          path: 'nuevo-ingreso',
          element: (
            <PersonalAccountGuard hasContent>
              <TransactionIncomeCreate />
            </PersonalAccountGuard>
          ),
        },
        {
          path: 'ingreso/:id',
          element: (
            <PersonalAccountGuard hasContent>
              <TransactionIncomeDetail />
            </PersonalAccountGuard>
          ),
        },
        {
          path: 'ingreso/:id/editar',
          element: (
            <PersonalAccountGuard hasContent>
              <TransactionIncomeEdit />
            </PersonalAccountGuard>
          ),
        },
        {
          path: 'nuevo-gasto',
          element: (
            <CabalPlusGuestGuard hasContent>
              <TransactionExpenseCreate />
            </CabalPlusGuestGuard>
          ),
        },
        {
          path: 'gasto/:id',
          element: (
            <CabalPlusGuestGuard hasContent>
              <TransactionExpenseDetail />
            </CabalPlusGuestGuard>
          ),
        },
        {
          path: 'gasto/:id/editar',
          element: (
            <CabalPlusGuestGuard hasContent>
              <TransactionExpenseEdit />
            </CabalPlusGuestGuard>
          ),
        },
        {
          path: 'deudas/por-transaccion',
          element: (
            <CabalPlusGuestGuard hasContent>
              <DebtsTransactionList />
            </CabalPlusGuestGuard>
          ),
        },
        {
          path: 'deudas/por-usuario',
          element: (
            <CabalPlusGuestGuard hasContent>
              <DebtsUserList />
            </CabalPlusGuestGuard>
          ),
        },
        {
          path: 'deudas/por-usuario/:id',
          element: (
            <CabalPlusGuestGuard hasContent>
              <DebtsUserDetail />
            </CabalPlusGuestGuard>
          ),
        },
        {
          path: 'ordenes-web',
          element: (
            <BusinessAccountGuard hasContent>
              <CabalPlusGuestGuard hasContent>
                <WebSalesList />
              </CabalPlusGuestGuard>
            </BusinessAccountGuard>
          ),
        },
        {
          path: 'orden-web/:id',
          element: (
            <BusinessAccountGuard hasContent>
              <CabalPlusGuestGuard hasContent>
                <WebSalesDetail />
              </CabalPlusGuestGuard>
            </BusinessAccountGuard>
          ),
        },
        {
          path: 'organizaciones',
          children: [
            {
              element: (
                <BusinessAccountGuard hasContent>
                  <Navigate to={PATH_DASHBOARD.POS.root} replace />
                </BusinessAccountGuard>
              ),
              index: true,
            },
            {
              path: 'todas',
              element: (
                <BusinessAccountGuard hasContent>
                  <OrganizationsIndex />
                </BusinessAccountGuard>
              ),
            },
            {
              path: 'roles-permisos',
              element: (
                <RoleBasedGuard hasContent roles={['owner']}>
                  <BusinessAccountGuard hasContent>
                    <CabalPlusGuard hasContent>
                      <RolesPermissionsIndex />
                    </CabalPlusGuard>
                  </BusinessAccountGuard>
                </RoleBasedGuard>
              ),
            },
            {
              path: 'personalizacion',
              element: (
                <RoleBasedGuard hasContent roles={['owner']}>
                  <BusinessAccountGuard hasContent>
                    <CabalPlusGuard hasContent>
                      <Personalization />
                    </CabalPlusGuard>
                  </BusinessAccountGuard>
                </RoleBasedGuard>
              ),
            },
            {
              path: 'importar-datos',
              element: (
                <BusinessAccountGuard hasContent>
                  <CabalPlusGuard hasContent>
                    <PermissionGuard
                      hasContent
                      permissions={['massive_import']}
                    >
                      <BulkUpload />
                    </PermissionGuard>
                  </CabalPlusGuard>
                </BusinessAccountGuard>
              ),
            },
          ],
        },
        {
          path: 'tienda',
          children: [
            {
              element: (
                <BusinessAccountGuard hasContent>
                  <CabalPlusGuestGuard hasContent>
                    <Store />
                  </CabalPlusGuestGuard>
                </BusinessAccountGuard>
              ),
              index: true,
            },
            {
              path: 'configuracion',
              element: (
                <BusinessAccountGuard hasContent>
                  <PermissionGuard
                    hasContent
                    permissions={['web_configure_store']}
                  >
                    <CabalPlusGuestGuard hasContent>
                      <StoreSettings />
                    </CabalPlusGuestGuard>
                  </PermissionGuard>
                </BusinessAccountGuard>
              ),
            },
            {
              path: 'pagos',
              element: (
                <BusinessAccountGuard hasContent>
                  <PermissionGuard
                    hasContent
                    permissions={['web_configure_store']}
                  >
                    <CabalPlusGuestGuard hasContent>
                      <StorePaymentSettings />
                    </CabalPlusGuestGuard>
                  </PermissionGuard>
                </BusinessAccountGuard>
              ),
            },
          ],
        },
        {
          path: 'tus-ofertas',
          children: [
            {
              element: (
                <BusinessAccountGuard hasContent>
                  <PermissionGuard
                    hasContent
                    permissions={['web_manage_offers']}
                  >
                    <CabalPlusGuard hasContent>
                      <Campaigns />
                    </CabalPlusGuard>
                  </PermissionGuard>
                </BusinessAccountGuard>
              ),
              index: true,
            },
            {
              path: 'cupones',
              element: (
                <BusinessAccountGuard hasContent>
                  <PermissionGuard
                    hasContent
                    permissions={['web_manage_offers']}
                  >
                    <CabalPlusGuard hasContent>
                      <Coupons />
                    </CabalPlusGuard>
                  </PermissionGuard>
                </BusinessAccountGuard>
              ),
            },
            {
              path: 'cupones/:id',
              element: (
                <BusinessAccountGuard hasContent>
                  <PermissionGuard
                    hasContent
                    permissions={['web_manage_offers']}
                  >
                    <CabalPlusGuard hasContent>
                      <CouponsEdit />
                    </CabalPlusGuard>
                  </PermissionGuard>
                </BusinessAccountGuard>
              ),
            },
            {
              path: 'descuentos',
              element: (
                <BusinessAccountGuard hasContent>
                  <PermissionGuard
                    hasContent
                    permissions={['web_manage_offers']}
                  >
                    <CabalPlusGuard hasContent>
                      <Discounts />
                    </CabalPlusGuard>
                  </PermissionGuard>
                </BusinessAccountGuard>
              ),
            },
            {
              path: 'descuentos/:id',
              element: (
                <BusinessAccountGuard hasContent>
                  <PermissionGuard
                    hasContent
                    permissions={['web_manage_offers']}
                  >
                    <CabalPlusGuard hasContent>
                      <DiscountsEdit />
                    </CabalPlusGuard>
                  </PermissionGuard>
                </BusinessAccountGuard>
              ),
            },
            {
              path: 'descuentos/duplicar/:id',
              element: (
                <BusinessAccountGuard hasContent>
                  <PermissionGuard
                    hasContent
                    permissions={['web_manage_offers']}
                  >
                    <CabalPlusGuard hasContent>
                      <DiscountsDuplicate />
                    </CabalPlusGuard>
                  </PermissionGuard>
                </BusinessAccountGuard>
              ),
            },
          ],
        },
        {
          path: 'asistente-financiero',
          children: [
            {
              element: (
                <BusinessAccountGuard hasContent>
                  <PermissionGuard
                    hasContent
                    permissions={['acc_financial_statement']}
                  >
                    <CabalPlusGuard hasContent>
                      <FinancialAssistant />
                    </CabalPlusGuard>
                  </PermissionGuard>
                </BusinessAccountGuard>
              ),
              index: true,
            },
          ],
        },
        {
          path: 'estados-financieros',
          children: [
            {
              element: (
                <BusinessAccountGuard hasContent>
                  <PermissionGuard
                    hasContent
                    permissions={['acc_financial_statement']}
                  >
                    <CabalPlusGuard hasContent>
                      <EEFF />
                    </CabalPlusGuard>
                  </PermissionGuard>
                </BusinessAccountGuard>
              ),
              index: true,
            },
          ],
        },
        {
          path: 'pdv',
          children: [
            {
              element: (
                <BusinessAccountGuard hasContent>
                  <CabalPlusGuestGuard hasContent>
                    <Navigate to={PATH_DASHBOARD.POS.root} replace />
                  </CabalPlusGuestGuard>
                </BusinessAccountGuard>
              ),
              index: true,
            },
            {
              path: 'inicio',
              element: (
                <BusinessAccountGuard hasContent>
                  <POSIndex />
                </BusinessAccountGuard>
              ),
            },
            {
              path: 'nueva-venta',
              element: (
                <BusinessAccountGuard hasContent>
                  <CabalPlusGuestGuard hasContent>
                    <POSSales />
                  </CabalPlusGuestGuard>
                </BusinessAccountGuard>
              ),
            },
            {
              path: 'editar-venta/:id',
              element: (
                <BusinessAccountGuard hasContent>
                  <CabalPlusGuestGuard hasContent>
                    <POSSalesEdit />
                  </CabalPlusGuestGuard>
                </BusinessAccountGuard>
              ),
            },
            {
              path: 'ordenes/:id',
              element: (
                <BusinessAccountGuard hasContent>
                  <CabalPlusGuestGuard hasContent>
                    <POSSalesDetail />
                  </CabalPlusGuestGuard>
                </BusinessAccountGuard>
              ),
            },
            {
              path: 'ordenes',
              element: (
                <BusinessAccountGuard hasContent>
                  <CabalPlusGuestGuard hasContent>
                    <POSSalesList />
                  </CabalPlusGuestGuard>
                </BusinessAccountGuard>
              ),
            },
            {
              path: 'analiticas',
              element: (
                <BusinessAccountGuard hasContent>
                  <PermissionGuard
                    hasContent
                    permissions={['pdv_see_analytics']}
                  >
                    <CabalPlusGuestGuard hasContent>
                      <POSAnalitycs />
                    </CabalPlusGuestGuard>
                  </PermissionGuard>
                </BusinessAccountGuard>
              ),
            },
          ],
        },
        {
          path: 'productos',
          children: [
            {
              element: <Navigate to="/dashboard/productos/todos" replace />,
              index: true,
            },
            {
              path: 'todos',
              element: (
                <BusinessAccountGuard hasContent>
                  <PermissionGuard
                    hasContent
                    permissions={['inventory_see_catalog']}
                  >
                    <CabalPlusGuestGuard hasContent>
                      <ProductsList />
                    </CabalPlusGuestGuard>
                  </PermissionGuard>
                </BusinessAccountGuard>
              ),
            },
            {
              path: 'analiticas',
              element: (
                <BusinessAccountGuard hasContent>
                  <PermissionGuard
                    hasContent
                    permissions={['inventory_stock_analytics']}
                  >
                    <CabalPlusGuard hasContent>
                      <ProductAnalitycs />
                    </CabalPlusGuard>
                  </PermissionGuard>
                </BusinessAccountGuard>
              ),
            },
            {
              path: 'nuevo',
              element: (
                <BusinessAccountGuard hasContent>
                  <PermissionGuard
                    hasContent
                    permissions={['inventory_create_products']}
                  >
                    <CabalPlusGuestGuard hasContent>
                      <ProductCreate />
                    </CabalPlusGuestGuard>
                  </PermissionGuard>
                </BusinessAccountGuard>
              ),
            },
            {
              path: ':id',
              element: (
                <BusinessAccountGuard hasContent>
                  <CabalPlusGuestGuard hasContent>
                    <ProductDetails />
                  </CabalPlusGuestGuard>
                </BusinessAccountGuard>
              ),
            },
            {
              path: ':id/editar',
              element: (
                <BusinessAccountGuard hasContent>
                  <PermissionGuard
                    hasContent
                    permissions={['inventory_edit_products']}
                  >
                    <CabalPlusGuestGuard hasContent>
                      <ProductEdit />
                    </CabalPlusGuestGuard>
                  </PermissionGuard>
                </BusinessAccountGuard>
              ),
            },
          ],
        },
        {
          path: 'categoria-de-productos',
          children: [
            {
              element: (
                <Navigate
                  to="/dashboard/categoria-de-productos/todos"
                  replace
                />
              ),
              index: true,
            },
            {
              path: 'todos',
              element: (
                <BusinessAccountGuard hasContent>
                  <PermissionGuard
                    hasContent
                    permissions={['acc_list_categories']}
                  >
                    <CabalPlusGuestGuard hasContent>
                      <ProductCategoryList />
                    </CabalPlusGuestGuard>
                  </PermissionGuard>
                </BusinessAccountGuard>
              ),
            },
            {
              path: 'nuevo',
              element: (
                <BusinessAccountGuard hasContent>
                  <PermissionGuard
                    hasContent
                    permissions={['acc_create_categories']}
                  >
                    <CabalPlusGuestGuard hasContent>
                      <ProductCategoryCreate />
                    </CabalPlusGuestGuard>
                  </PermissionGuard>
                </BusinessAccountGuard>
              ),
            },
            {
              path: ':id/editar',
              element: (
                <BusinessAccountGuard hasContent>
                  <PermissionGuard
                    hasContent
                    permissions={['acc_update_categories']}
                  >
                    <CabalPlusGuestGuard hasContent>
                      <ProductCategoryEdit />
                    </CabalPlusGuestGuard>
                  </PermissionGuard>
                </BusinessAccountGuard>
              ),
            },
          ],
        },
        {
          path: 'adicionales',
          children: [
            {
              element: <Navigate to="/dashboard/adicionales/todos" replace />,
              index: true,
            },
            {
              path: 'todos',
              element: (
                <BusinessAccountGuard hasContent>
                  <PermissionGuard
                    hasContent
                    permissions={['inventory_create_products']}
                  >
                    <CabalPlusGuard hasContent>
                      <ModifierList />
                    </CabalPlusGuard>
                  </PermissionGuard>
                </BusinessAccountGuard>
              ),
            },
            {
              path: 'nuevo',
              element: (
                <BusinessAccountGuard hasContent>
                  <PermissionGuard
                    hasContent
                    permissions={['inventory_create_products']}
                  >
                    <CabalPlusGuard hasContent>
                      <ModifierCreate />
                    </CabalPlusGuard>
                  </PermissionGuard>
                </BusinessAccountGuard>
              ),
            },
            {
              path: ':id/editar',
              element: (
                <BusinessAccountGuard hasContent>
                  <PermissionGuard
                    hasContent
                    permissions={['inventory_create_products']}
                  >
                    <CabalPlusGuard hasContent>
                      <ModifierEdit />
                    </CabalPlusGuard>
                  </PermissionGuard>
                </BusinessAccountGuard>
              ),
            },
          ],
        },
        {
          path: 'servicios',
          children: [
            {
              element: <Navigate to="/dashboard/servicios/todos" replace />,
              index: true,
            },
            {
              path: 'todos',
              element: (
                <CabalPlusGuard hasContent>
                  <BusinessAccountGuard hasContent>
                    <PermissionGuard
                      hasContent
                      permissions={['service_see_catalog']}
                    >
                      <CabalPlusGuestGuard hasContent>
                        <ServiceList />
                      </CabalPlusGuestGuard>
                    </PermissionGuard>
                  </BusinessAccountGuard>
                </CabalPlusGuard>
              ),
            },
            {
              path: 'nuevo',
              element: (
                <CabalPlusGuard hasContent>
                  <BusinessAccountGuard hasContent>
                    <PermissionGuard
                      hasContent
                      permissions={['service_create']}
                    >
                      <CabalPlusGuestGuard hasContent>
                        <ServiceCreate />
                      </CabalPlusGuestGuard>
                    </PermissionGuard>
                  </BusinessAccountGuard>
                </CabalPlusGuard>
              ),
            },
            {
              path: ':id/editar',
              element: (
                <CabalPlusGuard hasContent>
                  <BusinessAccountGuard hasContent>
                    <PermissionGuard hasContent permissions={['service_edit']}>
                      <CabalPlusGuestGuard hasContent>
                        <ServiceEdit />
                      </CabalPlusGuestGuard>
                    </PermissionGuard>
                  </BusinessAccountGuard>
                </CabalPlusGuard>
              ),
            },
          ],
        },
        {
          path: 'categoria-de-servicios',
          children: [
            {
              element: (
                <Navigate
                  to="/dashboard/categoria-de-servicios/todos"
                  replace
                />
              ),
              index: true,
            },
            {
              path: 'todos',
              element: (
                <CabalPlusGuard hasContent>
                  <BusinessAccountGuard hasContent>
                    <PermissionGuard
                      hasContent
                      permissions={['acc_list_service_categories']}
                    >
                      <CabalPlusGuestGuard hasContent>
                        <ServiceCategoryList />
                      </CabalPlusGuestGuard>
                    </PermissionGuard>
                  </BusinessAccountGuard>
                </CabalPlusGuard>
              ),
            },
            {
              path: 'nuevo',
              element: (
                <CabalPlusGuard hasContent>
                  <BusinessAccountGuard hasContent>
                    <PermissionGuard
                      hasContent
                      permissions={['acc_create_categories']}
                    >
                      <CabalPlusGuestGuard hasContent>
                        <ServiceCategoryCreate />
                      </CabalPlusGuestGuard>
                    </PermissionGuard>
                  </BusinessAccountGuard>
                </CabalPlusGuard>
              ),
            },
            {
              path: ':id/editar',
              element: (
                <CabalPlusGuard hasContent>
                  <BusinessAccountGuard hasContent>
                    <PermissionGuard
                      hasContent
                      permissions={['acc_update_categories']}
                    >
                      <CabalPlusGuestGuard hasContent>
                        <ServiceCategoryEdit />
                      </CabalPlusGuestGuard>
                    </PermissionGuard>
                  </BusinessAccountGuard>
                </CabalPlusGuard>
              ),
            },
          ],
        },
        {
          path: 'categoria-de-transacciones',
          children: [
            {
              element: (
                <Navigate
                  to="/dashboard/categoria-de-transacciones/todos"
                  replace
                />
              ),
              index: true,
            },
            {
              path: 'todos',
              element: (
                <PermissionGuard
                  hasContent
                  permissions={['acc_list_categories']}
                >
                  <CabalPlusGuestGuard hasContent>
                    <TransactionCategoryList />
                  </CabalPlusGuestGuard>
                </PermissionGuard>
              ),
            },
            {
              path: 'nuevo',
              element: (
                <PermissionGuard
                  hasContent
                  permissions={['acc_create_categories']}
                >
                  <CabalPlusGuestGuard hasContent>
                    <TransactionCategoryCreate />
                  </CabalPlusGuestGuard>
                </PermissionGuard>
              ),
            },
            {
              path: ':id/editar',
              element: (
                <PermissionGuard
                  hasContent
                  permissions={['acc_update_categories']}
                >
                  <CabalPlusGuestGuard hasContent>
                    <TransactionCategoryEdit />
                  </CabalPlusGuestGuard>
                </PermissionGuard>
              ),
            },
            {
              path: ':id/detalle',
              element: (
                <PermissionGuard
                  hasContent
                  permissions={['acc_list_categories']}
                >
                  <CabalPlusGuestGuard hasContent>
                    <TransactionCategoryDetail />
                  </CabalPlusGuestGuard>
                </PermissionGuard>
              ),
            },
          ],
        },
        {
          path: 'contactos',
          children: [
            {
              element: <Navigate to="/dashboard/contactos/todos" replace />,
              index: true,
            },
            {
              path: 'todos',
              element: (
                <CabalPlusGuestGuard hasContent>
                  <ContactList />
                </CabalPlusGuestGuard>
              ),
            },
            {
              path: 'nuevo',
              element: (
                <CabalPlusGuestGuard hasContent>
                  <ContactCreate />
                </CabalPlusGuestGuard>
              ),
            },
            {
              path: ':id/editar',
              element: (
                <CabalPlusGuestGuard hasContent>
                  <ContactEdit />
                </CabalPlusGuestGuard>
              ),
            },
            {
              path: ':id/transacciones',
              element: (
                <CabalPlusGuard hasContent>
                  <ContactTransactions />
                </CabalPlusGuard>
              ),
            },
            {
              path: ':id/productos',
              element: (
                <CabalPlusGuard hasContent>
                  <ContactProducts />
                </CabalPlusGuard>
              ),
            },
          ],
        },
        {
          path: 'Detalles',
          children: [
            {
              element: <Navigate to="/dashboard/detalles/todos" replace />,
              index: true,
            },
            {
              path: 'todos',
              element: (
                <CabalPlusGuestGuard hasContent>
                  <PermissionGuard
                    hasContent
                    permissions={['acc_see_analytics']}
                  >
                    <DetailsList />
                  </PermissionGuard>
                </CabalPlusGuestGuard>
              ),
            },
          ],
        },
        {
          path: 'user',
          children: [
            {
              element: <Navigate to="/dashboard/user/account" replace />,
              index: true,
            },
            {
              path: 'account',
              element: (
                <RoleBasedGuard roles={['owner']} hasContent>
                  <UserAccount />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        { path: 'permission-denied', element: <PermissionDenied /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        {
          path: 'unirme',
          element: <JoinOrganizationManual />,
        },
        {
          path: 'unirme/:code',
          element: (
            <InvitesGuard>
              <JoinOrganization />
            </InvitesGuard>
          ),
        },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const SignUp = Loadable(
  lazy(() => lazyRetry(() => import('../pages/auth/SignUp')))
);
const PasswordForgot = Loadable(
  lazy(() => lazyRetry(() => import('../pages/auth/PasswordForgot')))
);
const PasswordReset = Loadable(
  lazy(() => lazyRetry(() => import('../pages/auth/PasswordReset')))
);
const Login = Loadable(
  lazy(() => lazyRetry(() => import('../pages/auth/Login')))
);
const VerifyEmail = Loadable(
  lazy(() => lazyRetry(() => import('../pages/auth/VerifyEmail')))
);

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/GeneralApp')))
);

// USER
const UserAccount = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/UserAccount')))
);

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/PermissionDenied')))
);

// MAIN
const Page403 = Loadable(
  lazy(() => lazyRetry(() => import('../pages/Page403')))
);
const Page404 = Loadable(
  lazy(() => lazyRetry(() => import('../pages/Page404')))
);
const FinishRegistration = Loadable(
  lazy(() => lazyRetry(() => import('../pages/FinishRegistration')))
);

// ORGANIZATIONS
const OrganizationsIndex = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/Organizations/index')))
);
const RolesPermissionsIndex = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/RolesPermissions/index'))
  )
);
const Personalization = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/Organizations/Personalization'))
  )
);
const JoinOrganization = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/Organizations/Join')))
);
const JoinOrganizationManual = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/Organizations/JoinManual'))
  )
);
const BulkUpload = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/Organizations/BulkUpload'))
  )
);

// POS
const POSIndex = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/POS/index')))
);
const POSSales = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/POS/POSSale')))
);
const POSSalesEdit = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/POS/POSSaleEdit')))
);
const POSSalesDetail = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/POS/POSSaleDetails')))
);
const POSSalesList = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/POS/POSSalesListPage')))
);
const POSAnalitycs = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/POS/POSAnalitycs')))
);

// TRANSACTIONS
const TransactionSaleCreate = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/TransactionSaleCreate'))
  )
);
const TransactionSaleDetail = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/TransactionSaleDetails'))
  )
);
const TransactionSaleEdit = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/TransactionSaleEdit')))
);
const TransactionIncomeCreate = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/TransactionIncomeCreate'))
  )
);
const TransactionIncomeDetail = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/TransactionIncomeDetails'))
  )
);
const TransactionIncomeEdit = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/TransactionIncomeEdit'))
  )
);
const TransactionExpenseCreate = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/TransactionExpenseCreate'))
  )
);
const TransactionExpenseDetail = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/TransactionExpenseDetails'))
  )
);
const TransactionExpenseEdit = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/TransactionExpenseEdit'))
  )
);
const WebSalesList = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/WebSalesList')))
);
const WebSalesDetail = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/WebSalesDetail')))
);

// PRODUCT
const ProductsList = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/ProductList')))
);
const ProductAnalitycs = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/Products/ProductAnalytics'))
  )
);

const ProductCreate = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/ProductCreate')))
);
const ProductDetails = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/ProductDetails')))
);
const ProductEdit = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/ProductEdit')))
);

// PRODUCT CATEGORIES
const ProductCategoryList = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/ProductCategoryList')))
);
const ProductCategoryCreate = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/ProductCategoryCreate'))
  )
);
const ProductCategoryEdit = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/ProductCategoryEdit')))
);

// SERVICES
const ServiceList = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/Services/ServicesList'))
  )
);

const ServiceCreate = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/Services/ServicesCreate'))
  )
);

const ServiceEdit = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/Services/ServicesEdit'))
  )
);

// SERVICE CATEGORIES
const ServiceCategoryList = Loadable(
  lazy(() =>
    lazyRetry(
      () => import('../pages/dashboard/ServicesCategories/ServiceCategoryList')
    )
  )
);
const ServiceCategoryCreate = Loadable(
  lazy(() =>
    lazyRetry(
      () =>
        import('../pages/dashboard/ServicesCategories/ServiceCategoryCreate')
    )
  )
);
const ServiceCategoryEdit = Loadable(
  lazy(() =>
    lazyRetry(
      () => import('../pages/dashboard/ServicesCategories/ServiceCategoryEdit')
    )
  )
);

// CONTACTS
const ContactList = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/Contact/List')))
);
const ContactCreate = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/Contact/Create')))
);
const ContactEdit = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/Contact/Edit')))
);
const ContactTransactions = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/Contact/Transactions')))
);
const ContactProducts = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/Contact/Products')))
);

// TRANSACTION CATEGORIES
const TransactionCategoryList = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/TransactionCategoryList'))
  )
);
const TransactionCategoryCreate = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/TransactionCategoryCreate'))
  )
);
const TransactionCategoryEdit = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/TransactionCategoryEdit'))
  )
);
const TransactionCategoryDetail = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/TransactionCategoryDetail'))
  )
);

// Product Modifiers
const ModifierList = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/Modifiers/ModifierList'))
  )
);
const ModifierCreate = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/Modifiers/ModifierCreate'))
  )
);
const ModifierEdit = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/Modifiers/ModifierEdit'))
  )
);

// DETAILS
const DetailsList = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/DetailsList')))
);

// DEBTS
const DebtsTransactionList = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/DebtsTransactionList')))
);
const DebtsUserList = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/DebtsUserList')))
);
const DebtsUserDetail = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/DebtsUserDetail')))
);

// STORE
const Store = Loadable(lazy(() => import('../pages/dashboard/Store')));
const StoreSettings = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/StoreSettings')))
);
const StorePaymentSettings = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/StorePaymentsSettings'))
  )
);

// CAMPAIGNS
const Campaigns = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/Campaigns')))
);
const Discounts = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/Campaigns/Discounts')))
);
const DiscountsEdit = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/Campaigns/DiscountEdit'))
  )
);
const DiscountsDuplicate = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/Campaigns/DiscountDuplicate'))
  )
);

const Coupons = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/Campaigns/Coupons')))
);
const CouponsEdit = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/Campaigns/CouponsEdit'))
  )
);

// FINANCIAL ASSISTANT
const FinancialAssistant = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/FinancialAssistant')))
);

// EEFF
const EEFF = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/EEFF')))
);

// Cabal Card

// const CCDashboard = Loadable(
//   lazy(() =>
//     lazyRetry(() => import('../pages/dashboard/CabalCard/CCDashboard'))
//   )
// );
// const VerifyAccount = Loadable(
//   lazy(() =>
//     lazyRetry(() => import('../pages/dashboard/CabalCard/VerifyAccountPage'))
//   )
// );

// Cabal Plus
const Plus = Loadable(
  lazy(() => lazyRetry(() => import('../pages/JoinCabalPlusPage')))
);

// Tutorials
const Tutorials = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/Tutorials')))
);

// Cotizaciones
const QuotesIndex = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/Quotes/index')))
);
const QuoteDetail = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/Quotes/details')))
);
