import { AskForCabalPlus } from 'src/components/AskForCabalPlus';
import { usePermissions } from 'src/hooks/usePermissions';

type Props = {
  children: React.ReactNode;
  hasContent?: boolean;
};

export const CabalPlusGuestGuard: React.FC<Props> = ({
  hasContent,
  children,
}) => {
  const { is, has } = usePermissions();

  if (is('guest') && !has('paid_access')) {
    return hasContent ? <AskForCabalPlus /> : null;
  }

  return <>{children}</>;
};
