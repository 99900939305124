/**
 *
 * @deprecated please use DestructiveModal instead
 */

import {
  DialogTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from '@mui/material';
import { closeModal, getModalState } from 'src/redux/slices/deleteModal';
import { useDispatch, useSelector } from 'src/redux/store';

export default function DeleteDialog() {
  const { itemName, isOpened, onDelete } = useSelector(getModalState);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeModal());
  };

  return (
    <Dialog onClose={handleClose} open={isOpened as boolean}>
      <DialogTitle>¿Estás seguro?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Estás a punto de eliminar{' '}
          <Typography component="span" variant="subtitle1">
            {itemName}
          </Typography>
          . No podrás deshacer esta acción.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button
          id="confirm-delete-button"
          color="error"
          onClick={onDelete}
          autoFocus
        >
          Entiendo y deseo eliminarlo.
        </Button>
      </DialogActions>
    </Dialog>
  );
}
