import { CabalPermissionName, PermissionCatalog } from 'src/@types/Permission';

export type PermissionsModuleName =
  | 'pos'
  | 'inventory'
  | 'services'
  | 'servicesCategories'
  | 'sales'
  | 'expenses'
  | 'eeff'
  | 'categories'
  | 'budgetAssistant'
  | 'details'
  | 'store';

export const servicesPermissions: PermissionCatalog[] = [
  {
    name: 'service_see_catalog',
    label: 'Catálogo de servicios',
  },
  {
    name: 'service_create',
    label: 'Crear servicios',
  },
  {
    name: 'service_delete',
    label: 'Eliminar servicios',
  },
  {
    name: 'service_edit',
    label: 'Editar servicios',
  },
];

export const servicesCategoriesPermissions: PermissionCatalog[] = [
  {
    name: 'acc_list_service_categories',
    label: 'Ver categorías de servicio',
  },
  {
    name: 'acc_create_service_categories',
    label: 'Crear categorías de servicio',
  },
  {
    name: 'acc_update_service_categories',
    label: 'Actualizar categorías de servicio',
  },
  {
    name: 'acc_delete_service_categories',
    label: 'Eliminar categorías de servicio',
  },
];

export const posPermissions: PermissionCatalog[] = [
  {
    name: 'pdv_delete_orders',
    label: 'Eliminar órdenes',
  },
  {
    name: 'pdv_see_analytics',
    label: 'Ver analíticas',
  },
];

export const inventoryPermissions: PermissionCatalog[] = [
  {
    name: 'inventory_see_catalog',
    label: 'Catálogo de productos',
  },
  {
    name: 'inventory_see_costs',
    label: 'Ver costo de los productos',
  },
  {
    name: 'inventory_create_products',
    label: 'Crear productos',
  },
  {
    name: 'inventory_delete_products',
    label: 'Eliminar productos',
  },
  {
    name: 'inventory_bulk_delete',
    label: 'Eliminar productos en masa',
  },
  {
    name: 'inventory_edit_products',
    label: 'Editar productos',
  },
  {
    name: 'inventory_stock_analytics',
    label: 'Ver analíticas',
  },
  {
    name: 'inventory_modifiers',
    label: 'Ver modificadores',
  },
];

export const salesPermissions: PermissionCatalog[] = [
  {
    name: 'acc_discounts',
    label: 'Descuentos',
  },
  {
    name: 'acc_credit_sales',
    label: 'Ventas al crédito',
  },
];

export const expensesPermissions: PermissionCatalog[] = [
  {
    name: 'acc_credit_expenses',
    label: 'Gastos al crédito',
  },
];

export const eeffPermissions: PermissionCatalog[] = [
  {
    name: 'acc_financial_statement',
    label: 'Ver EEFFs',
  },
];

export const categoriesPermissions: PermissionCatalog[] = [
  {
    name: 'acc_list_categories',
    label: 'Ver categorías',
  },
  {
    name: 'acc_create_categories',
    label: 'Crear categorías',
  },
  {
    name: 'acc_update_categories',
    label: 'Actualizar categorías',
  },
  {
    name: 'inventory_bulk_update',
    label: 'Asignar categorías en masa',
  },
  {
    name: 'acc_delete_categories',
    label: 'Eliminar categorías',
  },
];

export const budgetAssistantPermissions: PermissionCatalog[] = [
  {
    name: 'acc_budget_assistant',
    label: 'Administración del presupuesto',
  },
];

export const detailsPermissions: PermissionCatalog[] = [
  {
    name: 'acc_see_analytics',
    label: 'Ver analíticas',
  },
  {
    name: 'acc_delete_transactions',
    label: 'Eliminar transacciones',
  },
  {
    name: 'massive_import',
    label: 'Importación masiva',
  },
];

export const storePermissions: PermissionCatalog[] = [
  {
    name: 'web_manage_offers',
    label: 'Administración de campañas y ofertas',
  },
  {
    name: 'web_see_analytics',
    label: 'Ver analíticas',
  },
  {
    name: 'web_delete_transactions',
    label: 'Eliminar pedidos',
  },
  {
    name: 'web_configure_store',
    label: 'Configuración de tienda',
  },
];

const returnName = (permission: PermissionCatalog) => permission.name;

const posPermissionNames = posPermissions.map(returnName);
const inventoryPermissionNames = inventoryPermissions.map(returnName);
const salesPermissionNames = salesPermissions.map(returnName);
const expensesPermissionNames = expensesPermissions.map(returnName);
const eeffPermissionNames = eeffPermissions.map(returnName);
const categoriesPermissionNames = categoriesPermissions.map(returnName);
const budgetAssistantPermissionNames =
  budgetAssistantPermissions.map(returnName);
const detailsPermissionsNames = detailsPermissions.map(returnName);
const storePermissionNames = storePermissions.map(returnName);
const servicesPermissionNames = servicesPermissions.map(returnName);
const servicesCategoriesPermissionNames =
  servicesCategoriesPermissions.map(returnName);

export const permissionNames: Record<
  PermissionsModuleName,
  CabalPermissionName[]
> = {
  pos: posPermissionNames,
  inventory: inventoryPermissionNames,
  sales: salesPermissionNames,
  expenses: expensesPermissionNames,
  eeff: eeffPermissionNames,
  categories: categoriesPermissionNames,
  budgetAssistant: budgetAssistantPermissionNames,
  details: detailsPermissionsNames,
  store: storePermissionNames,
  services: servicesPermissionNames,
  servicesCategories: servicesCategoriesPermissionNames,
};
