import { Alert, Button, CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { QueryTypes } from 'src/@types/queryTypes';
import { StripeChargesApi } from 'src/api-client';
import { List } from 'src/components/List';
import { useErrorReport } from 'src/hooks/useErrorReport';

import { StripeElements } from '../../StripeElements';
import { SubscriptionOption } from '../data/subscriptionTypes';
import { PlanDescription } from './PlanDescription';

type Props = {
  options: SubscriptionOption[];
  isDirectBuy?: boolean;
};

export const SubscriptionOptions = ({ options, isDirectBuy }: Props) => {
  const { reportError } = useErrorReport();
  const [selectedPlan, setSelectedPlan] = useState<
    SubscriptionOption | undefined
  >();

  const { data, isLoading, isFetching, isSuccess } = useQuery({
    queryKey: [`${QueryTypes.Stripe}-client`],
    queryFn: async () => {
      const response = await StripeChargesApi.v1StripeChargesPost({
        v1StripeChargesDeleteRequest: {
          create_paid_subscription: isDirectBuy,
          plan_type: selectedPlan?.plan_type,
        },
      });
      return response.data;
    },
    cacheTime: 0,
    enabled: Boolean(selectedPlan),
    refetchOnWindowFocus: false,
  });

  const handlePlanReset = async () => {
    try {
      setSelectedPlan(undefined);

      if (data?.subscription_id) {
        await StripeChargesApi.v1StripeChargesDelete({
          id: data.subscription_id as string,
        });
      }
    } catch (error) {
      reportError(error);
    }
  };

  const handleSelectedPlan = (plan: SubscriptionOption) =>
    setSelectedPlan(plan);

  if (!selectedPlan) {
    return (
      <div style={{ margin: '0 auto 40px' }}>
        <Typography
          marginBottom={0}
          textAlign="center"
          variant="h5"
          color="text.secondary"
        >
          Desbloquea tu máximo potencial
        </Typography>
        <Typography marginBottom={3} textAlign="center" variant="h3">
          Selecciona tu plan
        </Typography>
        <List
          data={options}
          renderItem={(plan) => (
            <PlanDescription
              plan={plan}
              onClick={handleSelectedPlan}
              disabled={isFetching}
            />
          )}
          gridProps={{
            spacing: 1,
            display: 'flex',
            justifyContent: 'center',
          }}
          gridItemProps={{
            xs: 12,
            sm: 4,
          }}
        />
      </div>
    );
  }

  if ((selectedPlan && isLoading) || isFetching) {
    return (
      <div
        style={{
          textAlign: 'center',
          marginBottom: 40,
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (!isSuccess || !data.client_secret) {
    return (
      <div style={{ marginBottom: 40 }}>
        <Button
          style={{ marginBottom: 20 }}
          size="small"
          onClick={handlePlanReset}
          variant="contained"
        >
          Cambiar plan
        </Button>
        <Alert severity="error">Ocurrió un error</Alert>
      </div>
    );
  }

  return (
    <div>
      <Button
        style={{ marginBottom: 20 }}
        size="small"
        onClick={handlePlanReset}
        variant="contained"
      >
        Cambiar plan
      </Button>
      <StripeElements
        isDirectBuy={isDirectBuy}
        selectedPlan={selectedPlan}
        clientSecret={data.client_secret as string}
      />
    </div>
  );
};
