import { Box, BoxProps } from '@mui/material';
import { memo } from 'react';

function ForbiddenIllustration({ ...other }: BoxProps) {
  return (
    <Box {...other}>
      <img
        style={{
          margin: '0 auto',
        }}
        width="320"
        height="auto"
        alt="error 403"
        src="/assets/illustrations/illustration_403.png"
      />
    </Box>
  );
}

export default memo(ForbiddenIllustration);
