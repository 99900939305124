import {
  AR,
  BO,
  CL,
  CO,
  CR,
  DO,
  EC,
  GT,
  HN,
  MX,
  NI,
  PA,
  PE,
  PR,
  PY,
  SV,
  US,
  UY,
  VE,
} from 'country-flag-icons/react/3x2';

import useAuth from 'src/hooks/useAuth';

export const CountryFlag = () => {
  const { user } = useAuth();
  const width = 32;
  const height = 32;
  const props = {
    title: user?.current_organization?.country?.name,
    width,
    height,
  };

  switch (user?.current_organization?.country?.name) {
    case 'Argentina':
      return <AR {...props} />;
    case 'Bolivia':
      return <BO {...props} />;
    case 'Chile':
      return <CL {...props} />;
    case 'Colombia':
      return <CO {...props} />;
    case 'Costa Rica':
      return <CR {...props} />;
    case 'Ecuador':
      return <EC {...props} />;
    case 'Estados Unidos':
      return <US {...props} />;
    case 'Guatemala':
      return <GT {...props} />;
    case 'Honduras':
      return <HN {...props} />;
    case 'México':
      return <MX {...props} />;
    case 'Nicaragua':
      return <NI {...props} />;
    case 'Panamá':
      return <PA {...props} />;
    case 'Paraguay':
      return <PY {...props} />;
    case 'Perú':
      return <PE {...props} />;
    case 'Puerto Rico':
      return <PR {...props} />;
    case 'República Dominicana':
      return <DO {...props} />;
    case 'Uruguay':
      return <UY {...props} />;
    case 'Venezuela':
      return <VE {...props} />;
    default:
      return <SV {...props} />;
  }
};
