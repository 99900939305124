const en = {
  myModules: 'Módulos',
  myPOS: 'Punto de venta (PDV)',
  start: 'Inicio',
  posSale: 'Venta mostrador',
  sales: 'Ventas',
  sale: 'Venta',
  income: 'Ingreso',
  orders: 'Órdenes',
  clients: 'Clientes',
  contacts: 'Contactos',
  products: 'Productos',
  all: 'Todos',
  dashboard: 'Tablero de resultados',
  all2: 'Todas',
  categories: 'Categorías',
  category: 'Categoría',
  analytics: 'Analíticas',
  posAnalytics: 'Analíticas PDV',
  myAccounting: 'Contabilidad',
  expenses: 'Gastos',
  expense: 'Gasto',
  debts: 'Deudas',
  debt: 'Deuda',
  transactions: 'Transacciones',
  myStore: 'Tienda en línea',
  requests: 'Pedidos',
  offers: 'Ofertas',
  myInventory: 'Inventario',
  financialAssistant: 'Asistente financiero',
};

export default en;
