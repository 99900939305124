import { Container, Divider } from '@mui/material';
import { createContext, useContext, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';

import Logo from 'src/components/Logo';

interface SimpleLayoutContextProps {
  fullWidth: boolean;
  showLogo: boolean;
  setFullWidth: (data: boolean) => void;
  setShowLogo: (data: boolean) => void;
}

const SimpleLayoutContext = createContext<SimpleLayoutContextProps>({
  fullWidth: false,
  showLogo: true,
  setFullWidth: (data: boolean) => {},
  setShowLogo: (data: boolean) => {},
});

export const useSimpleLayout = () => {
  const context = useContext(SimpleLayoutContext);

  return context;
};

export default function SimpleLayout() {
  const [fullWidth, setFullWidth] = useState(false);
  const [showLogo, setShowLogo] = useState(true);

  const body = useMemo(
    () => (
      <SimpleLayoutContext.Provider
        value={{
          fullWidth,
          showLogo,
          setFullWidth,
          setShowLogo,
        }}
      >
        {showLogo && (
          <>
            <Logo />
            <Divider style={{ marginTop: 30, marginBottom: 20 }} />
          </>
        )}
        <Outlet />
      </SimpleLayoutContext.Provider>
    ),
    [fullWidth, showLogo]
  );

  return fullWidth ? (
    body
  ) : (
    <Container maxWidth="xl" style={{ paddingTop: 20 }}>
      {body}
    </Container>
  );
}
