import { useContext } from 'react';

import { RemoteConfigSetting } from 'src/@types/CabalRemoteConfig';
import { RemoteConfigContext } from 'src/contexts/RemoteConfig';

export const useRemoteConfig = () => {
  const context = useContext(RemoteConfigContext);

  if (!context)
    throw new Error(
      'RemoteConfig context must be use inside RemoteConfigProvider'
    );

  const getRemoteSetting = (setting: RemoteConfigSetting) => {
    const value = context[setting];
    if (value === 'true' || value === 'false') {
      return JSON.parse(value);
    }

    return value;
  };

  return {
    getRemoteSetting,
  };
};
