import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'login',
  initialState: null,
  reducers: {
    setAuthorizationHeader: (
      _state,
      { payload: { accessToken } }: PayloadAction<{ accessToken: string }>
    ) => {
      localStorage.setItem('accessToken', accessToken);
    }
  }
});

// Reducer 
export default slice.reducer;

// Actions
export const { setAuthorizationHeader } = slice.actions;