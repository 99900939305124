// Import the functions you need from the SDKs you need
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getRemoteConfig } from 'firebase/remote-config';

import { defaultRemoteConfig } from './contexts/RemoteConfig';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAxxbXlcHlRzDsuWm7Ofo6jAie9ArR2tGE',
  authDomain: 'cabal-33aa5.firebaseapp.com',
  projectId: 'cabal-33aa5',
  storageBucket: 'cabal-33aa5.appspot.com',
  messagingSenderId: '468701863685',
  appId: '1:468701863685:web:328b098abeea51bc3d216c',
  measurementId: 'G-SZYG2ZGFVN',
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const remoteConfig = getRemoteConfig(app);

remoteConfig.defaultConfig = defaultRemoteConfig;

export { app, analytics, remoteConfig, firebaseConfig };
