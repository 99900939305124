import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ACCProduct, CartItem } from 'src/@types/cart';
import { CartTransactionEnum } from 'src/components/CartTable';

import { RootState } from '../store';

type CartItemTypes = 'products' | 'services';

type CartStateItem = CartItem[];

interface CartState {
  products: CartStateItem;
  services: CartStateItem;
  amountProducts: number;
  amountServices: number;
  amountFree: number | undefined;
  amountDiscount: number | undefined;
  totalQuantity: number;
}

interface CartItemPayload {
  item: CartItem;
  transactionType: CartTransactionEnum;
  itemType?: CartItemTypes;
  idx?: number;
}

interface CartItemsPayload {
  items: CartItem[];
  transactionType: CartTransactionEnum;
  itemType?: CartItemTypes;
}

const initialState: CartState = {
  products: [],
  services: [],
  amountProducts: 0,
  amountServices: 0,
  amountFree: 0,
  amountDiscount: 0,
  totalQuantity: 0,
};

const calculateAmount = (
  items: CartStateItem,
  key: CartTransactionEnum,
  itemType: CartItemTypes
) => {
  const onAmount = key === CartTransactionEnum.SALE ? 'price' : 'cost';
  let amount = 0;

  items.forEach((item) => {
    if (itemType === 'products') {
      let itemPriceOrCost = (item as ACCProduct)[onAmount];
      if (!itemPriceOrCost) itemPriceOrCost = '0';

      amount += item.quantity * parseFloat(itemPriceOrCost);
    } else {
      amount += item.quantity * parseFloat(item.price);
    }
  });

  return amount;
};

const calculateTotalQty = (products: CartItem[], services: CartItem[]) => {
  let productsQty = 0;
  let servicesQty = 0;

  if (products.length > 0) {
    productsQty = products
      .map((product) => product.quantity)
      .reduce((curr, next) => curr + next);
  }

  if (services.length > 0) {
    servicesQty = services
      .map((service) => service.quantity)
      .reduce((curr, next) => curr + next);
  }

  return productsQty + servicesQty;
};

const getStateKey = (itemType?: CartItemTypes) => itemType ?? 'products';

const slice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItem(state, { payload }: PayloadAction<CartItemPayload>) {
      const stateKey = getStateKey(payload?.itemType);
      const currentItems = state[stateKey];
      currentItems.push(payload.item);

      if (stateKey === 'products') {
        state.amountProducts = calculateAmount(
          state.products,
          payload.transactionType,
          stateKey
        );
      } else {
        state.amountServices = calculateAmount(
          state.services,
          payload.transactionType,
          stateKey
        );
      }

      state.totalQuantity = calculateTotalQty(state.products, state.services);
    },
    updateQuantity(
      state,
      {
        payload,
      }: PayloadAction<{ quantity: number } & Omit<CartItemPayload, 'item'>>
    ) {
      const stateKey = getStateKey(payload?.itemType);
      state[stateKey] = state[stateKey].map((item, i) =>
        i === payload.idx ? { ...item, quantity: payload.quantity } : item
      );

      if (stateKey === 'products') {
        state.amountProducts = calculateAmount(
          state.products,
          payload.transactionType,
          stateKey
        );
      } else {
        state.amountServices = calculateAmount(
          state.services,
          payload.transactionType,
          stateKey
        );
      }
      state.totalQuantity = calculateTotalQty(state.products, state.services);
    },
    updateCost(
      state,
      {
        payload,
      }: PayloadAction<{ cost: string } & Omit<CartItemPayload, 'item'>>
    ) {
      const stateKey = getStateKey(payload?.itemType);
      state[stateKey] = state[stateKey].map((item, i) =>
        i === payload.idx ? { ...item, cost: payload.cost } : item
      );

      if (stateKey === 'products') {
        state.amountProducts = calculateAmount(
          state.products,
          payload.transactionType,
          stateKey
        );
      } else {
        state.amountServices = calculateAmount(
          state.services,
          payload.transactionType,
          stateKey
        );
      }
      state.totalQuantity = calculateTotalQty(state.products, state.services);
    },
    updatePrice(
      state,
      {
        payload,
      }: PayloadAction<{ price: string } & Omit<CartItemPayload, 'item'>>
    ) {
      const stateKey = getStateKey(payload?.itemType);
      state[stateKey] = state[stateKey].map((item, i) =>
        i === payload.idx ? { ...item, price: payload.price } : item
      );

      if (stateKey === 'products') {
        state.amountProducts = calculateAmount(
          state.products,
          payload.transactionType,
          stateKey
        );
      } else {
        state.amountServices = calculateAmount(
          state.services,
          payload.transactionType,
          stateKey
        );
      }
      state.totalQuantity = calculateTotalQty(state.products, state.services);
    },
    removeItem(
      state,
      { payload }: PayloadAction<Omit<CartItemPayload, 'item'>>
    ) {
      const stateKey = getStateKey(payload?.itemType);
      state[stateKey] = state[stateKey].filter((_, i) => i !== payload.idx);

      if (stateKey === 'products') {
        state.amountProducts = calculateAmount(
          state.products,
          payload.transactionType,
          stateKey
        );
      } else {
        state.amountServices = calculateAmount(
          state.services,
          payload.transactionType,
          stateKey
        );
      }
      state.totalQuantity = calculateTotalQty(state.products, state.services);
    },
    setSelectedItems(state, { payload }: PayloadAction<CartItemsPayload>) {
      const stateKey = getStateKey(payload?.itemType);
      const { items } = payload;
      state[stateKey] = items;

      if (stateKey === 'products') {
        state.amountProducts = calculateAmount(
          state.products,
          payload.transactionType,
          stateKey
        );
      } else {
        state.amountServices = calculateAmount(
          state.services,
          payload.transactionType,
          stateKey
        );
      }
      state.totalQuantity = calculateTotalQty(state.products, state.services);
    },
    setAmountFree(state, action: PayloadAction<number | undefined>) {
      state.amountFree = action.payload;
    },
    setAmountDiscount(state, action: PayloadAction<number | undefined>) {
      state.amountDiscount = action.payload;
    },
    cleanCart() {
      return initialState;
    },
  },
});

export const {
  addItem,
  updateCost,
  updatePrice,
  updateQuantity,
  setAmountDiscount,
  setAmountFree,
  setSelectedItems,
  removeItem,
  cleanCart,
} = slice.actions;

export default slice.reducer;

export const getCartState = (state: RootState) => ({
  ...state.cart,
});
