import { useSnackbar } from 'notistack';
import { ReactNode, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { usePermissions } from 'src/hooks/usePermissions';
import SignUp from 'src/pages/auth/SignUp';
import { PATH_DASHBOARD, PATH_PAGE } from 'src/routes/paths';

import LoadingScreen from '../components/LoadingScreen';
import useAuth from '../hooks/useAuth';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { user, isAuthenticated, isInitialized, stepTwo } = useAuth();
  const { is, isAccountType } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  );

  if (is('guestWithoutOrganization')) {
    return <Navigate to={PATH_PAGE.joinManual} />;
  }

  if (
    isAuthenticated &&
    stepTwo &&
    pathname !== '/dashboard/finalizar-registro' &&
    !isAccountType('guest') &&
    (stepTwo || !user?.user.country || !user?.current_organization.country)
  ) {
    if (
      (!isAccountType('guest') &&
        (!user?.user.country || !user?.current_organization.country) &&
        user?.user.last_name === '') ||
      user?.user.last_name === '.'
    ) {
      enqueueSnackbar('Por favor actualiza tu perfil');
    }

    if (process.env.NODE_ENV === 'test') return <div />;

    return <Navigate to={PATH_DASHBOARD.general.finishRegistration} />;
  }

  if (
    isAuthenticated &&
    !stepTwo &&
    pathname === '/dashboard/finalizar-registro' &&
    (isAccountType('guest') ||
      (!isAccountType('guest') &&
        user?.user.country &&
        user.current_organization.country))
  ) {
    if (process.env.NODE_ENV === 'test') return <div />;

    if (isAccountType('business')) {
      return <Navigate to={PATH_DASHBOARD.POS.root} />;
    }
    return <Navigate to={PATH_DASHBOARD.general.app} />;
  }

  if (!isInitialized) {
    if (process.env.NODE_ENV === 'test') return <div />;

    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (process.env.NODE_ENV === 'test') return <div />;

    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <SignUp />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    if (process.env.NODE_ENV === 'test') return <div />;

    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  try {
    const joinCode = localStorage.getItem('join_code');
    const joinOrganization = localStorage.getItem('join_organization');

    if (joinCode && joinOrganization && !stepTwo) {
      if (process.env.NODE_ENV === 'test') return <div />;

      return (
        <Navigate
          to={`${PATH_PAGE.join(joinCode)}?organization=${joinOrganization}`}
        />
      );
    }
  } catch (error) {
    console.log(error);
  }

  return <>{children}</>;
}
