import { Box, BoxProps } from '@mui/material';
import { memo } from 'react';

function PageNotFoundIllustration({ ...other }: BoxProps) {
  return (
    <Box {...other}>
      <img
        style={{
          margin: '0 auto',
        }}
        width="320"
        height="auto"
        alt="error 404"
        src="/assets/illustrations/illustration_404.png"
      />
    </Box>
  );
}

export default memo(PageNotFoundIllustration);
