// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: '/login',
  passwordRestore: '/recuperar-contrasena',
  passwordReset: '/cambiar-contrasena',
  emailVerify: '/verificar-email',
};

export const PATH_PAGE = {
  page403: '/403',
  page404: '/404',
  joinManual: `/unirme`,
  join: (code: string) => `/unirme/${code}`,
  plus: '/plus',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    finishRegistration: path(ROOTS_DASHBOARD, '/finalizar-registro'),
    app: path(ROOTS_DASHBOARD, '/app'),
    tutorials: path(ROOTS_DASHBOARD, '/tutoriales'),
    inventario: path(ROOTS_DASHBOARD, '/inventario'),
  },
  organizations: {
    root: path(ROOTS_DASHBOARD, '/organizaciones/todas'),
    rolesPermissions: path(ROOTS_DASHBOARD, '/organizaciones/roles-permisos'),
    personalization: path(ROOTS_DASHBOARD, '/organizaciones/personalizacion'),
    bulkUpload: path(ROOTS_DASHBOARD, '/organizaciones/importar-datos'),
  },
  cabalCard: {
    root: path(ROOTS_DASHBOARD, '/cabal-card/tablero'),
    verifyAccount: path(ROOTS_DASHBOARD, '/cabal-card/verificar-cuenta'),
  },
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    account: path(ROOTS_DASHBOARD, '/user/account'),
  },
  POS: {
    root: path(ROOTS_DASHBOARD, '/pdv/inicio'),
    sales: path(ROOTS_DASHBOARD, '/pdv/nueva-venta'),
    orders: path(ROOTS_DASHBOARD, '/pdv/ordenes'),
    ordersDetail: (id: number) => path(ROOTS_DASHBOARD, `/pdv/ordenes/${id}`),
    ordersEdit: (id: number) =>
      path(ROOTS_DASHBOARD, `/pdv/editar-venta/${id}`),
    analitycs: path(ROOTS_DASHBOARD, '/pdv/analiticas'),
  },
  products: {
    root: path(ROOTS_DASHBOARD, '/productos'),
    analytics: path(ROOTS_DASHBOARD, '/productos/analiticas'),
    new: path(ROOTS_DASHBOARD, '/productos/nuevo'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/productos/${id}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/productos/${id}/editar`),
  },
  modifiers: {
    root: path(ROOTS_DASHBOARD, '/adicionales'),
    new: path(ROOTS_DASHBOARD, '/adicionales/nuevo'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/adicionales/${id}/editar`),
  },
  productCategories: {
    root: path(ROOTS_DASHBOARD, '/categoria-de-productos'),
    new: path(ROOTS_DASHBOARD, '/categoria-de-productos/nuevo'),
    edit: (id: string) =>
      path(ROOTS_DASHBOARD, `/categoria-de-productos/${id}/editar`),
  },
  services: {
    root: path(ROOTS_DASHBOARD, '/servicios'),
    new: path(ROOTS_DASHBOARD, '/servicios/nuevo'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/servicios/${id}/editar`),
  },
  serviceCategories: {
    root: path(ROOTS_DASHBOARD, '/categoria-de-servicios'),
    new: path(ROOTS_DASHBOARD, '/categoria-de-servicios/nuevo'),
    edit: (id: string) =>
      path(ROOTS_DASHBOARD, `/categoria-de-servicios/${id}/editar`),
  },
  transactionCategories: {
    root: path(ROOTS_DASHBOARD, '/categoria-de-transacciones'),
    new: path(ROOTS_DASHBOARD, '/categoria-de-transacciones/nuevo'),
    edit: (id: string) =>
      path(ROOTS_DASHBOARD, `/categoria-de-transacciones/${id}/editar`),
    detail: (id: string) =>
      path(ROOTS_DASHBOARD, `/categoria-de-transacciones/${id}/detalle`),
  },
  contacts: {
    root: path(ROOTS_DASHBOARD, '/contactos'),
    new: path(ROOTS_DASHBOARD, '/contactos/nuevo'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/contactos/${id}/editar`),
    transactions: (id: string) =>
      path(ROOTS_DASHBOARD, `/contactos/${id}/transacciones`),
    products: (id: string) =>
      path(ROOTS_DASHBOARD, `/contactos/${id}/productos`),
  },
  transactions: {
    sale_detail: (id: string) => path(ROOTS_DASHBOARD, `/venta/${id}`),
    income_detail: (id: string) => path(ROOTS_DASHBOARD, `/ingreso/${id}`),
    expense_detail: (id: string) => path(ROOTS_DASHBOARD, `/gasto/${id}`),
    sale_new: path(ROOTS_DASHBOARD, '/nueva-venta'),
    income_new: path(ROOTS_DASHBOARD, '/nuevo-ingreso'),
    expense_new: path(ROOTS_DASHBOARD, '/nuevo-gasto'),
    sale_edit: (id: string) => path(ROOTS_DASHBOARD, `/venta/${id}/editar`),
    income_edit: (id: string) => path(ROOTS_DASHBOARD, `/ingreso/${id}/editar`),
    expense_edit: (id: string) => path(ROOTS_DASHBOARD, `/gasto/${id}/editar`),
  },
  quotes: {
    index: path(ROOTS_DASHBOARD, '/cotizaciones'),
    quote_detail: (id: string) => path(ROOTS_DASHBOARD, `/cotizaciones/${id}`),
  },
  eeff: {
    root: path(ROOTS_DASHBOARD, '/estados-financieros'),
  },
  debts: {
    transactions: path(ROOTS_DASHBOARD, '/deudas/por-transaccion'),
    users: path(ROOTS_DASHBOARD, '/deudas/por-usuario'),
    user_detail: (id: string) =>
      path(ROOTS_DASHBOARD, `/deudas/por-usuario/${id}`),
  },
  details: {
    root: path(ROOTS_DASHBOARD, '/detalles'),
    all: path(ROOTS_DASHBOARD, '/detalles/todos'),
  },
  web: {
    root: () => path(ROOTS_DASHBOARD, `/ordenes-web`),
    detail: (id: string) => path(ROOTS_DASHBOARD, `/orden-web/${id}`),
  },
  store: {
    root: path(ROOTS_DASHBOARD, '/tienda'),
    settings: path(ROOTS_DASHBOARD, '/tienda/configuracion'),
    payment_settings: path(ROOTS_DASHBOARD, '/tienda/pagos'),
  },
  campaigns: {
    root: path(ROOTS_DASHBOARD, '/tus-ofertas'),
    coupons: path(ROOTS_DASHBOARD, '/tus-ofertas/cupones'),
    coupons_edit: (id: number) =>
      path(ROOTS_DASHBOARD, `/tus-ofertas/cupones/${id}`),
    coupons_duplicate: (id: number) =>
      path(ROOTS_DASHBOARD, `/tus-ofertas/cupones/duplicar/${id}`),
    discounts: path(ROOTS_DASHBOARD, '/tus-ofertas/descuentos'),
    discounts_edit: (id: number) =>
      path(ROOTS_DASHBOARD, `/tus-ofertas/descuentos/${id}`),
    discounts_duplicate: (id: number) =>
      path(ROOTS_DASHBOARD, `/tus-ofertas/descuentos/duplicar/${id}`),
  },
  financialAssitant: {
    root: path(ROOTS_DASHBOARD, '/asistente-financiero'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
