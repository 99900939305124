import jwtDecode from 'jwt-decode';

// routes
// import { PATH_AUTH } from '../routes/paths';
//
import axios from './axios';

// ----------------------------------------------------------------------

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const handleTokenExpired = (exp: number) => {
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    alert('Token expired');

    localStorage.removeItem('accessToken');

    window.location.href = '/';
  }, timeLeft);
};

const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode<{ exp: number }>(accessToken); // ~3 days by minimals server
    handleTokenExpired(exp);
  } else {
    localStorage.removeItem('WSGroupCta');
    localStorage.removeItem('whatsNewModal');
    localStorage.removeItem('joinCabalPlusModal');
    localStorage.removeItem('stepTwo');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('organization');
    localStorage.removeItem('verifyAccountStep');
    localStorage.removeItem('verifyAccountStepsDone');
    localStorage.removeItem('verifyAccountModule');
    delete axios.defaults.headers.common.Authorization;
    delete axios.defaults.headers.common.Organization;
  }
};

const updateSession = (organization: number) => {
  if (organization) {
    localStorage.setItem('organization', organization.toString());
    axios.defaults.headers.common.Organization = organization.toString();
  }
};

export { isValidToken, setSession, updateSession };
