import { useQuery } from '@tanstack/react-query';

import { QueryTypes } from 'src/@types/queryTypes';
import { UserApi } from 'src/api-client';

import { useOnError } from './useOnError';

type Props = {
  onSuccess?: VoidFunction;
};

export const useSubscription = (props?: Props) => {
  const { onError } = useOnError();

  const { data, refetch, isSuccess, isError, status, isLoading, error } =
    useQuery({
      queryKey: [QueryTypes.User, 'subscription'],
      queryFn: () => UserApi.v1UserSubscriptionGet(),
      onSuccess: () => {
        if (props?.onSuccess) props.onSuccess();
      },
      onError,
    });

  const is = (
    statusRequest:
      | 'no_subscription'
      | 'trial_subscription'
      | 'active_subscription'
      | 'inactive_subscription'
  ) => {
    if (!data?.data) {
      return;
    } else {
      const { status } = data.data;

      if (status === undefined) return;

      switch (statusRequest) {
        case 'no_subscription':
          return status === null;
        case 'trial_subscription':
          const days = parseInt(status as string);
          if (isNaN(days)) return false;
          return true;
        case 'inactive_subscription':
          return isNaN(status as number) && status !== 'active';
        case 'active_subscription':
          return status === 'active';
        default:
          return;
      }
    }
  };

  return {
    data,
    isSuccess,
    isError,
    status,
    isLoading,
    error,
    refetch,
    is,
  };
};
