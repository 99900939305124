import { FFmpeg } from '@ffmpeg/ffmpeg';

export const generateMp3File = async (webmBlob: Blob): Promise<File> => {
  const ffmpeg = new FFmpeg();
  await ffmpeg.load();

  const inputName = 'input.webm';
  const outputName = `output.mp3`;

  await ffmpeg.writeFile(
    inputName,
    new Uint8Array(await webmBlob.arrayBuffer())
  );

  await ffmpeg.exec(['-i', inputName, outputName]);

  const outputData = await ffmpeg.readFile(outputName);
  const outputBlob = new Blob([outputData], {
    type: `audio/mp3`,
  });
  const outputFile = new File([outputBlob], 'audio.mp3', {
    type: `audio/mp3`,
  });

  return outputFile;
};
