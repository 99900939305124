import { TextField, TextFieldProps } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { Controller, useFormContext } from 'react-hook-form';

type Props = {
  name: string;
  label: string;
  inputFormat?: string;
  disabled?: boolean;
  textFieldProps?: TextFieldProps;
  disablePast?: boolean;
  id?: string;
};

const RHFDateTimePicker = ({
  name,
  label,
  inputFormat = 'dd/MM/yyyy h:mm a',
  disabled,
  textFieldProps,
  disablePast,
  id,
}: Props) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DateTimePicker
          {...field}
          minDateTime
          disabled={disabled}
          disablePast={disablePast}
          label={label}
          inputFormat={inputFormat}
          value={field.value}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              error={!!error}
              helperText={error?.message}
              {...textFieldProps}
              id={id}
            />
          )}
        />
      )}
    />
  );
};

export default RHFDateTimePicker;
