import axios, { AxiosHeaders } from 'axios';
// config
import { API_URL } from '../config';

// ----------------------------------------------------------------------
const axiosInstance = axios.create({
  baseURL: API_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    if (config.headers === undefined) {
      config.headers = {} as AxiosHeaders;
    }

    (config.headers as AxiosHeaders).set('Accept', 'application/json');
    (config.headers as AxiosHeaders).set(
      'Authorization',
      localStorage.getItem('accessToken') ?? ''
    );

    return config;
  },
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    )
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    )
);

export default axiosInstance;
