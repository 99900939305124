import {
  faAddressBook,
  faBoxOpen,
  faBoxesStacked,
  faCirclePlay,
  faFileInvoiceDollar,
  faList,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AddBusiness,
  AssistantPhoto,
  Brush,
  CloudUpload,
  Dashboard,
  Dvr,
  Handshake,
  Info,
  Insights,
  Payments,
  People,
  PointOfSale,
  Queue,
  ShoppingBag,
  Speed,
  Storefront,
  Toll,
  Verified,
} from '@mui/icons-material';
import RequestPageIcon from '@mui/icons-material/RequestPage';

import { CabalAnalyticsEvent } from 'src/@types/CabalAnalyticsEvent';
import useLocales from 'src/hooks/useLocales';
import { usePermissions } from 'src/hooks/usePermissions';
import { PATH_DASHBOARD } from 'src/routes/paths';

interface NavModuleItem {
  title: string;
  path: string;
  icon: JSX.Element;
  shouldRender: boolean;
  isPaidModule?: boolean;
  analyticsEvent?: CabalAnalyticsEvent;
  children?: {
    title: string;
    path: string;
    shouldRender: boolean;
    isPaidModule?: boolean;
    analyticsEvent?: CabalAnalyticsEvent;
  }[];
}

interface NavModule {
  subheader: string;
  items: NavModuleItem[];
}

export const useNavConfig = () => {
  const { can, is, has, isAccountType } = usePermissions();
  const { translate: t } = useLocales();

  let navConfig: NavModule[] = [];

  let myOrganization: NavModule = {
    subheader: 'Organizaciones',
    items: [
      {
        title: 'Mis organizaciones',
        path: PATH_DASHBOARD.organizations.root,
        icon: <AddBusiness />,
        shouldRender:
          isAccountType('business') || has('multiple_organizations'),
        analyticsEvent: 'open_team',
      },
      {
        title: 'Roles y permisos',
        path: PATH_DASHBOARD.organizations.rolesPermissions,
        icon: <People />,
        shouldRender: isAccountType('business') && is('owner'),
        isPaidModule: true,
        analyticsEvent: 'open_employees',
      },
    ],
  };

  let myModules: NavModule = {
    subheader: t('myModules'),
    items: [
      {
        title: t('start'),
        path: PATH_DASHBOARD.POS.root,
        icon: <Dashboard />,
        shouldRender: isAccountType('business'),
      },
      {
        title: 'Tutoriales',
        path: PATH_DASHBOARD.general.tutorials,
        icon: <FontAwesomeIcon icon={faCirclePlay} />,
        shouldRender: isAccountType('business'),
      },
    ],
  };

  // let cabalCard: NavModule = {
  //   subheader: 'Cabal Card',
  //   items: [
  //     {
  //       title: 'Tablero',
  //       path: PATH_DASHBOARD.cabalCard.root,
  //       icon: <Dashboard />,
  //       shouldRender: isAccountType('person'),
  //     },
  //     {
  //       title: 'Verificar cuenta',
  //       path: PATH_DASHBOARD.cabalCard.verifyAccount,
  //       icon: <Verified />,
  //       shouldRender: isAccountType('person'),
  //     },
  //   ],
  // };

  let myPOS: NavModule = {
    subheader: t('myPOS'),
    items: [
      {
        title: t('posSale'),
        path: PATH_DASHBOARD.POS.sales,
        icon: <PointOfSale />,
        shouldRender: isAccountType('business'),
        analyticsEvent: 'open_new_pos_sale',
      },
      {
        title: t('orders'),
        path: PATH_DASHBOARD.POS.orders,
        icon: <Dvr />,
        shouldRender: isAccountType('business'),
        analyticsEvent: 'open_pos_orders',
      },
      {
        title: t('products'),
        path: '',
        icon: <FontAwesomeIcon icon={faBoxOpen} />,
        shouldRender: isAccountType('business'),
        children: [
          {
            title: t('all'),
            path: PATH_DASHBOARD.products.root,
            shouldRender:
              isAccountType('business') && can(['inventory_see_catalog']),
            analyticsEvent: 'open_inventory',
          },
          {
            title: t('categories'),
            path: PATH_DASHBOARD.productCategories.root,
            shouldRender:
              isAccountType('business') && can(['acc_list_categories']),
          },
        ],
      },
      {
        title: 'Servicios',
        path: '',
        icon: <Handshake />,
        shouldRender: isAccountType('business') && can(['service_see_catalog']),
        children: [
          {
            title: t('all'),
            isPaidModule: true,
            path: PATH_DASHBOARD.services.root,
            shouldRender:
              isAccountType('business') && can(['service_see_catalog']),
          },
          {
            title: t('categories'),
            isPaidModule: true,
            path: PATH_DASHBOARD.serviceCategories.root,
            shouldRender:
              isAccountType('business') && can(['acc_list_service_categories']),
          },
        ],
      },

      {
        icon: <Queue />,
        title: 'Adicionales',
        isPaidModule: true,
        path: PATH_DASHBOARD.modifiers.root,
        shouldRender:
          isAccountType('business') && can(['inventory_create_products']),
        analyticsEvent: 'open_modifiers',
      },
      {
        title: t('posAnalytics'),
        path: PATH_DASHBOARD.POS.analitycs,
        icon: <Insights />,
        shouldRender: isAccountType('business') && can(['pdv_see_analytics']),
        analyticsEvent: 'open_pos_analytics',
      },
    ],
  };

  let myAccounting: NavModule = {
    subheader: t('myAccounting'),
    items: [
      {
        title: t('dashboard'),
        path: PATH_DASHBOARD.general.app,
        icon: <Speed />,
        shouldRender: is('owner'),
      },
      {
        title: t('financialAssistant'),
        path: PATH_DASHBOARD.financialAssitant.root,
        icon: <AssistantPhoto />,
        shouldRender:
          isAccountType('business') && can(['acc_financial_statement']),
        isPaidModule: true,
        analyticsEvent: 'open_finantial_assist',
      },
      {
        title: 'Estados financieros',
        path: PATH_DASHBOARD.eeff.root,
        icon: <RequestPageIcon />,
        shouldRender: isAccountType('business') && is('owner'),
        isPaidModule: true,
        analyticsEvent: 'open_finantial_status',
      },
      {
        title: t('sales'),
        path: PATH_DASHBOARD.transactions.sale_new,
        icon: <PointOfSale />,
        shouldRender: isAccountType('business'),
        analyticsEvent: 'open_new_sale',
      },
      {
        title: 'Cotizaciones',
        path: PATH_DASHBOARD.quotes.index,
        icon: <FontAwesomeIcon icon={faFileInvoiceDollar} />,
        shouldRender: isAccountType('business'),
        analyticsEvent: 'open_new_sale',
        isPaidModule: true,
      },
      {
        title: t('income'),
        path: PATH_DASHBOARD.transactions.income_new,
        icon: <PointOfSale />,
        shouldRender: isAccountType('person'),
        analyticsEvent: 'open_new_sale',
      },
      {
        title: t('expense'),
        path: PATH_DASHBOARD.transactions.expense_new,
        icon: <Toll />,
        shouldRender: true,
        analyticsEvent: 'open_new_expense',
      },
      {
        title: t('debts'),
        path: PATH_DASHBOARD.debts.transactions,
        icon: <Payments />,
        shouldRender: true,
        analyticsEvent: 'open_debts',
      },
      {
        title: isAccountType('person') ? t('contacts') : t('clients'),
        path: PATH_DASHBOARD.contacts.root,
        icon: <FontAwesomeIcon icon={faAddressBook} />,
        shouldRender: true,
      },
      {
        title: t('transactions'),
        path: '',
        icon: <Info />,
        shouldRender: true,
        children: [
          {
            title: t('all2'),
            path: PATH_DASHBOARD.details.root,
            shouldRender: can(['acc_see_analytics']),
          },
          {
            title: t('categories'),
            path: PATH_DASHBOARD.transactionCategories.root,
            shouldRender: can(['acc_list_categories']),
          },
        ],
      },
    ],
  };

  let myStore: NavModule = {
    subheader: t('myStore'),
    items: [
      {
        title: t('myStore'),
        path: PATH_DASHBOARD.store.root,
        icon: <Storefront />,
        shouldRender: isAccountType('business'),
        analyticsEvent: 'open_online_store',
      },
      {
        title: t('requests'),
        path: PATH_DASHBOARD.web.root(),
        icon: <ShoppingBag />,
        shouldRender: isAccountType('business'),
        analyticsEvent: 'open_web_orders',
      },
      {
        title: t('offers'),
        path: PATH_DASHBOARD.campaigns.root,
        icon: <Verified />,
        shouldRender: isAccountType('business') && can(['web_manage_offers']),
        isPaidModule: true,
        analyticsEvent: 'open_offers',
      },
      {
        title: 'Personalización',
        path: PATH_DASHBOARD.organizations.personalization,
        icon: <Brush />,
        shouldRender: isAccountType('business') && is('owner'),
        isPaidModule: true,
        analyticsEvent: 'open_store_customization',
      },
    ],
  };

  let myInventory: NavModule = {
    subheader: t('myInventory'),
    items: [
      {
        title: t('products'),
        icon: <FontAwesomeIcon icon={faBoxOpen} />,
        path: PATH_DASHBOARD.products.root,
        shouldRender:
          isAccountType('business') && can(['inventory_see_catalog']),
        analyticsEvent: 'open_inventory',
      },
      {
        title: t('categories'),
        path: PATH_DASHBOARD.productCategories.root,
        icon: <FontAwesomeIcon icon={faList} />,
        shouldRender: isAccountType('business') && can(['acc_list_categories']),
      },
      {
        title: 'Adicionales',
        path: PATH_DASHBOARD.modifiers.root,
        icon: <FontAwesomeIcon icon={faBoxesStacked} />,
        shouldRender:
          isAccountType('business') && can(['inventory_create_products']),
        isPaidModule: true,
        analyticsEvent: 'open_modifiers',
      },
      {
        title: 'Importar datos',
        path: PATH_DASHBOARD.organizations.bulkUpload,
        icon: <CloudUpload />,
        shouldRender: isAccountType('business') && can(['massive_import']),
        isPaidModule: true,
        analyticsEvent: 'open_data_import',
      },
      {
        title: 'Analíticas',
        path: PATH_DASHBOARD.products.analytics,
        icon: <Insights />,
        shouldRender:
          isAccountType('business') && can(['inventory_stock_analytics']),
        isPaidModule: true,
      },
    ],
  };

  let myServices: NavModule = {
    subheader: 'Servicios',
    items: [
      {
        title: 'Todos',
        icon: <Handshake />,
        path: PATH_DASHBOARD.services.root,
        shouldRender:
          isAccountType('business') && can(['inventory_see_catalog']),
        isPaidModule: true,
      },
      {
        title: 'Categorías',
        path: PATH_DASHBOARD.serviceCategories.root,
        icon: <FontAwesomeIcon icon={faList} />,
        shouldRender: isAccountType('business') && can(['acc_list_categories']),
        isPaidModule: true,
      },
    ],
  };

  navConfig = [myAccounting];

  if (isAccountType('person')) {
    // navConfig.splice(0, 0, cabalCard);
  }

  if (isAccountType('business')) {
    navConfig.splice(0, 0, myModules);
    navConfig.splice(1, 0, myPOS);
    navConfig.push(myStore);
    navConfig.push(myInventory);
    navConfig.push(myServices);
  }

  if (isAccountType('business') || has('multiple_organizations')) {
    navConfig.splice(2, 0, myOrganization);
  }

  return navConfig;
};
