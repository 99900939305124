import { m } from 'framer-motion';
import { Container, Typography } from '@mui/material';

import { MotionContainer, varBounce } from './animate';
import { ForbiddenIllustration } from '../assets';

export const PermissionDeniedMessage = () => (
  <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
    <m.div variants={varBounce().in}>
      <Typography variant="h3" paragraph>
        Permiso denegado
      </Typography>
    </m.div>

    <m.div variants={varBounce().in}>
      <Typography sx={{ color: 'text.secondary' }}>
        No tienes permisos para acceder a está página.
      </Typography>
    </m.div>

    <m.div variants={varBounce().in}>
      <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
    </m.div>
  </Container>
);
