import { ReactNode, createContext, useState } from 'react';

interface PageContextInterface {
  title: string;
  setPageTitle: (title: string) => void;
}

const initialState: PageContextInterface = {
  title: '',
  setPageTitle: () => {},
};

const PageContext = createContext<PageContextInterface>(initialState);

type PageProviderProps = {
  children: ReactNode;
};

function PageProvider({ children }: PageProviderProps) {
  const [page, setPage] = useState<PageContextInterface>(initialState);

  const setPageTitle = (title: string) =>
    setPage((prev) => ({ ...prev, title }));

  return (
    <PageContext.Provider
      value={{
        ...page,
        setPageTitle,
      }}
    >
      {children}
    </PageContext.Provider>
  );
}

export { PageContext, PageProvider };
