import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { api } from 'src/services/cabalModifiedApi';

import cartReducer from './slices/cart';
import contactModalReducer from './slices/contactModal';
import deleteModalReducer from './slices/deleteModal';
import loginReducer from './slices/login';
// slices
import userReducer from './slices/user';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  user: userReducer,
  cart: cartReducer,
  deleteModal: deleteModalReducer,
  contactModal: contactModalReducer,
  login: loginReducer,
});

export { rootPersistConfig, rootReducer };
