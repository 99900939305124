import { Delete, Help } from '@mui/icons-material';
import {
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import { ACCProduct } from 'src/@types/cart';
import { updateCost, updateQuantity } from 'src/redux/slices/cart';
import { dispatch } from 'src/redux/store';

import { CartTransactionEnum } from '.';
import IncrementerButton from '../custom-input/IncrementerButton';

type Props = {
  product: ACCProduct;
  idx: number;
  transactionType: CartTransactionEnum;
  itemType: 'products' | 'services';
  removeItem: VoidFunction;
};

export const exceptThisSymbols = ['e', 'E', '+', '-'];

export const CostModifier = ({
  product,
  transactionType,
  itemType,
  removeItem,
  idx,
}: Props) => {
  const handleNewCost = (cost: string) => {
    dispatch(
      updateCost({
        idx,
        cost,
        transactionType,
        itemType,
      })
    );
  };

  const handleQuantityChange = (quantity: number) => {
    dispatch(
      updateQuantity({
        idx,
        quantity,
        transactionType,
        itemType,
      })
    );
  };

  const handleQuantityIncrease = () =>
    handleQuantityChange(product.quantity + 1);

  const handleQuantityDecrease = () => {
    dispatch(
      updateQuantity({
        idx,
        quantity: product.quantity - 1,
        transactionType,
        itemType,
      })
    );
  };

  return (
    <div style={{ marginBottom: 10 }}>
      <Stack spacing={1} marginBottom={2} direction="row" alignItems="center">
        <IconButton size="small" color="error" onClick={() => removeItem()}>
          <Delete fontSize="small" />
        </IconButton>
        <Tooltip title={product.name}>
          <Typography noWrap fontSize={18} fontWeight="bold" marginBottom={1}>
            {product.name}
          </Typography>
        </Tooltip>
      </Stack>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6}>
          <TextField
            type="number"
            label={`Costo`}
            value={product.cost}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Utiliza este campo para actualizar el costo de este producto.">
                    <IconButton>
                      <Help />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            onKeyDown={(e) =>
              exceptThisSymbols.includes(e.key) && e.preventDefault()
            }
            onChange={(e) => handleNewCost(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <IncrementerButton
            value={product.quantity}
            onQtyChange={(quantity) => handleQuantityChange(quantity)}
            disabledDecrease={product.quantity <= 1}
            onIncrease={handleQuantityIncrease}
            onDecrease={handleQuantityDecrease}
          />
        </Grid>
      </Grid>
    </div>
  );
};
