import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import useAuth from 'src/hooks/useAuth';

type IProps = {
  name: string;
  int?: boolean;
};

type Props = IProps & TextFieldProps;

export const exceptThisSymbols = ['e', 'E', '+', '-'];

export default function RHFMoneyField({ name, type, int, ...other }: Props) {
  const { control } = useFormContext();
  const { user } = useAuth();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          type="number"
          fullWidth
          value={field.value}
          error={!!error}
          helperText={error?.message}
          onKeyDown={(e) =>
            exceptThisSymbols.includes(e.key) && e.preventDefault()
          }
          {...other}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {user?.current_organization.country?.currency_symbol}
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}
