import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import useAuth from 'src/hooks/useAuth';
import { usePermissions } from 'src/hooks/usePermissions';
import { PATH_DASHBOARD } from 'src/routes/paths';

import LoadingScreen from './LoadingScreen';

type Props = {
  error: Error;
  resetErrorBoundary: VoidFunction;
};

export const ErrorFallback = ({ error, resetErrorBoundary }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useAuth();
  const { isAccountType } = usePermissions();

  const { mutateAsync } = useMutation({
    mutationKey: ['ErrorBoundary'],
    mutationFn: (content: string) =>
      axios.post(process.env.REACT_APP_DISCORD_BUGS_WEBHOOK_URL as string, {
        content,
      }),
  });

  useEffect(() => {
    if (error) {
      const token = window.localStorage.getItem('accessToken') ?? 'Not set';
      let content = `${location.pathname} \n USER ID: ${user.user?.user.email}\n Token: ${token} \n${error.message} \n${error.stack}`;
      content = content.substring(0, 500);

      if (
        process.env.REACT_APP_SENTRY_ENV === 'production' &&
        process.env.REACT_APP_DISCORD_BUGS_WEBHOOK_URL
      ) {
        mutateAsync(content);
        Sentry.captureException(error);
      } else {
        console.log(error);
      }

      if (content.includes('TypeError: Cannot read properties of undefined')) {
        if (user.user) {
          if (isAccountType('business')) {
            navigate(PATH_DASHBOARD.POS.root);
          } else {
            navigate(PATH_DASHBOARD.root);
          }
        } else {
          navigate('/');
        }
      }

      resetErrorBoundary();
    }
  }, [error]);

  return <LoadingScreen />;
};
