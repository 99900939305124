import { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  LinearProgress,
  ListItem,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';

import MenuPopover from 'src/components/MenuPopover';
import { IconButtonAnimate } from 'src/components/animate';
import { WhatsNewItem } from 'src/@types/WhatsNewItem';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { QueryTypes } from 'src/@types/queryTypes';
import { useOnError } from 'src/hooks/useOnError';
import { ChangelogsApi } from 'src/api-client';
import { Pagination } from 'src/components/pagination';

const Content = styled('div')(() => ({
  '& > ul': {
    margin: '10px 0 20px',
    paddingLeft: 20,
  },
  '& > p': {
    margin: '10px 0 20px',
  },
}));

const WhatsNewPopover = () => {
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [id, setId] = useState<number | null>(null);
  const [currentUpdate, setCurrentUpdate] = useState<WhatsNewItem | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const { onError } = useOnError();

  const {
    status,
    data: changelogsApi,
    isFetching,
    isPreviousData,
  } = useQuery({
    queryKey: [QueryTypes.WhatsNew, page],
    queryFn: () => ChangelogsApi.v1ChangelogsGet({ page }),
    onError,
  });

  const { refetch } = useQuery({
    enabled: false,
    queryKey: [QueryTypes.WhatsNew, `single-${id}`],
    queryFn: () => ChangelogsApi.v1ChangelogsIdGet({ id: id!.toString() }),
    onSuccess: ({ data }) => {
      setCurrentUpdate(data);
      setOpenModal(true);
    },
    onError,
  });

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setId(null);
    localStorage.setItem('whatsNewModal', 'yes');
  };

  const handleChange = (id: number) => {
    setId(id);
    handleClose();
  };

  useEffect(() => {
    if (id) refetch();
  }, [id]);

  useEffect(() => {
    if (!isPreviousData && changelogsApi?.data?.pagination.next) {
      queryClient.prefetchQuery({
        queryKey: [QueryTypes.WhatsNew, page + 1],
        queryFn: () => ChangelogsApi.v1ChangelogsGet({ page: page + 1 }),
      });
    }
  }, [changelogsApi, isPreviousData, page, queryClient]);

  useEffect(() => {
    try {
      const whatsNewModalLS = localStorage.getItem('whatsNewModal');
      const joinCabalPlusModalLS = localStorage.getItem('joinCabalPlusModal');
      const latestChangelogIdLS = localStorage.getItem('latestChangelogId');

      if (changelogsApi && changelogsApi.data?.changelogs.length > 0) {
        const firstChangelog = changelogsApi.data?.changelogs[0];

        if (
          (!whatsNewModalLS && joinCabalPlusModalLS) ||
          (firstChangelog.id.toString() !== latestChangelogIdLS &&
            joinCabalPlusModalLS)
        ) {
          localStorage.setItem(
            'latestChangelogId',
            firstChangelog.id.toString()
          );

          setId(firstChangelog.id);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [status, changelogsApi]);

  return (
    <>
      <IconButtonAnimate
        data-testid="wn-trigger-button"
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          ...(open && { bgcolor: 'action.selected' }),
        }}
      >
        <FontAwesomeIcon icon={faBullhorn} style={{ fontSize: 18 }} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 320,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            padding: 1,
          },
        }}
      >
        <Stack
          direction="column"
          alignItems="center"
          style={{
            position: 'relative',
          }}
        >
          {(status === 'loading' || isFetching) && (
            <LinearProgress
              data-testid="wn-loading-component"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
              }}
            />
          )}

          <ListItem>
            <ListItemText>
              <span style={{ fontWeight: 'bold' }}>
                Actualizaciones recientes
              </span>
            </ListItemText>
          </ListItem>
          <Divider style={{ width: '100%' }} />

          {status === 'success' && changelogsApi.data?.changelogs.length > 0 ? (
            <>
              {changelogsApi.data.changelogs.map((update) => (
                <MenuItem
                  key={update.id}
                  selected={update.id === id}
                  onClick={() => handleChange(update.id)}
                  style={{
                    whiteSpace: 'unset',
                  }}
                >
                  <ListItemText
                    primary={moment(update.created_at).format('DD/MM/YYYY')}
                    secondary={update.excerpt}
                  />
                </MenuItem>
              ))}
            </>
          ) : (
            <ListItem>
              <Alert severity="info" style={{ width: '100%' }}>
                No hay datos disponibles
              </Alert>
            </ListItem>
          )}

          {status === 'error' && (
            <ListItem>
              <Alert severity="error">
                Ocurrió un error cargando la información
              </Alert>
            </ListItem>
          )}
        </Stack>
        <Pagination
          fetchPreviousPage={() => setPage((old) => old - 1)}
          fetchNextPage={() => setPage((old) => old + 1)}
          hasPreviousPage={Boolean(changelogsApi?.data?.pagination.prev)}
          hasNextPage={Boolean(changelogsApi?.data?.pagination.next)}
          current={changelogsApi?.data?.pagination.page}
          total={changelogsApi?.data?.pagination.page}
        />
      </MenuPopover>

      <Dialog open={openModal} onClose={handleCloseModal} scroll="body">
        <DialogTitle>
          <Typography
            style={{
              fontSize: 24,
              fontWeight: 'bold',
            }}
          >
            {currentUpdate?.title}
          </Typography>
          <Typography variant="caption">
            Publicado el{' '}
            {moment(currentUpdate?.created_at).format('DD/MM/YYYY')}
          </Typography>
          <Divider style={{ marginTop: 10, marginBottom: 20 }} />
        </DialogTitle>
        <DialogContent>
          <Content
            dangerouslySetInnerHTML={{
              __html: currentUpdate?.content ?? '',
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default WhatsNewPopover;
