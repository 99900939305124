import { useMutation } from '@tanstack/react-query';

import { DirectUploadApi, V1DirectUploadPostRequest } from 'src/api-client';
import { fileChecksum } from 'src/utils/md5';

import { useOnError } from './useOnError';

type Props = {
  onSuccess: (data: { id: number; blob_signed_id: string }) => void;
};

export const useDirectUpload = ({ onSuccess }: Props) => {
  const { onError } = useOnError();
  const { mutateAsync: generateUploadURL } = useMutation(
    async (data: V1DirectUploadPostRequest) => {
      const response = await DirectUploadApi.v1DirectUploadPost({
        v1DirectUploadPostRequest: data,
      });
      return response.data;
    }
  );

  const { mutateAsync: directUpload, ...others } = useMutation({
    mutationFn: async (file: File) => {
      const checksum = await fileChecksum(file);
      const { direct_upload, id, blob_signed_id } = await generateUploadURL({
        file: {
          filename: file.name,
          byte_size: file.size,
          content_type: file.type,
          checksum,
        },
      });

      await fetch(direct_upload.url as string, {
        method: 'PUT',
        headers: {
          'Content-Type': direct_upload.headers?.['Content-Type'] as string,
          'Content-MD5': direct_upload.headers?.['Content-MD5'] as string,
        },
        body: file,
      });

      return {
        id,
        blob_signed_id,
      };
    },
    onSuccess: (data) => onSuccess(data),
    onError,
  });

  return { directUpload, ...others };
};
