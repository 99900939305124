import { AxiosResponse } from 'axios';
import { setUserProperties } from 'firebase/analytics';
import { ReactNode, createContext, useEffect, useReducer } from 'react';

import { ActionMap, AuthState, JWTContextType } from 'src/@types/auth';
import { CurrentOrganization, User } from 'src/@types/user';
import { UserApi, UsersAndroidPost200Response } from 'src/api-client';
import { analytics } from 'src/firebase';
import {
  PostUsersAndroidApiArg,
  PostUsersIosApiArg,
} from 'src/services/cabalApi';
import {
  usePostUsersAndroidMutation,
  usePostUsersIosMutation,
} from 'src/services/cabalModifiedApi';
import { isValidToken, setSession, updateSession } from 'src/utils/jwt';

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Refresh = 'REFRESH',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: User | null;
    stepTwo: boolean | null;
  };
  [Types.Login]: {
    user: User;
    stepTwo: boolean;
  };
  [Types.Refresh]: {
    user: User;
    stepTwo?: boolean;
  };
  [Types.Logout]: undefined;
};

export type JWTActions =
  ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  stepTwo: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        stepTwo: action.payload.stepTwo,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        stepTwo: action.payload.stepTwo,
      };
    case 'REFRESH':
      const newState = {
        ...state,
        user: action.payload.user,
      };

      if (typeof action.payload.stepTwo === 'boolean')
        newState.stepTwo = action.payload.stepTwo;

      return newState;
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

type AuthProviderProps = {
  children: ReactNode;
};

const sendUserProperties = (is_paid_customer: boolean) =>
  setUserProperties(analytics, {
    origin: 'web',
    is_paid_customer,
  });

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const [loginAndroid] = usePostUsersAndroidMutation();
  const [loginIos] = usePostUsersIosMutation();

  useEffect(() => {
    initialize();
  }, []);

  const handleOrganizations = (data: User): User => {
    const storedOrganizationId = localStorage.getItem('organization');
    const user = { ...data };

    if (storedOrganizationId && user.organizations?.length > 0) {
      const storedOrganization = user.organizations.find(
        (org) => org.id.toString() === storedOrganizationId
      );

      if (storedOrganization) {
        user.current_organization = {
          ...storedOrganization,
          has_paid_access: user.current_organization.has_paid_access,
          main_role: user.current_organization.main_role,
        } as CurrentOrganization;
      }
    }

    return user;
  };

  const initialize = async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      let stepTwo = localStorage.getItem('stepTwo');
      stepTwo = stepTwo ? JSON.parse(stepTwo) : null;

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const { data } = await UserApi.v1UserMeGet();
        const user = handleOrganizations(data as User);
        if (user.current_organization) {
          updateSession(user.current_organization.id);
          sendUserProperties(user.current_organization.has_paid_access);
        }

        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: true,
            user,
            stepTwo: stepTwo as boolean | null,
          },
        });
      } else {
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
            stepTwo: null,
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: Types.Initial,
        payload: {
          isAuthenticated: false,
          user: null,
          stepTwo: null,
        },
      });
    }
  };

  const handleLogin = async (stepTwo: boolean, accessToken: string) => {
    localStorage.setItem('stepTwo', JSON.stringify(stepTwo));
    setSession(accessToken);

    const { data } = await UserApi.v1UserMeGet();

    const user = handleOrganizations(data as User);
    if (user.current_organization) {
      updateSession(user.current_organization.id);
      sendUserProperties(user.current_organization.has_paid_access);
    }

    dispatch({
      type: Types.Login,
      payload: {
        user,
        stepTwo,
      },
    });
  };

  const androidLogin = async (arg: PostUsersAndroidApiArg) => {
    const { accessToken, step_two: stepTwo } = await loginAndroid(arg).unwrap();
    handleLogin(stepTwo, accessToken);
  };

  const iosLogin = async (arg: PostUsersIosApiArg) => {
    const { accessToken, step_two: stepTwo } = await loginIos(arg).unwrap();
    handleLogin(stepTwo, accessToken);
  };

  const emailLogin = async (
    arg: AxiosResponse<UsersAndroidPost200Response>
  ) => {
    const accessToken = arg.headers['authorization'] as string;
    const stepTwo = arg.data.step_two;
    handleLogin(stepTwo, accessToken);
  };

  const updateUser = (data: User, stepTwo?: boolean) => {
    const user = handleOrganizations(data);

    dispatch({
      type: Types.Refresh,
      payload: {
        user,
        stepTwo,
      },
    });
    updateSession(user.current_organization.id);
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        androidLogin,
        iosLogin,
        emailLogin,
        logout,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
