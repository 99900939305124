import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface DeleteModalState {
  isOpened?: boolean;
  itemName?: string;
  onDelete?: (() => void);
}


const initialState: DeleteModalState = {
  itemName: '',
  isOpened: false,
  onDelete: () => { console.log('Define me') },
};

const slice = createSlice({
  name: 'deleteModal',
  initialState,
  reducers: {
    openModal(state, { payload: { itemName, onDelete } }: PayloadAction<DeleteModalState>) {
      state.isOpened = true;
      state.itemName = itemName;
      state.onDelete = onDelete;
    },
    closeModal(state) {
      state.isOpened = false;
    }
  },
});

export const { openModal, closeModal } = slice.actions;

export default slice.reducer;

export const getModalState = (state: RootState) => ({
  ...state.deleteModal
}) 
