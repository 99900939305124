import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { useAnalytics } from 'src/hooks/useAnalytics';
import { usePermissions } from 'src/hooks/usePermissions';

import { JoinCabalPlus } from './JoinCabalPlus';
import { hasOffersEnabled } from './JoinCabalPlus/data/subscriptionTypes';

export const CabalPlusModal = () => {
  const [openModal, setOpenModal] = useState(false);
  const { is, has, isAccountType } = usePermissions();
  const { pathname } = useLocation();
  const { sendAnalyticsEvent } = useAnalytics();
  const enableOffers = hasOffersEnabled();

  useEffect(() => {
    try {
      const joinCabalPlusModalLS = localStorage.getItem('joinCabalPlusModal');
      if (!joinCabalPlusModalLS) handleOpenModal();
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => {
    sendAnalyticsEvent('user_closed_cabal_plus_modal');
    setOpenModal(false);
    localStorage.setItem('joinCabalPlusModal', 'closed');
  };

  return (
    <Dialog
      open={
        openModal &&
        isAccountType('business') &&
        is('owner') &&
        !is('guestWithoutOrganization') &&
        !has('paid_access') &&
        pathname !== '/dashboard/finalizar-registro'
      }
      onClose={handleCloseModal}
      scroll="body"
      PaperProps={{
        style: {
          width: '100%',
          maxWidth: 800,
        },
      }}
    >
      <DialogContent>
        <JoinCabalPlus enableOffers={enableOffers} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
};
