import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Stack, StackProps, TextField } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { forwardRef, useEffect, useState } from 'react';

interface IncrementerButtonProps extends StackProps {
  name?: string;
  initialValue?: number;
  value: number;
  max?: number;
  onMaxReached?: VoidFunction;
  disabledIncrease?: boolean;
  disabledDecrease?: boolean;
  onIncrease: VoidFunction;
  onDecrease: VoidFunction;
  onQtyChange: (qty: number) => void;
  disabled?: boolean;
}

const IncrementerButton = forwardRef<HTMLDivElement, IncrementerButtonProps>(
  (
    {
      initialValue,
      value,
      max,
      onIncrease,
      onDecrease,
      onQtyChange,
      onMaxReached,
      disabledIncrease,
      disabledDecrease,
      disabled,
      sx,
      ...other
    },
    ref
  ) => {
    const [internalValue, setInternalValue] = useState<string>(
      initialValue?.toString() ?? ''
    );

    useEffect(() => {
      if (value.toString() !== internalValue) {
        setInternalValue(value.toString());
      }
    }, [value]);

    useEffect(() => {
      if (internalValue === '') return;
      const parsedValue = parseInt(internalValue);
      if (isNaN(parsedValue)) return;
      if (parsedValue === initialValue) return;

      onQtyChange(parsedValue);
    }, [internalValue]);

    return (
      <Stack
        ref={ref}
        flexShrink={0}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={1}
        sx={{
          py: 0.57,
          px: 0.75,
          borderRadius: 1,
          border: (theme) =>
            `solid 1px ${alpha(theme.palette.grey[500], 0.32)}`,
          ...sx,
        }}
        {...other}
      >
        <IconButton
          size="small"
          color="inherit"
          onClick={onDecrease}
          disabled={disabledDecrease || disabled}
          sx={{ color: 'text.secondary' }}
        >
          <FontAwesomeIcon icon={faMinus} width={16} />
        </IconButton>

        <TextField
          value={internalValue}
          size="small"
          disabled={disabled}
          style={{
            backgroundColor: '#fff',
          }}
          onChange={(e) => {
            let parsedValue = e.target.value.replace(/[^0-9.]/g, '');
            let intValue = parseInt(parsedValue);

            if (!isNaN(intValue) && max && intValue > max) {
              setInternalValue(max.toString());
              if (onMaxReached) onMaxReached();
              return;
            }

            setInternalValue(parsedValue);
          }}
          onBlur={(e) => {
            if (e.target.value === '') setInternalValue(value.toString());
          }}
        />

        <IconButton
          size="small"
          color="inherit"
          onClick={onIncrease}
          disabled={disabledIncrease || disabled}
          sx={{ color: 'text.secondary' }}
        >
          <FontAwesomeIcon icon={faPlus} width={16} />
        </IconButton>
      </Stack>
    );
  }
);

export default IncrementerButton;
