import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { V1ContactsGet200ResponseContactsInner } from 'src/api-client';

import { RootState } from '../store';

export interface Contact extends V1ContactsGet200ResponseContactsInner {}

interface ContactModalState {
  isOpened?: boolean;
  contact: Contact | null;
}

const initialState: ContactModalState = {
  contact: null,
  isOpened: false,
};

const slice = createSlice({
  name: 'contactModal',
  initialState,
  reducers: {
    selectContact(
      state,
      action: PayloadAction<V1ContactsGet200ResponseContactsInner>
    ) {
      state.contact = action.payload;
      state.isOpened = false;
    },
    deleteContact(state) {
      state.contact = null;
    },
    openModal(state) {
      state.isOpened = true;
    },
    closeModal(state) {
      state.isOpened = false;
    },
  },
});

export const { openModal, closeModal, selectContact, deleteContact } =
  slice.actions;

export default slice.reducer;

export const getContactModalState = (state: RootState) => ({
  ...state.contactModal,
});
