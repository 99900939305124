// @mui
import { Box, Typography, Stack } from '@mui/material';
// assets
import { UploadIllustration } from '../../assets';

// ----------------------------------------------------------------------
type Props = {
  title?: string;
  description?: string;
};

const BlockContent: React.FC<Props> = ({
  title = 'Selecciona las imágenes',
  description = 'Arrastra los archivos o da click acá para elegir las imágenes',
}) => {
  return (
    <Box sx={{ p: 3 }}>
      <Stack
        spacing={2}
        alignItems="center"
        justifyContent="center"
        direction={{ xs: 'column', md: 'row' }}
        sx={{
          width: 1,
          maxWidth: 500,
          margin: '0 auto',
          textAlign: { xs: 'center', md: 'left' },
        }}
      >
        <UploadIllustration sx={{ width: 220 }} />
        <div>
          <Typography gutterBottom variant="h5">
            {title}
          </Typography>

          <Typography
            variant="subtitle1"
            sx={{ color: 'text.secondary', fontSize: 12 }}
          >
            {description}
          </Typography>
        </div>
      </Stack>
    </Box>
  );
};
export default BlockContent;
