import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import LoadingScreen from 'src/components/LoadingScreen';
import useAuth from 'src/hooks/useAuth';
import { usePermissions } from 'src/hooks/usePermissions';
import { PATH_DASHBOARD } from 'src/routes/paths';

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated, isInitialized } = useAuth();
  const { isAccountType } = usePermissions();

  if (isAuthenticated) {
    if (isAccountType('business')) {
      return <Navigate to={PATH_DASHBOARD.POS.root} />;
    }
    return <Navigate to={PATH_DASHBOARD.general.app} />;
  }

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
}
