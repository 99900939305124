import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useState } from 'react';

import { JoinCabalPlus } from 'src/components/CabalPlusCTA/JoinCabalPlus';
import { hasOffersEnabled } from 'src/components/CabalPlusCTA/JoinCabalPlus/data/subscriptionTypes';
import { useSubscription } from 'src/hooks/useSubscription';

export function TrialInformation() {
  const [open, setOpen] = useState(false);
  const { data } = useSubscription();
  const isTrial = Boolean(parseInt(data?.data.status as string));
  const enableOffers = hasOffersEnabled();

  let trialDaysLeft = parseInt(data?.data.status as string);
  if (trialDaysLeft < 0) trialDaysLeft = 0;
  const handleCloseModal = () => setOpen(false);

  return isTrial ? (
    <>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        scroll="body"
        PaperProps={{
          style: {
            width: '100%',
            maxWidth: 800,
          },
        }}
      >
        <DialogContent>
          <JoinCabalPlus isDirectBuy enableOffers={enableOffers} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cerrar</Button>
        </DialogActions>
      </Dialog>
      <Button
        onClick={() => setOpen(true)}
        variant="contained"
        color="secondary"
        size="small"
      >
        {isTrial
          ? `Tu período de prueba finalizará en ${trialDaysLeft} ${
              trialDaysLeft === 1 ? 'día' : 'días'
            }`
          : 'Tu período de prueba ha expirado'}
      </Button>
    </>
  ) : (
    <span />
  );
}
