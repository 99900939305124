import { CabalPermissionName } from 'src/@types/Permission';
import { PermissionDeniedMessage } from 'src/components/PermissionDeniedMessage';
import { usePermissions } from 'src/hooks/usePermissions';

type Props = {
  permissions: CabalPermissionName[];
  children: React.ReactNode;
  hasContent?: boolean;
};

export const PermissionGuard: React.FC<Props> = ({
  permissions,
  hasContent,
  children,
}) => {
  const { can } = usePermissions();

  if (!can(permissions)) {
    return hasContent ? <PermissionDeniedMessage /> : null;
  }

  return <>{children}</>;
};
