import currency from 'currency.js';

import useAuth from 'src/hooks/useAuth';

export const useFormatCurrency = () => {
  const { user } = useAuth();

  const formatCurrency = (amount: number) =>
    currency(amount).format({
      symbol: user?.current_organization.country?.currency_symbol,
      separator: user?.current_organization.money_symbol === 'dot' ? '.' : ',',
      decimal: user?.current_organization.money_symbol === 'dot' ? ',' : '.',
    });

  return {
    formatCurrency,
  };
};
