import CryptoJS from 'crypto-js'

// Note that for larger files, you may want to hash them incrementally.
// Taken from https://stackoverflow.com/questions/768268/
const md5FromFile = (file: File) : Promise<string> => 
  // FileReader is event driven, does not return promise
  // Wrap with promise api so we can call w/ async await
  // https://stackoverflow.com/questions/34495796
   new Promise((resolve, reject) => {
    const reader = new FileReader()
  
    reader.onload = (fileEvent: any) => {
      let binary = CryptoJS.lib.WordArray.create(fileEvent.target?.result);
      const md5 = CryptoJS.MD5(binary).toString(CryptoJS.enc.Base64);
      resolve(md5)
    }

    reader.onerror = () => {
      reject('oops, something went wrong with the file reader.')
    }

    reader.readAsArrayBuffer(file)
  })


export const fileChecksum = async(file: File) => md5FromFile(file)