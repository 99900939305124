import { logEvent, setUserProperties } from 'firebase/analytics';

import { CabalAnalyticsEvent } from 'src/@types/CabalAnalyticsEvent';
import { analytics } from 'src/firebase';

import useAuth from './useAuth';

export const useAnalytics = () => {
  const origin = 'web';
  const { user } = useAuth();

  if (!analytics) throw new Error('Firebase analytics is not set');

  const sendAnalyticsEvent = (eventName: CabalAnalyticsEvent) => {
    // Send Firebase event
    logEvent(analytics, eventName, {
      origin,
      is_paid_customer: user?.current_organization?.has_paid_access ?? '',
      user_email: user?.user?.email ?? '',
      user_type: user?.current_organization?.business_type ?? '',
    });
  };

  const sendUserProperties = (is_paid_customer: boolean) =>
    setUserProperties(analytics, {
      origin,
      is_paid_customer,
    });

  return {
    sendAnalyticsEvent,
    sendUserProperties,
  };
};
