import {
  V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor,
  V1UserMeGet200Response,
} from 'src/api-client';
import { baseSplitApi as api } from './baseApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postUsersAndroid: build.mutation<
      PostUsersAndroidApiResponse,
      PostUsersAndroidApiArg
    >({
      query: (queryArg) => ({
        url: `/users/android`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postUsersIos: build.mutation<PostUsersIosApiResponse, PostUsersIosApiArg>({
      query: (queryArg) => ({
        url: `/users/ios`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postUsersGuest: build.mutation<
      PostUsersGuestApiResponse,
      PostUsersGuestApiArg
    >({
      query: (queryArg) => ({
        url: `/users/guest`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postUsersGuestAndroid: build.mutation<
      PostUsersGuestAndroidApiResponse,
      PostUsersGuestAndroidApiArg
    >({
      query: (queryArg) => ({
        url: `/users/guest/android`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postUsersGuestIos: build.mutation<
      PostUsersGuestIosApiResponse,
      PostUsersGuestIosApiArg
    >({
      query: (queryArg) => ({
        url: `/users/guest/ios`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    deleteUsersLogout: build.mutation<
      DeleteUsersLogoutApiResponse,
      DeleteUsersLogoutApiArg
    >({
      query: () => ({ url: `/users/logout`, method: 'DELETE' }),
    }),
    getV1CashFlow: build.query<GetV1CashFlowApiResponse, GetV1CashFlowApiArg>({
      query: (queryArg) => ({
        url: `/v1/cash-flow`,
        params: {
          from: queryArg['from'],
          until: queryArg.until,
          page: queryArg.page,
        },
      }),
    }),
    getV1Charts: build.query<GetV1ChartsApiResponse, GetV1ChartsApiArg>({
      query: (queryArg) => ({
        url: `/v1/charts`,
        params: {
          group_by: queryArg.groupBy,
          from: queryArg['from'],
          until: queryArg.until,
        },
      }),
    }),
    getV1ContactsDebtsTransactions: build.query<
      GetV1ContactsDebtsTransactionsApiResponse,
      GetV1ContactsDebtsTransactionsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/contacts-debts-transactions`,
        params: { page: queryArg.page },
      }),
    }),
    getV1ContactsDebtsTransactionsById: build.query<
      GetV1ContactsDebtsTransactionsByIdApiResponse,
      GetV1ContactsDebtsTransactionsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/contacts-debts-transactions/${queryArg.id}`,
      }),
    }),
    getV1Contacts: build.query<GetV1ContactsApiResponse, GetV1ContactsApiArg>({
      query: (queryArg) => ({
        url: `/v1/contacts`,
        params: { page: queryArg.page, content: queryArg.content },
      }),
    }),
    postV1Contacts: build.mutation<
      PostV1ContactsApiResponse,
      PostV1ContactsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/contacts`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getV1ContactsById: build.query<
      GetV1ContactsByIdApiResponse,
      GetV1ContactsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/v1/contacts/${queryArg.id}` }),
    }),
    patchV1ContactsById: build.mutation<
      PatchV1ContactsByIdApiResponse,
      PatchV1ContactsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/contacts/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    deleteV1ContactsById: build.mutation<
      DeleteV1ContactsByIdApiResponse,
      DeleteV1ContactsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/contacts/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getV1Countries: build.query<
      GetV1CountriesApiResponse,
      GetV1CountriesApiArg
    >({
      query: () => ({ url: `/v1/countries` }),
    }),
    getV1DebtsTransactions: build.query<
      GetV1DebtsTransactionsApiResponse,
      GetV1DebtsTransactionsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/debts-transactions`,
        params: { page: queryArg.page },
      }),
    }),
    getV1DebtsTransactionsById: build.query<
      GetV1DebtsTransactionsByIdApiResponse,
      GetV1DebtsTransactionsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/v1/debts-transactions/${queryArg.id}` }),
    }),
    getV1DeliveryAreas: build.query<
      GetV1DeliveryAreasApiResponse,
      GetV1DeliveryAreasApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/delivery-areas`,
        params: { page: queryArg.page, content: queryArg.content },
      }),
    }),
    postV1DeliveryAreas: build.mutation<
      PostV1DeliveryAreasApiResponse,
      PostV1DeliveryAreasApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/delivery-areas`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getV1DeliveryAreasById: build.query<
      GetV1DeliveryAreasByIdApiResponse,
      GetV1DeliveryAreasByIdApiArg
    >({
      query: (queryArg) => ({ url: `/v1/delivery-areas/${queryArg.id}` }),
    }),
    patchV1DeliveryAreasById: build.mutation<
      PatchV1DeliveryAreasByIdApiResponse,
      PatchV1DeliveryAreasByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/delivery-areas/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    deleteV1DeliveryAreasById: build.mutation<
      DeleteV1DeliveryAreasByIdApiResponse,
      DeleteV1DeliveryAreasByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/delivery-areas/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    postV1DebtsTransactionsByDebtsTransactionIdDeposits: build.mutation<
      PostV1DebtsTransactionsByDebtsTransactionIdDepositsApiResponse,
      PostV1DebtsTransactionsByDebtsTransactionIdDepositsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/debts-transactions/${queryArg.debtsTransactionId}/deposits`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    deleteV1DebtsTransactionsByDebtsTransactionIdDepositsAndId: build.mutation<
      DeleteV1DebtsTransactionsByDebtsTransactionIdDepositsAndIdApiResponse,
      DeleteV1DebtsTransactionsByDebtsTransactionIdDepositsAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/debts-transactions/${queryArg.debtsTransactionId}/deposits/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    postV1DeviceInfos: build.mutation<
      PostV1DeviceInfosApiResponse,
      PostV1DeviceInfosApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/device-infos`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postV1DirectUpload: build.mutation<
      PostV1DirectUploadApiResponse,
      PostV1DirectUploadApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/direct-upload`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getV1ExpensesTransactions: build.query<
      GetV1ExpensesTransactionsApiResponse,
      GetV1ExpensesTransactionsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/expenses-transactions`,
        params: { page: queryArg.page },
      }),
    }),
    postV1ExpensesTransactions: build.mutation<
      PostV1ExpensesTransactionsApiResponse,
      PostV1ExpensesTransactionsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/expenses-transactions`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getV1ExpensesTransactionsById: build.query<
      GetV1ExpensesTransactionsByIdApiResponse,
      GetV1ExpensesTransactionsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/expenses-transactions/${queryArg.id}`,
      }),
    }),
    patchV1ExpensesTransactionsById: build.mutation<
      PatchV1ExpensesTransactionsByIdApiResponse,
      PatchV1ExpensesTransactionsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/expenses-transactions/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    deleteV1ExpensesTransactionsById: build.mutation<
      DeleteV1ExpensesTransactionsByIdApiResponse,
      DeleteV1ExpensesTransactionsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/expenses-transactions/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getV1Fcm: build.query<GetV1FcmApiResponse, GetV1FcmApiArg>({
      query: (queryArg) => ({
        url: `/v1/fcm`,
        params: { screen: queryArg.screen },
      }),
    }),
    getV1GamificationBasicRoadmap: build.query<
      GetV1GamificationBasicRoadmapApiResponse,
      GetV1GamificationBasicRoadmapApiArg
    >({
      query: () => ({ url: `/v1/gamification/basic-roadmap` }),
    }),
    getV1Industries: build.query<
      GetV1IndustriesApiResponse,
      GetV1IndustriesApiArg
    >({
      query: () => ({ url: `/v1/industries` }),
    }),
    getV1NotificationsOrganization: build.query<
      GetV1NotificationsOrganizationApiResponse,
      GetV1NotificationsOrganizationApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/notifications/organization`,
        params: { page: queryArg.page },
      }),
    }),
    getV1NotificationsPlatform: build.query<
      GetV1NotificationsPlatformApiResponse,
      GetV1NotificationsPlatformApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/notifications/platform`,
        params: { page: queryArg.page },
      }),
    }),
    postV1NotificationsByIdSeen: build.mutation<
      PostV1NotificationsByIdSeenApiResponse,
      PostV1NotificationsByIdSeenApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/notifications/${queryArg.id}/seen`,
        method: 'POST',
      }),
    }),
    getV1OrganizationSetting: build.query<
      GetV1OrganizationSettingApiResponse,
      GetV1OrganizationSettingApiArg
    >({
      query: () => ({ url: `/v1/organization-setting` }),
    }),
    patchV1OrganizationSetting: build.mutation<
      PatchV1OrganizationSettingApiResponse,
      PatchV1OrganizationSettingApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/organization-setting`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    patchV1Organizations: build.mutation<
      PatchV1OrganizationsApiResponse,
      PatchV1OrganizationsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/organizations`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    getV1ProductCategories: build.query<
      GetV1ProductCategoriesApiResponse,
      GetV1ProductCategoriesApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/product-categories`,
        params: { page: queryArg.page, content: queryArg.content },
      }),
    }),
    postV1ProductCategories: build.mutation<
      PostV1ProductCategoriesApiResponse,
      PostV1ProductCategoriesApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/product-categories`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getV1ProductCategoriesById: build.query<
      GetV1ProductCategoriesByIdApiResponse,
      GetV1ProductCategoriesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/v1/product-categories/${queryArg.id}` }),
    }),
    patchV1ProductCategoriesById: build.mutation<
      PatchV1ProductCategoriesByIdApiResponse,
      PatchV1ProductCategoriesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/product-categories/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    deleteV1ProductCategoriesById: build.mutation<
      DeleteV1ProductCategoriesByIdApiResponse,
      DeleteV1ProductCategoriesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/product-categories/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    patchV1ProductCategoriesByIdBatchUpdate: build.mutation<
      PatchV1ProductCategoriesByIdBatchUpdateApiResponse,
      PatchV1ProductCategoriesByIdBatchUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/product-categories/${queryArg.id}/batch-update`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    getV1ProductCategoriesByIdProducts: build.query<
      GetV1ProductCategoriesByIdProductsApiResponse,
      GetV1ProductCategoriesByIdProductsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/product-categories/${queryArg.id}/products`,
      }),
    }),
    deleteV1ProductsByProductIdImagesAndId: build.mutation<
      DeleteV1ProductsByProductIdImagesAndIdApiResponse,
      DeleteV1ProductsByProductIdImagesAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/products/${queryArg.productId}/images/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getV1Products: build.query<GetV1ProductsApiResponse, GetV1ProductsApiArg>({
      query: (queryArg) => ({
        url: `/v1/products`,
        params: {
          page: queryArg.page,
          by: queryArg.by,
          content: queryArg.content,
          product_category_ids: queryArg.productCategoryIds,
          ids: queryArg.ids,
          order: queryArg.order,
        },
      }),
    }),
    postV1Products: build.mutation<
      PostV1ProductsApiResponse,
      PostV1ProductsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/products`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getV1ProductsById: build.query<
      GetV1ProductsByIdApiResponse,
      GetV1ProductsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/v1/products/${queryArg.id}` }),
    }),
    patchV1ProductsById: build.mutation<
      PatchV1ProductsByIdApiResponse,
      PatchV1ProductsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/products/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    deleteV1ProductsById: build.mutation<
      DeleteV1ProductsByIdApiResponse,
      DeleteV1ProductsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/products/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    postV1ProductsByIdImagesOrder: build.mutation<
      PostV1ProductsByIdImagesOrderApiResponse,
      PostV1ProductsByIdImagesOrderApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/products/${queryArg.id}/images-order`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getV1ProductsUncategorized: build.query<
      GetV1ProductsUncategorizedApiResponse,
      GetV1ProductsUncategorizedApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/products/uncategorized`,
        params: { page: queryArg.page, content: queryArg.content },
      }),
    }),
    getV1Receipts: build.query<GetV1ReceiptsApiResponse, GetV1ReceiptsApiArg>({
      query: (queryArg) => ({
        url: `/v1/receipts`,
        params: { from: queryArg['from'], until: queryArg.until },
      }),
    }),
    getV1ReceiptsByIdPdf: build.query<
      GetV1ReceiptsByIdPdfApiResponse,
      GetV1ReceiptsByIdPdfApiArg
    >({
      query: (queryArg) => ({ url: `/v1/receipts/${queryArg.id}.pdf` }),
    }),
    getV1Roles: build.query<GetV1RolesApiResponse, GetV1RolesApiArg>({
      query: () => ({ url: `/v1/roles` }),
    }),
    postV1Roles: build.mutation<PostV1RolesApiResponse, PostV1RolesApiArg>({
      query: (queryArg) => ({
        url: `/v1/roles`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getV1RolesById: build.query<
      GetV1RolesByIdApiResponse,
      GetV1RolesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/v1/roles/${queryArg.id}` }),
    }),
    patchV1RolesById: build.mutation<
      PatchV1RolesByIdApiResponse,
      PatchV1RolesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/roles/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    deleteV1RolesById: build.mutation<
      DeleteV1RolesByIdApiResponse,
      DeleteV1RolesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/roles/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getV1SalesTransactions: build.query<
      GetV1SalesTransactionsApiResponse,
      GetV1SalesTransactionsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/sales-transactions`,
        params: { page: queryArg.page },
      }),
    }),
    postV1SalesTransactions: build.mutation<
      PostV1SalesTransactionsApiResponse,
      PostV1SalesTransactionsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/sales-transactions`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getV1SalesTransactionsById: build.query<
      GetV1SalesTransactionsByIdApiResponse,
      GetV1SalesTransactionsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/v1/sales-transactions/${queryArg.id}` }),
    }),
    patchV1SalesTransactionsById: build.mutation<
      PatchV1SalesTransactionsByIdApiResponse,
      PatchV1SalesTransactionsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/sales-transactions/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    deleteV1SalesTransactionsById: build.mutation<
      DeleteV1SalesTransactionsByIdApiResponse,
      DeleteV1SalesTransactionsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/sales-transactions/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getV1Stats: build.query<GetV1StatsApiResponse, GetV1StatsApiArg>({
      query: (queryArg) => ({
        url: `/v1/stats`,
        params: { from: queryArg['from'], until: queryArg.until },
      }),
    }),
    getV1TransactionCategories: build.query<
      GetV1TransactionCategoriesApiResponse,
      GetV1TransactionCategoriesApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/transaction-categories`,
        params: {
          page: queryArg.page,
          content: queryArg.content,
          transaction_type_id: queryArg.transactionTypeId,
        },
      }),
    }),
    postV1TransactionCategories: build.mutation<
      PostV1TransactionCategoriesApiResponse,
      PostV1TransactionCategoriesApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/transaction-categories`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getV1TransactionCategoriesById: build.query<
      GetV1TransactionCategoriesByIdApiResponse,
      GetV1TransactionCategoriesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/transaction-categories/${queryArg.id}`,
      }),
    }),
    patchV1TransactionCategoriesById: build.mutation<
      PatchV1TransactionCategoriesByIdApiResponse,
      PatchV1TransactionCategoriesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/transaction-categories/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    deleteV1TransactionCategoriesById: build.mutation<
      DeleteV1TransactionCategoriesByIdApiResponse,
      DeleteV1TransactionCategoriesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/transaction-categories/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    patchV1TransactionCategoriesByIdBatchUpdate: build.mutation<
      PatchV1TransactionCategoriesByIdBatchUpdateApiResponse,
      PatchV1TransactionCategoriesByIdBatchUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/transaction-categories/${queryArg.id}/batch-update`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    getV1TransactionCategoriesByIdTransactions: build.query<
      GetV1TransactionCategoriesByIdTransactionsApiResponse,
      GetV1TransactionCategoriesByIdTransactionsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/transaction-categories/${queryArg.id}/transactions`,
      }),
    }),
    getV1Transactions: build.query<
      GetV1TransactionsApiResponse,
      GetV1TransactionsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/transactions`,
        params: {
          from: queryArg['from'],
          until: queryArg.until,
          page: queryArg.page,
        },
      }),
    }),
    getV1TransactionsDetails: build.query<
      GetV1TransactionsDetailsApiResponse,
      GetV1TransactionsDetailsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/transactions/details`,
        params: { from: queryArg['from'], until: queryArg.until },
      }),
    }),
    getV1UserMe: build.query<V1UserMeGet200Response, GetV1UserMeApiArg>({
      query: () => ({ url: `/v1/user/me` }),
    }),
    patchV1User: build.mutation<PatchV1UserApiResponse, PatchV1UserApiArg>({
      query: (queryArg) => ({
        url: `/v1/user`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    patchV1UserProgramDestroy: build.mutation<
      PatchV1UserProgramDestroyApiResponse,
      PatchV1UserProgramDestroyApiArg
    >({
      query: () => ({ url: `/v1/user/program-destroy`, method: 'PATCH' }),
    }),
    getV1Version: build.query<GetV1VersionApiResponse, GetV1VersionApiArg>({
      query: () => ({ url: `/v1/version` }),
    }),
    getV1WebOrganizationsBySlug: build.query<
      GetV1WebOrganizationsBySlugApiResponse,
      GetV1WebOrganizationsBySlugApiArg
    >({
      query: (queryArg) => ({ url: `/v1/web/organizations/${queryArg.slug}` }),
    }),
    getV1WebOrganizationsBySlugProductCategories: build.query<
      GetV1WebOrganizationsBySlugProductCategoriesApiResponse,
      GetV1WebOrganizationsBySlugProductCategoriesApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/web/organizations/${queryArg.slug}/product-categories`,
        params: { page: queryArg.page, content: queryArg.content },
      }),
    }),
    getV1WebOrganizationsBySlugProducts: build.query<
      GetV1WebOrganizationsBySlugProductsApiResponse,
      GetV1WebOrganizationsBySlugProductsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/web/organizations/${queryArg.slug}/products`,
        params: {
          page: queryArg.page,
          by: queryArg.by,
          content: queryArg.content,
          category_ids: queryArg.categoryIds,
          order: queryArg.order,
        },
      }),
    }),
    postV1WebSalesTransactionsByCodeRatings: build.mutation<
      PostV1WebSalesTransactionsByCodeRatingsApiResponse,
      PostV1WebSalesTransactionsByCodeRatingsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/web/sales-transactions/${queryArg.code}/ratings`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postV1WebOrganizationsBySlugSalesTransactions: build.mutation<
      PostV1WebOrganizationsBySlugSalesTransactionsApiResponse,
      PostV1WebOrganizationsBySlugSalesTransactionsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/web/organizations/${queryArg.slug}/sales-transactions`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getV1WebTransactions: build.query<
      GetV1WebTransactionsApiResponse,
      GetV1WebTransactionsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/web-transactions`,
        params: { process_status: queryArg.processStatus, page: queryArg.page },
      }),
    }),
    getV1WebTransactionsById: build.query<
      GetV1WebTransactionsByIdApiResponse,
      GetV1WebTransactionsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/v1/web-transactions/${queryArg.id}` }),
    }),
    patchV1WebTransactionsByIdApprove: build.mutation<
      PatchV1WebTransactionsByIdApproveApiResponse,
      PatchV1WebTransactionsByIdApproveApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/web-transactions/${queryArg.id}/approve`,
        method: 'PATCH',
      }),
    }),
    patchV1WebTransactionsByIdReject: build.mutation<
      PatchV1WebTransactionsByIdRejectApiResponse,
      PatchV1WebTransactionsByIdRejectApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/web-transactions/${queryArg.id}/reject`,
        method: 'PATCH',
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as cabalApi };
export type PostUsersAndroidApiResponse = /** status 200 login#android */ {
  step_two: boolean;
};
export type PostUsersAndroidApiArg = {
  body: {
    android: {
      accessToken: string;
    };
  };
};
export type PostUsersIosApiResponse = /** status 200 login#ios */ {
  step_two: boolean;
};
export type PostUsersIosApiArg = {
  body: {
    ios: {
      identityToken: string;
      user: string;
      email: string;
      authorizationCode: string;
      fullName: {
        givenName: string;
      };
    };
  };
};
export type PostUsersGuestApiResponse = unknown;
export type PostUsersGuestApiArg = {
  body: {
    provider: 'ios' | 'android';
  };
};
export type PostUsersGuestAndroidApiResponse =
  /** status 200 guest_users#update_android */ {
    user_exists: boolean;
  };
export type PostUsersGuestAndroidApiArg = {
  body: {
    android: {
      accessToken: string;
    };
  };
};
export type PostUsersGuestIosApiResponse =
  /** status 200 guest_users#update_ios */ {
    user_exists: boolean;
  };
export type PostUsersGuestIosApiArg = {
  body: {
    ios: {
      identityToken: string;
      user: string;
      email: string;
      authorizationCode: string;
      fullName: {
        givenName: string;
      };
    };
  };
};
export type DeleteUsersLogoutApiResponse = unknown;
export type DeleteUsersLogoutApiArg = void;
export type GetV1CashFlowApiResponse = /** status 200 cash_flow#index */ {
  transactions: {
    id: number;
    description: string;
    transaction_code: string;
    amount_total: string;
    payment_method: string;
    transaction_type: string;
    payment_type: string;
    parent_transaction_type?: string | null;
    parent_id: number | null;
    skip_cost: boolean;
    created_at: string;
  }[];
  pagination: {
    page: number;
    prev: number | null;
    next: number | null;
    last: number;
  };
};
export type GetV1CashFlowApiArg = {
  from?: string;
  until?: string;
  page?: number;
};
export type GetV1ChartsApiResponse = /** status 200 charts#index */ {
  expenses: {
    date: string;
    amount: number;
    label: string;
  }[];
  sales: {
    date: string;
    amount: number;
    label: string;
  }[];
  debts: {
    date: string;
    amount: number;
    label: string;
  }[];
  costs: {
    date: string;
    amount: number;
    label: string;
  }[];
};
export type GetV1ChartsApiArg = {
  groupBy?: 'month' | 'week';
  from?: string;
  until?: string;
};
export type GetV1ContactsDebtsTransactionsApiResponse =
  /** status 200 contacts_debts_transactions#index */ {
    contacts: {
      contact_id: number;
      contact_name: string;
      contact_phone: string;
      contact_email: string | null;
      transactions: number;
      total_debt: string;
      total_paid: string;
    }[];
    pagination: {
      page: number;
      prev: number | null;
      next: number | null;
      last: number;
      count: number;
    };
  };
export type GetV1ContactsDebtsTransactionsApiArg = {
  page?: number;
};
export type GetV1ContactsDebtsTransactionsByIdApiResponse =
  /** status 200 contacts_debts_transactions#show */ {
    contact_id: number;
    contact_name: string;
    contact_phone: string;
    contact_email: string | null;
    total_debts_count?: number;
    total_debts: string;
    total_paid?: string;
    transactions: {
      id: number;
      amount_due: string;
      amount_paid: string;
      amount_free: string;
      amount_discount: string;
      amount_products: string;
      amount_utility: string;
      amount_cost: string;
      code?: string;
      concept: string;
      transaction_type: string;
      pay_at: string;
      created_at: string;
      updated_at: string;
    }[];
    pagination: {
      page: number;
      prev: number | null;
      next: number | null;
      last: number;
      count: number;
    };
  };
export type GetV1ContactsDebtsTransactionsByIdApiArg = {
  id: string;
};
export type GetV1ContactsApiResponse = /** status 200 contacts#index */ {
  contacts: {
    id: number;
    name: string;
    phone: string;
    email?: string | null;
    created_at: string;
    updated_at: string;
  }[];
  pagination: {
    page: number;
    prev: number | null;
    next: number | null;
    last: number;
    count: number;
  };
};
export type GetV1ContactsApiArg = {
  page?: number;
  content?: string;
};
export type PostV1ContactsApiResponse = /** status 201 contacts#create */ {
  id: number;
  name: string;
  phone: string;
  email?: string | null;
  created_at: string;
  updated_at: string;
};
export type PostV1ContactsApiArg = {
  body: {
    contact: {
      name: string;
      phone: string;
      email?: string | null;
    };
  };
};
export type GetV1ContactsByIdApiResponse = /** status 200 contacts#show */ {
  id: number;
  name: string;
  phone: string;
  email?: string | null;
  created_at: string;
  updated_at: string;
};
export type GetV1ContactsByIdApiArg = {
  id: string;
};
export type PatchV1ContactsByIdApiResponse = /** status 200 contacts#update */ {
  id: number;
  name: string;
  phone: string;
  email?: string | null;
  created_at: string;
  updated_at: string;
};
export type PatchV1ContactsByIdApiArg = {
  id: string;
  body: {
    contact: {
      name: string;
      phone: string;
      email?: string | null;
    };
  };
};
export type DeleteV1ContactsByIdApiResponse = unknown;
export type DeleteV1ContactsByIdApiArg = {
  id: string;
};
export type GetV1CountriesApiResponse = /** status 200 countries#index */ {
  countries: {
    id: number;
    name: string;
    iso_code: string;
    currency_name: string;
    currency_symbol: string;
    phone_area_number: string;
  }[];
};
export type GetV1CountriesApiArg = void;
export type GetV1DebtsTransactionsApiResponse =
  /** status 200 debt_transactions#index */ {
    transactions: {
      id: number;
      amount_due: string;
      amount_paid: string;
      amount_free: string;
      amount_discount: string;
      amount_products: string;
      amount_utility: string;
      amount_cost: string;
      code?: string;
      skip_cost: boolean;
      contact?: {
        id?: number;
        name?: string;
        phone?: string;
      } | null;
      concept: string;
      transaction_type: string;
      pay_at: string;
      created_at: string;
      updated_at: string;
    }[];
    pagination: {
      page: number;
      prev: number | null;
      next: number | null;
      last: number;
    };
    total_sales: string;
    total_expenses: string;
  };
export type GetV1DebtsTransactionsApiArg = {
  page?: number;
};
export type GetV1DebtsTransactionsByIdApiResponse =
  /** status 200 debt_transactions#show */ {
    id: number;
    amount_due: string;
    amount_paid: string;
    amount_free: string;
    amount_discount: string;
    amount_products: string;
    code?: string;
    skip_cost: boolean;
    contact?: {
      id?: number;
      name?: string;
      phone?: string;
    } | null;
    concept: string;
    transaction_type: string;
    transaction_status: string;
    payment_type: string;
    payment_method: string;
    deposits: {
      id: number;
      concept: string;
      amount: number;
      payment_method: string;
      created_at: string;
    }[];
    products: {
      id?: number;
      name?: string;
      description?: string | null;
      cost?: string;
      price?: string;
      quantity?: number;
      barcode?: string | null;
      images?: {
        id: number;
        image_url: string;
      }[];
      category?: {
        name: string;
        description: string;
      };
    }[];
    created_at: string;
    updated_at: string;
    pay_at: string | null;
  };
export type GetV1DebtsTransactionsByIdApiArg = {
  id: string;
};
export type GetV1DeliveryAreasApiResponse =
  /** status 200 delivery_areas#index */ {
    delivery_areas: {
      id: number;
      name: string;
      description: string | null;
      amount: string;
      created_at: string;
      updated_at: string;
    }[];
    pagination: {
      page: number;
      prev: number | null;
      next: number | null;
      last: number;
      count: number;
    };
  };
export type GetV1DeliveryAreasApiArg = {
  page?: number;
  content?: string;
};
export type PostV1DeliveryAreasApiResponse =
  /** status 201 delivery_areas#create */ {
    id: number;
    name: string;
    description: string | null;
    amount: string;
    created_at: string;
    updated_at: string;
  };
export type PostV1DeliveryAreasApiArg = {
  body: {
    delivery_area: {
      name: string;
      description: string;
      amount: string;
    };
  };
};
export type GetV1DeliveryAreasByIdApiResponse =
  /** status 200 delivery_areas#show */ {
    id: number;
    name: string;
    description: string | null;
    amount: string;
    created_at: string;
    updated_at: string;
  };
export type GetV1DeliveryAreasByIdApiArg = {
  id: string;
};
export type PatchV1DeliveryAreasByIdApiResponse =
  /** status 200 delivery_areas#update */ {
    id: number;
    name: string;
    description: string | null;
    amount: string;
    created_at: string;
    updated_at: string;
  };
export type PatchV1DeliveryAreasByIdApiArg = {
  id: string;
  body: {
    delivery_area: {
      name: string;
      description: string;
      amount: string;
    };
  };
};
export type DeleteV1DeliveryAreasByIdApiResponse = unknown;
export type DeleteV1DeliveryAreasByIdApiArg = {
  id: string;
};
export type PostV1DebtsTransactionsByDebtsTransactionIdDepositsApiResponse =
  /** status 201 deposits#create */ {
    id: number;
    payment_method: string;
    concept: string;
    amount: number;
    created_at: string;
    updated_at: string;
  };
export type PostV1DebtsTransactionsByDebtsTransactionIdDepositsApiArg = {
  debtsTransactionId: string;
  body: {
    deposit: {
      payment_method: 'cash' | 'on-credit' | 'card' | 'other' | 'bank';
      concept?: string;
      amount: number;
    };
  };
};
export type DeleteV1DebtsTransactionsByDebtsTransactionIdDepositsAndIdApiResponse =
  unknown;
export type DeleteV1DebtsTransactionsByDebtsTransactionIdDepositsAndIdApiArg = {
  debtsTransactionId: string;
  id: string;
};
export type PostV1DeviceInfosApiResponse = unknown;
export type PostV1DeviceInfosApiArg = {
  body: {
    device_info: {
      fcm_token: string;
    };
  };
};
export type PostV1DirectUploadApiResponse =
  /** status 201 direct_upload#create */ {
    direct_upload: {
      url?: string;
      headers?: {
        'Content-Type'?: string;
        'Content-MD5'?: string;
      };
    };
    blob_signed_id: string;
  };
export type PostV1DirectUploadApiArg = {
  body: {
    file: {
      filename: string;
      byte_size: number;
      checksum: string;
      content_type: string;
    };
  };
};
export type GetV1ExpensesTransactionsApiResponse =
  /** status 200 transactions#index */ {
    transactions: {
      id: number;
      amount_due: string;
      amount_paid: string;
      amount_free: string;
      amount_products: string;
      amount_utility: string;
      amount_cost: string;
      amount_total: string;
      code: string;
      skip_cost: boolean;
      contact: {
        id?: number;
        name?: string;
        phone?: string;
      } | null;
      transaction_category: {
        id?: number;
        name?: string;
        color?: string;
        icon?: string;
      } | null;
      concept: string;
      transaction_type: string;
      transaction_status: string;
      payment_type: string;
      payment_method: string;
      products: {
        id: number;
        name: string;
        description: string | null;
        cost: string;
        price: string;
        stock: number;
        quantity: number;
        barcode: string | null;
        images: {
          id: number;
          image_url: string;
        }[];
        product_category?: {
          id: number;
          name: string;
        } | null;
        created_at: string;
        updated_at: string;
      }[];
      created_at: string;
      updated_at: string;
      pay_at: string | null;
    }[];
    pagination: {
      page: number;
      prev: number | null;
      next: number | null;
      last: number;
    };
  };
export type GetV1ExpensesTransactionsApiArg = {
  page?: number;
};
export type PostV1ExpensesTransactionsApiResponse =
  /** status 201 transactions#create */ {
    id: number;
    amount_total: string;
    amount_due: string;
    amount_paid: string;
    amount_free: string;
    amount_products: string;
    amount_utility: string;
    amount_cost: string;
    code: string;
    skip_cost: boolean;
    contact: {
      id?: number;
      name?: string;
      phone?: string;
    } | null;
    transaction_category: {
      id?: number;
      name?: string;
    } | null;
    concept: string;
    transaction_type: string;
    transaction_status: string;
    payment_type: string;
    payment_method: string;
    products: {
      id: number;
      name: string;
      description: string | null;
      cost: string;
      price: string;
      stock: number;
      quantity: number;
      barcode: string | null;
      images: {
        id: number;
        image_url: string;
      }[];
      product_category?: {
        id: number;
        name: string;
      } | null;
      created_at: string;
      updated_at: string;
    }[];
    created_at: string;
    updated_at: string;
    pay_at: string | null;
  };
export type PostV1ExpensesTransactionsApiArg = {
  body: {
    expense: {
      products: {
        id: number;
        name: string;
        description: string | null;
        cost: string;
        price: string;
        stock: number;
        quantity: number;
        barcode: string | null;
        images: {
          id: number;
          image_url: string;
        }[];
        product_category?: {
          id: number;
          name: string;
        } | null;
        created_at: string;
        updated_at: string;
      }[];
      amount?: number;
      concept?: string;
      contact_id?: number | null;
      payment_type: 'in-cash' | 'credit';
      payment_method: 'cash' | 'on-credit' | 'card' | 'other' | 'bank';
      created_at: string;
      pay_at?: string | null;
      skip_cost?: boolean;
      transaction_category_id?: number | null;
      transaction_origin?: string;
    };
  };
};
export type GetV1ExpensesTransactionsByIdApiResponse =
  /** status 200 transactions#show */ {
    id: number;
    amount_due: string;
    amount_paid: string;
    amount_free: string;
    amount_products: string;
    amount_utility: string;
    amount_cost: string;
    amount_total: string;
    code: string;
    skip_cost: boolean;
    contact: {
      id?: number;
      name?: string;
      phone?: string;
    } | null;
    transaction_category: {
      id?: number;
      name?: string;
    } | null;
    concept: string;
    transaction_type: string;
    transaction_status: string;
    payment_type: string;
    payment_method: string;
    deposits: {
      id?: number;
      concept?: string;
      amount?: string;
      payment_method?: string;
      created_at?: string;
    }[];
    products: {
      id: number;
      name: string;
      description: string | null;
      cost: string;
      price: string;
      stock: number;
      quantity: number;
      barcode: string | null;
      images: {
        id: number;
        image_url: string;
      }[];
      product_category?: {
        id: number;
        name: string;
      } | null;
      created_at: string;
      updated_at: string;
    }[];
    created_at: string;
    updated_at: string;
    pay_at: string | null;
  };
export type GetV1ExpensesTransactionsByIdApiArg = {
  id: string;
};
export type PatchV1ExpensesTransactionsByIdApiResponse =
  /** status 200 transactions#update */ {
    id: number;
    amount_total: string;
    amount_due: string;
    amount_paid: string;
    amount_free: string;
    amount_products: string;
    amount_utility: string;
    amount_cost: string;
    code: string;
    skip_cost: boolean;
    contact: {
      id?: number;
      name?: string;
      phone?: string;
    } | null;
    transaction_category: {
      id?: number;
      name?: string;
    } | null;
    concept: string;
    transaction_type: string;
    transaction_status: string;
    payment_type: string;
    payment_method: string;
    products: {
      id: number;
      name: string;
      description: string | null;
      cost: string;
      price: string;
      stock: number;
      quantity: number;
      barcode: string | null;
      images: {
        id: number;
        image_url: string;
      }[];
      product_category?: {
        id: number;
        name: string;
      } | null;
      created_at: string;
      updated_at: string;
    }[];
    created_at: string;
    updated_at: string;
    pay_at: string | null;
  };
export type PatchV1ExpensesTransactionsByIdApiArg = {
  id: string;
  body: {
    expense: {
      products: {
        id?: number;
        quantity?: number;
      }[];
      amount?: number;
      concept?: string;
      contact_id?: number;
      payment_type: 'in-cash' | 'credit';
      payment_method: 'cash' | 'on-credit' | 'card' | 'other' | 'bank';
      created_at: string;
      skip_cost?: boolean;
      transaction_category_id?: number | null;
      pay_at?: string | null;
    };
  };
};
export type DeleteV1ExpensesTransactionsByIdApiResponse = unknown;
export type DeleteV1ExpensesTransactionsByIdApiArg = {
  id: string;
};
export type GetV1FcmApiResponse = unknown;
export type GetV1FcmApiArg = {
  screen?: string;
};
export type GetV1GamificationBasicRoadmapApiResponse =
  /** status 200 gamification#basic_roadmap */ {
    org_settings: boolean;
    products_count: number;
    sales_count: number;
    expenses_count: number;
  };
export type GetV1GamificationBasicRoadmapApiArg = void;
export type GetV1IndustriesApiResponse = /** status 200 industries#index */ {
  industries: string[];
};
export type GetV1IndustriesApiArg = void;
export type GetV1NotificationsOrganizationApiResponse =
  /** status 200 notifications#organization */ {
    notifications: {
      id: number;
      content: string;
      seen?: boolean;
      created_at: string;
      updated_at: string;
    }[];
    pagination: {
      page: number;
      prev: number | null;
      next: number | null;
      last: number;
      count: number;
    };
  };
export type GetV1NotificationsOrganizationApiArg = {
  page?: number;
};
export type GetV1NotificationsPlatformApiResponse =
  /** status 200 notifications#platform */ {
    notifications: {
      id: number;
      title: string;
      content: string;
      created_at: string;
      updated_at: string;
    }[];
    pagination: {
      page: number;
      prev: number | null;
      next: number | null;
      last: number;
      count: number;
    };
  };
export type GetV1NotificationsPlatformApiArg = {
  page?: number;
};
export type PostV1NotificationsByIdSeenApiResponse = unknown;
export type PostV1NotificationsByIdSeenApiArg = {
  id: string;
};
export type GetV1OrganizationSettingApiResponse =
  /** status 200 organization_settings#show */ {
    pick_in_store: boolean;
    delivery: boolean;
    pay_with_cash: boolean;
    pay_with_card: boolean;
    pay_with_bank: boolean;
    monday_from: string | null;
    monday_to: string | null;
    tuesday_from: string | null;
    tuesday_to: string | null;
    wednesday_from: string | null;
    wednesday_to: string | null;
    thursday_from: string | null;
    thursday_to: string | null;
    friday_from: string | null;
    friday_to: string | null;
    saturday_from: string | null;
    saturday_to: string | null;
    sunday_from: string | null;
    sunday_to: string | null;
    image: string | null;
    description: string | null;
    facebook: string | null;
    instagram: string | null;
    twitter: string | null;
    tik_tok: string | null;
    fixed_rate: string | null;
    shareable: boolean;
  };
export type GetV1OrganizationSettingApiArg = void;
export type PatchV1OrganizationSettingApiResponse =
  /** status 200 organization_settings#update */ {
    pick_in_store: boolean;
    delivery: boolean;
    pay_with_cash: boolean;
    pay_with_card: boolean;
    pay_with_bank: boolean;
    monday_from: string | null;
    monday_to: string | null;
    tuesday_from: string | null;
    tuesday_to: string | null;
    wednesday_from: string | null;
    wednesday_to: string | null;
    thursday_from: string | null;
    thursday_to: string | null;
    friday_from: string | null;
    friday_to: string | null;
    saturday_from: string | null;
    saturday_to: string | null;
    sunday_from: string | null;
    sunday_to: string | null;
    image: string | null;
    fixed_rate: string | null;
    shareable: boolean;
  };
export type PatchV1OrganizationSettingApiArg = {
  body: {
    pick_in_store?: boolean;
    delivery?: boolean;
    pay_with_cash?: boolean;
    pay_with_card?: boolean;
    pay_with_bank?: boolean;
    monday_from?: string;
    monday_to?: string;
    tuesday_from?: string;
    tuesday_to?: string;
    wednesday_from?: string;
    wednesday_to?: string;
    thursday_from?: string;
    thursday_to?: string;
    friday_from?: string;
    friday_to?: string;
    saturday_from?: string;
    saturday_to?: string;
    sunday_from?: string;
    sunday_to?: string;
    description: string;
    facebook?: string;
    twitter?: string;
    instagram?: string;
    tik_tok?: string;
    fixed_rate: number;
    image?: Blob | null;
  };
};
export type PatchV1OrganizationsApiResponse =
  /** status 200 organization#update */ {
    name: string;
    slug: string;
    money_symbol: 'dot' | 'comma';
    industry?:
      | 'Agricultura'
      | 'Alimentos y bebidas'
      | 'Alojamiento/Hoteler\u00EDa'
      | 'Autom\u00F3viles, aviones y embarcaciones'
      | 'Arte'
      | 'Bienes ra\u00EDces'
      | 'Belleza, cosm\u00E9tica y cuidado personal'
      | 'Comercial e Industrial'
      | 'Ciencia, tecnolog\u00EDa e ingenier\u00EDa'
      | 'Deportes y recreaci\u00F3n'
      | 'Educaci\u00F3n'
      | 'Farmacia'
      | 'Ferreter\u00EDa'
      | 'Servicios financieros'
      | 'Servicios legales'
      | 'Medio de comunicaci\u00F3n/noticias'
      | 'Medicina y salud'
      | 'Organizaci\u00F3n sin fines de lucro'
      | 'Otros servicios'
      | 'Publicidad /Marketing'
      | 'Ropa y accesorios'
      | 'Restaurante'
      | 'Servicio P\u00FAblico y Gubernamental'
      | 'Tienda de conveniencia/supermercado'
      | 'Viajes y transporte'
      | 'Venta por cat\u00E1logo';
    business_type?: 'person' | 'business';
    avatar: string | null;
    country: {
      id: number;
      name: string;
      currency_symbol: string;
      currency_name: string;
      phone_area_number: string;
    } | null;
  };
export type PatchV1OrganizationsApiArg = {
  body: {
    organization: {
      industry:
        | 'Agricultura'
        | 'Alimentos y bebidas'
        | 'Alojamiento/Hoteler\u00EDa'
        | 'Autom\u00F3viles, aviones y embarcaciones'
        | 'Arte'
        | 'Bienes ra\u00EDces'
        | 'Belleza, cosm\u00E9tica y cuidado personal'
        | 'Comercial e Industrial'
        | 'Ciencia, tecnolog\u00EDa e ingenier\u00EDa'
        | 'Deportes y recreaci\u00F3n'
        | 'Educaci\u00F3n'
        | 'Farmacia'
        | 'Ferreter\u00EDa'
        | 'Servicios financieros'
        | 'Servicios legales'
        | 'Medio de comunicaci\u00F3n/noticias'
        | 'Medicina y salud'
        | 'Organizaci\u00F3n sin fines de lucro'
        | 'Otros servicios'
        | 'Publicidad /Marketing'
        | 'Ropa y accesorios'
        | 'Restaurante'
        | 'Servicio P\u00FAblico y Gubernamental'
        | 'Tienda de conveniencia/supermercado'
        | 'Viajes y transporte'
        | 'Venta por cat\u00E1logo';
    };
  };
};
export type GetV1ProductCategoriesApiResponse =
  /** status 200 product_categories#index */ {
    product_categories: {
      id: number;
      name: string;
      description: string | null;
      products_count?: number;
      created_at: string;
      updated_at: string;
    }[];
    pagination: {
      page: number;
      prev: number | null;
      next: number | null;
      last: number;
      count: number;
    };
  };
export type GetV1ProductCategoriesApiArg = {
  page?: number;
  content?: string;
};
export type PostV1ProductCategoriesApiResponse =
  /** status 201 product_categories#create */ {
    id: number;
    name: string;
    description: string | null;
    created_at: string;
    updated_at: string;
  };
export type PostV1ProductCategoriesApiArg = {
  body: {
    product_category: {
      name: string;
      description: string;
    };
  };
};
export type GetV1ProductCategoriesByIdApiResponse =
  /** status 200 product_categories#show */ {
    id: number;
    name: string;
    description: string | null;
    created_at: string;
    updated_at: string;
  };
export type GetV1ProductCategoriesByIdApiArg = {
  id: string;
};
export type PatchV1ProductCategoriesByIdApiResponse =
  /** status 200 product_categories#update */ {
    id: number;
    name: string;
    description: string | null;
    created_at: string;
    updated_at: string;
  };
export type PatchV1ProductCategoriesByIdApiArg = {
  id: string;
  body: {
    product_category: {
      name: string;
      description: string;
    };
  };
};
export type DeleteV1ProductCategoriesByIdApiResponse = unknown;
export type DeleteV1ProductCategoriesByIdApiArg = {
  id: string;
};
export type PatchV1ProductCategoriesByIdBatchUpdateApiResponse = unknown;
export type PatchV1ProductCategoriesByIdBatchUpdateApiArg = {
  id: string;
  body: {
    product_ids: string[];
  };
};
export type GetV1ProductCategoriesByIdProductsApiResponse =
  /** status 200 product_categories#products */ {
    product_category: {
      id: number;
      name: string;
      description: string | null;
      products_count: number;
      products: {
        id: number;
        name: string;
        description: string | null;
        cost: string;
        price: string;
        stock: number;
        barcode: string | null;
        show_in_store: boolean;
        images: {
          id: number;
          image_url: string;
        }[];
        product_category?: {
          id: number;
          name: string;
        } | null;
        created_at: string;
        updated_at: string;
      }[];
    };
    pagination: {
      page: number;
      prev: number | null;
      next: number | null;
      last: number;
      count: number;
    };
  };
export type GetV1ProductCategoriesByIdProductsApiArg = {
  id: string;
};
export type DeleteV1ProductsByProductIdImagesAndIdApiResponse = unknown;
export type DeleteV1ProductsByProductIdImagesAndIdApiArg = {
  productId: string;
  id: string;
};
export type GetV1ProductsApiResponse = /** status 200 products#index */ {
  inventory_total?: string;
  products: {
    id: number;
    name: string;
    description: string | null;
    cost: string;
    price: string;
    stock: number;
    barcode: string | null;
    show_in_store: boolean;
    images: {
      id: number;
      image_url: string;
    }[];
    product_category?: {
      id: number;
      name: string;
    } | null;
    created_at: string;
    updated_at: string;
  }[];
  pagination: {
    page: number;
    prev: number | null;
    next: number | null;
    last: number;
    count: number;
  };
};
export type GetV1ProductsApiArg = {
  page?: number;
  /** The field you want to search. ie: name barcode */
  by?: string;
  /** The content you want to search. ie: query prop1,prop2,prop3 */
  content?: string;
  /** Search by product_category_ids, comma separated. ie: 1,2,3 */
  productCategoryIds?: string;
  /** Search by product ids, comma separated. ie: 1,2,3 */
  ids?: string;
  /** Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,- */
  order?: string;
};
export type PostV1ProductsApiResponse = unknown;
export type PostV1ProductsApiArg = {
  body: {
    product: {
      name: string;
      description: string | null;
      cost: string;
      price: string;
      stock: number;
      barcode: string | null;
      show_in_store: boolean;
      images: string[] | null;
      product_category_id: number | null;
    };
  };
};
export type GetV1ProductsByIdApiResponse = /** status 200 products#show */ {
  id: number;
  name: string;
  description: string | null;
  cost: string;
  price: string;
  stock: number;
  barcode: string | null;
  show_in_store: boolean;
  style: V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor | null;
  color: V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor | null;
  size: V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor | null;
  images: {
    id: number;
    image_url: string;
  }[];
  product_category?: {
    id: number;
    name: string;
  } | null;
  created_at: string;
  updated_at: string;
};
export type GetV1ProductsByIdApiArg = {
  id: string;
};
export type PatchV1ProductsByIdApiResponse = /** status 200 products#update */ {
  id: number;
  name: string;
  description: string | null;
  cost: string;
  price: string;
  stock: number;
  barcode: string | null;
  show_in_store: boolean;
  images: {
    id: number;
    image_url: string;
  }[];
  product_category?: {
    id: number;
    name: string;
  } | null;
  created_at: string;
  updated_at: string;
};
export type PatchV1ProductsByIdApiArg = {
  id: string;
  body: {
    product: {
      name: string;
      description: string | null;
      cost: string;
      price: string;
      stock: number;
      barcode: string | null;
      show_in_store: boolean;
      images: string[] | null;
      product_category_id: number | null;
    };
  };
};
export type DeleteV1ProductsByIdApiResponse = unknown;
export type DeleteV1ProductsByIdApiArg = {
  id: string;
};
export type PostV1ProductsByIdImagesOrderApiResponse =
  /** status 200 products#images_order */ {
    id: number;
    name: string;
    description: string | null;
    cost: string;
    price: string;
    stock: number;
    barcode: string | null;
    show_in_store: boolean;
    images: {
      id: number;
      image_url: string;
    }[];
    product_category?: {
      id: number;
      name: string;
    } | null;
    created_at: string;
    updated_at: string;
  };
export type PostV1ProductsByIdImagesOrderApiArg = {
  id: string;
  body: {
    product: {
      ordered_images_ids: number[];
    };
  };
};
export type GetV1ProductsUncategorizedApiResponse =
  /** status 200 products#uncategorized */ {
    products: {
      id: number;
      name: string;
      description: string | null;
      cost: string;
      price: string;
      stock: number;
      barcode: string | null;
      show_in_store: boolean;
      images: {
        id: number;
        image_url: string;
      }[];
      product_category?: {
        id: number;
        name: string;
      } | null;
      created_at: string;
      updated_at: string;
    }[];
    pagination: {
      page: number;
      prev: number | null;
      next: number | null;
      last: number;
      count: number;
    };
  };
export type GetV1ProductsUncategorizedApiArg = {
  page?: number;
  /** Send the string you want to search in a products name */
  content?: string;
};
export type GetV1ReceiptsApiResponse = unknown;
export type GetV1ReceiptsApiArg = {
  from?: string;
  until?: string;
};
export type GetV1ReceiptsByIdPdfApiResponse = unknown;
export type GetV1ReceiptsByIdPdfApiArg = {
  id: string;
};
export type GetV1RolesApiResponse = /** status 200 roles#index */ {
  roles: {
    name: string;
    inventory_see_catalog: boolean;
    inverstory_costs: boolean;
    inventory_create_products: boolean;
    inventory_delete_products: boolean;
    inventory_edit_products: boolean;
    inventory_see_product_categories: boolean;
    inventory_delete_product_categories: boolean;
    inventory_edit_categories: boolean;
    sales_select_products: boolean;
    sales_create_manual_sale: boolean;
    sales_discounts: boolean;
    expenses_skip_costs: boolean;
    debts_incoming: boolean;
    debts_outgoing: boolean;
    debts_edit: boolean;
    debts_deposits: boolean;
    debts_delete: boolean;
    details_history: boolean;
    details_Edit?: boolean;
    details_filter: boolean;
    details_download: boolean;
    details_delete: boolean;
    details_utilities: boolean;
    store_see: boolean;
    store_accept_in_approval: boolean;
    store_accept_in_progress: boolean;
    store_reject: boolean;
  }[];
};
export type GetV1RolesApiArg = void;
export type PostV1RolesApiResponse = unknown;
export type PostV1RolesApiArg = {
  body: {
    role: {
      name: string;
      inventory_see_catalog: boolean;
      inverstory_costs: boolean;
      inventory_create_products: boolean;
      inventory_delete_products: boolean;
      inventory_edit_products: boolean;
      inventory_see_product_categories: boolean;
      inventory_delete_product_categories: boolean;
      inventory_edit_categories: boolean;
      sales_select_products: boolean;
      sales_create_manual_sale: boolean;
      sales_discounts: boolean;
      expenses_skip_costs: boolean;
      debts_incoming: boolean;
      debts_outgoing: boolean;
      debts_edit: boolean;
      debts_deposits: boolean;
      debts_delete: boolean;
      details_history: boolean;
      details_Edit?: boolean;
      details_filter: boolean;
      details_download: boolean;
      details_delete: boolean;
      details_utilities: boolean;
      store_see: boolean;
      store_accept_in_approval: boolean;
      store_accept_in_progress: boolean;
      store_reject: boolean;
    };
  };
};
export type GetV1RolesByIdApiResponse = /** status 200 roles#show */ {
  role: {
    name: string;
    inventory_see_catalog: boolean;
    inverstory_costs: boolean;
    inventory_create_products: boolean;
    inventory_delete_products: boolean;
    inventory_edit_products: boolean;
    inventory_see_product_categories: boolean;
    inventory_delete_product_categories: boolean;
    inventory_edit_categories: boolean;
    sales_select_products: boolean;
    sales_create_manual_sale: boolean;
    sales_discounts: boolean;
    expenses_skip_costs: boolean;
    debts_incoming: boolean;
    debts_outgoing: boolean;
    debts_edit: boolean;
    debts_deposits: boolean;
    debts_delete: boolean;
    details_history: boolean;
    details_Edit?: boolean;
    details_filter: boolean;
    details_download: boolean;
    details_delete: boolean;
    details_utilities: boolean;
    store_see: boolean;
    store_accept_in_approval: boolean;
    store_accept_in_progress: boolean;
    store_reject: boolean;
  };
};
export type GetV1RolesByIdApiArg = {
  id: string;
};
export type PatchV1RolesByIdApiResponse = /** status 200 roles#update */ {
  role: {
    name: string;
    inventory_see_catalog: boolean;
    inverstory_costs: boolean;
    inventory_create_products: boolean;
    inventory_delete_products: boolean;
    inventory_edit_products: boolean;
    inventory_see_product_categories: boolean;
    inventory_delete_product_categories: boolean;
    inventory_edit_categories: boolean;
    sales_select_products: boolean;
    sales_create_manual_sale: boolean;
    sales_discounts: boolean;
    expenses_skip_costs: boolean;
    debts_incoming: boolean;
    debts_outgoing: boolean;
    debts_edit: boolean;
    debts_deposits: boolean;
    debts_delete: boolean;
    details_history: boolean;
    details_Edit?: boolean;
    details_filter: boolean;
    details_download: boolean;
    details_delete: boolean;
    details_utilities: boolean;
    store_see: boolean;
    store_accept_in_approval: boolean;
    store_accept_in_progress: boolean;
    store_reject: boolean;
  };
};
export type PatchV1RolesByIdApiArg = {
  id: string;
  body: {
    role: {
      name: string;
      inventory_see_catalog: boolean;
      inverstory_costs: boolean;
      inventory_create_products: boolean;
      inventory_delete_products: boolean;
      inventory_edit_products: boolean;
      inventory_see_product_categories: boolean;
      inventory_delete_product_categories: boolean;
      inventory_edit_categories: boolean;
      sales_select_products: boolean;
      sales_create_manual_sale: boolean;
      sales_discounts: boolean;
      expenses_skip_costs: boolean;
      debts_incoming: boolean;
      debts_outgoing: boolean;
      debts_edit: boolean;
      debts_deposits: boolean;
      debts_delete: boolean;
      details_history: boolean;
      details_Edit?: boolean;
      details_filter: boolean;
      details_download: boolean;
      details_delete: boolean;
      details_utilities: boolean;
      store_see: boolean;
      store_accept_in_approval: boolean;
      store_accept_in_progress: boolean;
      store_reject: boolean;
    };
  };
};
export type DeleteV1RolesByIdApiResponse = unknown;
export type DeleteV1RolesByIdApiArg = {
  id: string;
};
export type GetV1SalesTransactionsApiResponse =
  /** status 200 transactions#index */ {
    transactions: {
      id: number;
      origin: string;
      amount_due: string;
      amount_paid: string;
      amount_free: string;
      amount_discount: string;
      amount_products: string;
      amount_utility: string;
      amount_cost?: string;
      amount_total: string;
      amount_delivery: string;
      amount_total_with_delivery: string;
      code: string;
      contact: {
        id?: number;
        name?: string;
        phone?: string;
      } | null;
      transaction_category: {
        id?: number;
        name?: string;
        color?: string;
        icon?: string;
      } | null;
      concept: string;
      transaction_type: string;
      transaction_status: string;
      transaction_origin: string;
      payment_type: string;
      payment_method: string;
      products: {
        id: number;
        name: string;
        description: string | null;
        cost: string;
        price: string;
        stock: number;
        quantity: number;
        barcode: string | null;
        images: {
          id: number;
          image_url: string;
        }[];
        product_category?: {
          id: number;
          name: string;
        } | null;
        created_at: string;
        updated_at: string;
      }[];
      created_at: string;
      updated_at: string;
      pay_at: string | null;
    }[];
    pagination: {
      page: number;
      prev: number | null;
      next: number | null;
      last: number;
    };
  };
export type GetV1SalesTransactionsApiArg = {
  page?: number;
};
export type PostV1SalesTransactionsApiResponse =
  /** status 201 transactions#create */ {
    id: number;
    origin: string;
    amount_due: string;
    amount_paid: string;
    amount_free: string;
    amount_discount: string;
    amount_products: string;
    amount_utility: string;
    amount_cost: string;
    amount_total: string;
    amount_delivery?: string;
    amount_total_with_delivery: string;
    code: string;
    contact: {
      id?: number;
      name?: string;
      phone?: string;
    } | null;
    transaction_category: {
      id?: number;
      name?: string;
      color?: string;
      icon?: string;
    } | null;
    concept: string;
    transaction_origin: string;
    transaction_type: string;
    transaction_status: string;
    payment_type: string;
    payment_method: string;
    products: {
      id: number;
      name: string;
      description: string | null;
      cost: string;
      price: string;
      stock: number;
      quantity: number;
      barcode: string | null;
      images: {
        id: number;
        image_url: string;
      }[];
      product_category?: {
        id: number;
        name: string;
      } | null;
      created_at: string;
      updated_at: string;
    }[];
    created_at: string;
    updated_at: string;
    pay_at: string | null;
  };
export type PostV1SalesTransactionsApiArg = {
  body: {
    sale: {
      products: {
        id?: number;
        quantity?: number;
      }[];
      amount?: number;
      discount?: number;
      concept?: string;
      contact_id?: number | null;
      transaction_category_id?: number | null;
      payment_type: 'in-cash' | 'credit';
      payment_method: 'cash' | 'on-credit' | 'card' | 'other' | 'bank';
      created_at: string;
      pay_at: string | null;
      transaction_origin?: string;
    };
  };
};
export type GetV1SalesTransactionsByIdApiResponse =
  /** status 200 transactions#show */ {
    id: number;
    origin: string;
    amount_due: string;
    amount_paid: string;
    amount_free: string;
    amount_discount: string;
    amount_products: string;
    amount_utility: string;
    amount_cost: string;
    amount_total: string;
    amount_delivery: string;
    amount_total_with_delivery: string;
    code: string;
    contact: {
      id?: number;
      name?: string;
      phone?: string;
    } | null;
    transaction_category: {
      id?: number;
      name?: string;
      color?: string;
      icon?: string;
    } | null;
    concept: string;
    transaction_type: string;
    transaction_status: string;
    transaction_origin: string;
    payment_type: string;
    payment_method: string;
    deposits: {
      id?: number;
      concept?: string;
      amount?: string;
      payment_method?: string;
      created_at?: string;
    }[];
    products: {
      id: number;
      name: string;
      description: string | null;
      cost: string;
      price: string;
      stock: number;
      quantity: number;
      barcode: string | null;
      images: {
        id: number;
        image_url: string;
      }[];
      product_category?: {
        id: number;
        name: string;
      } | null;
      created_at: string;
      updated_at: string;
    }[];
    created_at: string;
    updated_at: string;
    pay_at: string | null;
  };
export type GetV1SalesTransactionsByIdApiArg = {
  id: string;
};
export type PatchV1SalesTransactionsByIdApiResponse =
  /** status 200 transactions#update */ {
    id: number;
    origin: string;
    amount_total: string;
    amount_due: string;
    amount_paid: string;
    amount_free: string;
    amount_discount: string;
    amount_products: string;
    amount_utility: string;
    amount_cost?: string;
    amount_delivery: string;
    amount_total_with_delivery: string;
    code: string;
    contact: {
      id?: number;
      name?: string;
      phone?: string;
    } | null;
    transaction_category: {
      id?: number;
      name?: string;
      color?: string;
      icon?: string;
    } | null;
    concept: string;
    transaction_type: string;
    transaction_status: string;
    transaction_origin: string;
    payment_type: string;
    payment_method: string;
    products: {
      id: number;
      name: string;
      description: string | null;
      cost: string;
      price: string;
      stock: number;
      quantity: number;
      barcode: string | null;
      images: {
        id: number;
        image_url: string;
      }[];
      product_category?: {
        id: number;
        name: string;
      } | null;
      created_at: string;
      updated_at: string;
    }[];
    created_at: string;
    updated_at: string;
    pay_at: string | null;
  };
export type PatchV1SalesTransactionsByIdApiArg = {
  id: string;
  body: {
    sale: {
      products: {
        id?: number;
        quantity?: number;
        cost?: string;
        price?: string;
      }[];
      amount?: number;
      discount?: number;
      concept?: string;
      contact_id?: number;
      transaction_category_id?: number | null;
      payment_type: 'in-cash' | 'credit';
      payment_method: 'cash' | 'on-credit' | 'card' | 'other' | 'bank';
      created_at: string;
      pay_at?: string | null;
    };
  };
};
export type DeleteV1SalesTransactionsByIdApiResponse = unknown;
export type DeleteV1SalesTransactionsByIdApiArg = {
  id: string;
};
export type GetV1StatsApiResponse = /** status 200 stats#index */ {
  sales_credit: string;
  sales_web: string;
  discounts: string;
  total_sales: string;
  sales_costs: string;
  sales_costs_cash: string;
  sales_costs_credit: string;
  sales_costs_web: string;
  expenses_in_cash: string;
  expenses_credit: string;
  expenses_skipped_costs: string;
  profits: string;
  available_cash: string;
  debts_to_charge_paid: string;
  debts_to_pay_paid: string;
  sales_in_cash_cash: number;
  sales_in_cash_card: number;
  sales_in_cash_bank: number;
  sales_in_cash_total: number;
  sales_credit_cash: string;
  sales_credit_card: string;
  sales_credit_bank: string;
  sales_credit_total: string;
  debts_to_charge_pending: string;
  debts_to_charge_total: string;
  debts_to_pay_pending: string;
  debts_to_pay_total: string;
  stock_detail_categories: number;
  stock_detail_products: number;
  stock_cost: string;
  stock_price: string;
  sales_in_cash: string;
  web_in_approval: number;
  web_in_progress: number;
  web_finished: number;
};
export type GetV1StatsApiArg = {
  from?: string;
  until?: string;
};
export type GetV1TransactionCategoriesApiResponse =
  /** status 200 transaction_categories#index */ {
    transaction_categories: {
      id: number;
      name: string;
      description: string | null;
      icon: string | null;
      color: string | null;
      transactions_count?: number;
      transaction_type: string;
      is_deleteable: boolean;
      created_at: string;
      updated_at: string;
    }[];
    pagination: {
      page: number;
      prev: number | null;
      next: number | null;
      last: number;
      count: number;
    };
  };
export type GetV1TransactionCategoriesApiArg = {
  page?: number;
  content?: string;
  transactionTypeId?: string;
};
export type PostV1TransactionCategoriesApiResponse =
  /** status 201 transaction_categories#create */ {
    id: number;
    name: string;
    description: string | null;
    icon: string | null;
    color: string | null;
    transaction_type: string;
    is_deleteable: boolean;
    created_at: string;
    updated_at: string;
  };
export type PostV1TransactionCategoriesApiArg = {
  body: {
    transaction_category: {
      name: string;
      description?: string;
      icon?: string | null;
      color?: string | null;
      transaction_type_id: string;
    };
  };
};
export type GetV1TransactionCategoriesByIdApiResponse =
  /** status 200 transaction_categories#show */ {
    id: number;
    name: string;
    description: string | null;
    icon?: string | null;
    color?: string | null;
    transaction_type: string;
    is_deleteable: boolean;
    created_at: string;
    updated_at: string;
  };
export type GetV1TransactionCategoriesByIdApiArg = {
  id: string;
};
export type PatchV1TransactionCategoriesByIdApiResponse =
  /** status 200 transaction_categories#update */ {
    id: number;
    name: string;
    description: string | null;
    icon: string | null;
    color: string | null;
    transaction_type: string;
    is_deleteable: boolean;
    created_at: string;
    updated_at: string;
  };
export type PatchV1TransactionCategoriesByIdApiArg = {
  id: string;
  body: {
    transaction_category: {
      name: string;
      description?: string;
      icon?: string | null;
      color?: string | null;
      transaction_type_id?: string;
    };
  };
};
export type DeleteV1TransactionCategoriesByIdApiResponse = unknown;
export type DeleteV1TransactionCategoriesByIdApiArg = {
  id: string;
};
export type PatchV1TransactionCategoriesByIdBatchUpdateApiResponse = unknown;
export type PatchV1TransactionCategoriesByIdBatchUpdateApiArg = {
  id: string;
  body: {
    transaction_ids: number[];
  };
};
export type GetV1TransactionCategoriesByIdTransactionsApiResponse =
  /** status 200 transaction_categories#products */ {
    transaction_category: {
      id: number;
      name: string;
      description: string | null;
      icon: string | null;
      color: string | null;
      transaction_type: string;
      is_deleteable: boolean;
      transactions_count: number;
      amount_total?: string;
      transactions: {
        id: number;
        amount_due: string;
        amount_paid: string;
        amount_free: string;
        amount_discount: string;
        amount_products: string;
        amount_utility: string;
        amount_cost?: string;
        amount_total: string;
        amount_delivery: string;
        amount_total_with_delivery: string;
        code: string;
        contact: {
          id?: number;
          name?: string;
          phone?: string;
        } | null;
        transaction_category: {
          id?: number;
          name?: string;
          color?: string;
          icon?: string;
        } | null;
        concept: string;
        transaction_transaction_type?: string;
        transaction_status: string;
        transaction_origin: string;
        payment_transaction_type?: string;
        payment_method: string;
        products: {
          id: number;
          name: string;
          description: string | null;
          cost: string;
          price: string;
          stock: number;
          quantity: number;
          barcode: string | null;
          images: {
            id: number;
            image_url: string;
          }[];
          product_category?: {
            id: number;
            name: string;
          } | null;
          created_at: string;
          updated_at: string;
        }[];
        skip_cost?: boolean | null;
        created_at: string;
        updated_at: string;
        pay_at: string | null;
      }[];
    };
    pagination: {
      page: number;
      prev: number | null;
      next: number | null;
      last: number;
      count: number;
    };
  };
export type GetV1TransactionCategoriesByIdTransactionsApiArg = {
  id: string;
};
export type GetV1TransactionsApiResponse =
  /** status 200 transactions#index */ {
    transactions: {
      id: number;
      code: string;
      amount_total: string;
      concept: string;
      transaction_type: string;
      payment_method?: string;
      created_at: string;
      updated_at: string;
      pay_at: string | null;
    }[];
    total_sales?: string;
    total_expenses?: string;
    total_earnings?: string;
    pagination: {
      page: number;
      prev: number | null;
      next: number | null;
      last: number;
    };
  };
export type GetV1TransactionsApiArg = {
  from?: string;
  until?: string;
  page?: number;
};
export type GetV1TransactionsDetailsApiResponse =
  /** status 200 transactions#details */ {
    available_cash: string;
    profits?: string;
    sales_in_cash: string;
    sales_credit: string;
    deposits: string;
    sales_online: string;
    discounts: string;
    costs: string;
    expenses_in_cash: string;
    expenses_credit: string;
    skipped_costs: string;
  };
export type GetV1TransactionsDetailsApiArg = {
  from?: string;
  until?: string;
};
// export type GetV1UserMeApiResponse = /** status 200 users#show */ {
//   user: {
//     first_name: string | null;
//     last_name: string | null;
//     email: string;
//     phone: string | null;
//     time_zone: string;
//     language: string;
//     destroy_at: string | null;
//     is_guest?: boolean;
//     country: {
//       id: number;
//       name: string;
//       currency_symbol: string;
//       currency_name: string;
//       phone_area_number: string;
//     } | null;
//   };
//   organization: {
//     name: string;
//     slug: string;
//     money_symbol: 'dot' | 'comma';
//     business_type?: 'person' | 'business';
//     industry?: string | null;
//     avatar: string | null;
//     country: {
//       id: number;
//       name: string;
//       currency_symbol: string;
//       currency_name: string;
//       phone_area_number: string;
//     } | null;
//   };
// };
export type GetV1UserMeApiArg = void;
export type PatchV1UserApiResponse = /** status 200 users#update */ {
  user: {
    first_name: string | null;
    last_name: string | null;
    email: string;
    phone: string | null;
    time_zone: string;
    language: string;
    destroy_at: string | null;
    is_guest?: boolean;
    country: {
      id: number;
      name: string;
      currency_symbol: string;
      currency_name: string;
      phone_area_number: string;
    } | null;
  };
  organization: {
    name: string;
    slug: string;
    money_symbol: 'dot' | 'comma';
    business_type?: 'person' | 'business';
    industry?: string | null;
    avatar: string | null;
    country: {
      id: number;
      name: string;
      currency_symbol: string;
      currency_name: string;
      phone_area_number: string;
    } | null;
  };
};
export type PatchV1UserApiArg = {
  body: {
    user_first_name?: string;
    user_last_name?: string;
    user_phone: string;
    user_country_id: number;
    user_time_zone?: string;
    user_language: 'es' | 'en';
    organization_name: string;
    organization_money_symbol?: 'comma' | 'dot';
    organization_avatar?: Blob | null;
    organization_country_id: number;
    organization_business_type: 'person' | 'business';
    organization_industry?:
      | (
          | 'Agricultura'
          | 'Alimentos y bebidas'
          | 'Alojamiento/Hoteler\u00EDa'
          | 'Autom\u00F3viles, aviones y embarcaciones'
          | 'Arte'
          | 'Bienes ra\u00EDces'
          | 'Belleza, cosm\u00E9tica y cuidado personal'
          | 'Comercial e Industrial'
          | 'Ciencia, tecnolog\u00EDa e ingenier\u00EDa'
          | 'Deportes y recreaci\u00F3n'
          | 'Educaci\u00F3n'
          | 'Farmacia'
          | 'Ferreter\u00EDa'
          | 'Servicios financieros'
          | 'Servicios legales'
          | 'Medio de comunicaci\u00F3n/noticias'
          | 'Medicina y salud'
          | 'Organizaci\u00F3n sin fines de lucro'
          | 'Otros servicios'
          | 'Publicidad /Marketing'
          | 'Ropa y accesorios'
          | 'Restaurante'
          | 'Servicio P\u00FAblico y Gubernamental'
          | 'Tienda de conveniencia/supermercado'
          | 'Viajes y transporte'
          | 'Venta por cat\u00E1logo'
        )
      | null;
  };
};
export type PatchV1UserProgramDestroyApiResponse = unknown;
export type PatchV1UserProgramDestroyApiArg = void;
export type GetV1VersionApiResponse = /** status 200 charts#index */ {
  version: string;
};
export type GetV1VersionApiArg = void;
export type GetV1WebOrganizationsBySlugApiResponse =
  /** status 200 organizations#show */ {
    name: string;
    slug: string;
    avg_rating: number;
    country: {
      id: number;
      name: string;
      currency_symbol: string;
      currency_name: string;
      phone_area_number: string;
    };
    payment_methods:
      | {
          name: string;
          slug: string;
        }[]
      | null;
    delivery_methods:
      | {
          name: string;
          slug: string;
        }[]
      | null;
    organization_settings: {
      pick_in_store: boolean;
      delivery: boolean;
      pay_with_cash: boolean;
      pay_with_card: boolean;
      pay_with_bank: boolean;
      fixed_rate: string | null;
      monday_from: string | null;
      monday_to: string | null;
      tuesday_from: string | null;
      tuesday_to: string | null;
      wednesday_from: string | null;
      wednesday_to: string | null;
      thursday_from: string | null;
      thursday_to: string | null;
      friday_from: string | null;
      friday_to: string | null;
      saturday_from: string | null;
      saturday_to: string | null;
      sunday_from: string | null;
      sunday_to: string | null;
      image: string | null;
      description: string | null;
      facebook: string | null;
      instagram: string | null;
      twitter: string | null;
      tik_tok: string | null;
    };
  };
export type GetV1WebOrganizationsBySlugApiArg = {
  slug: string;
};
export type GetV1WebOrganizationsBySlugProductCategoriesApiResponse =
  /** status 200 product_categories#index */ {
    product_categories: {
      id: number;
      name: string;
      description: string | null;
      products_count?: number;
      created_at: string;
      updated_at: string;
    }[];
  };
export type GetV1WebOrganizationsBySlugProductCategoriesApiArg = {
  slug: string;
  page?: number;
  content?: string;
};
export type GetV1WebOrganizationsBySlugProductsApiResponse =
  /** status 200 products#index */ {
    products: {
      id: number;
      name: string;
      description: string | null;
      cost: string;
      price: string;
      stock: number;
      barcode: string | null;
      main_image: string | null;
      images: {
        id: number;
        image_url: string;
      }[];
      product_category: {
        id: number;
        name: string;
      } | null;
      created_at: string;
      updated_at: string;
    }[];
    pagination: {
      page: number;
      prev: number | null;
      next: number | null;
      last: number;
      count: number;
    };
  };
export type GetV1WebOrganizationsBySlugProductsApiArg = {
  slug: string;
  page?: number;
  /** The field you want to search. ie: name barcode */
  by?: string;
  /** The content you want to search. ie: query prop1,prop2,prop3 */
  content?: string;
  /** Search by category_ids, comma separated. ie: 1,2,3 */
  categoryIds?: string;
  /** Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,- */
  order?: string;
};
export type PostV1WebSalesTransactionsByCodeRatingsApiResponse = unknown;
export type PostV1WebSalesTransactionsByCodeRatingsApiArg = {
  code: string;
  body: {
    rating: {
      points: number;
      message: string | null;
    };
  };
};
export type PostV1WebOrganizationsBySlugSalesTransactionsApiResponse =
  /** status 201 sales_transactions#create */ {
    id: number;
    origin: string;
    amount_total: string;
    code: string;
    contact?: {
      id?: number;
      name: string;
      phone: string;
      email: string | null;
    };
    delivery_method: string;
    transaction_type: string;
    transaction_status: string;
    transaction_origin: string;
    process_status: string;
    payment_type: string;
    payment_method: string;
    concept: string;
    products: {
      id: number;
      name: string;
      description: string | null;
      price: string;
      images: {
        id: number;
        image_url: string;
      }[];
      product_category?: {
        id: number;
        name: string;
      } | null;
    }[];
    created_at: string;
  };
export type PostV1WebOrganizationsBySlugSalesTransactionsApiArg = {
  slug: string;
  body: {
    sale: {
      products: {
        id?: number;
        quantity?: number;
      }[];
      contact: {
        name: string;
        phone: string;
        email?: string | null;
      };
      payment_type: 'in-cash' | 'credit';
      payment_method: 'cash' | 'on-credit' | 'card' | 'other' | 'bank';
      delivery_method: 'store-pickup' | 'delivery';
    };
  };
};
export type GetV1WebTransactionsApiResponse =
  /** status 200 transactions#index */ {
    transactions: {
      id: number;
      amount_free: string;
      amount_discount: string;
      amount_products: string;
      amount_total: string;
      amount_total_with_delivery: string;
      amount_utility: string;
      amount_cost: string;
      amount_delivery: string;
      code: string;
      contact?: {
        id?: number;
        name?: string;
        phone?: string;
      } | null;
      concept: string;
      transaction_type: string;
      transaction_status: string;
      process_status: string;
      payment_type: string;
      payment_method: string;
      delivery_method: string | null;
      products: {
        id: number;
        name: string;
        description: string | null;
        cost: string;
        price: string;
        stock: number;
        quantity: number;
        barcode: string | null;
        images: {
          id: number;
          image_url: string;
        }[];
        product_category?: {
          id: number;
          name: string;
        } | null;
        created_at: string;
        updated_at: string;
      }[];
      created_at: string;
      updated_at: string;
      pay_at: string | null;
    }[];
    pagination: {
      page: number;
      prev: number | null;
      next: number | null;
      last: number;
    };
  };
export type GetV1WebTransactionsApiArg = {
  processStatus: 'in-approval' | 'in-progress' | 'finished' | 'cancelled';
  page?: number;
};
export type GetV1WebTransactionsByIdApiResponse =
  /** status 200 transactions#show */ {
    id: number;
    amount_free: string;
    amount_discount: string;
    amount_products: string;
    amount_total: string;
    amount_delivery: string;
    amount_total_with_delivery: string;
    delivery_method: string | null;
    code: string;
    contact?: {
      id?: number;
      name?: string;
      phone?: string;
    } | null;
    concept: string;
    transaction_type: string;
    transaction_status: string;
    process_status: string;
    payment_type: string;
    payment_method: string;
    products: {
      id: number;
      name: string;
      description: string | null;
      cost: string;
      price: string;
      stock: number;
      quantity: number;
      barcode: string | null;
      images: {
        id: number;
        image_url: string;
      }[];
      product_category?: {
        id: number;
        name: string;
      } | null;
      created_at: string;
      updated_at: string;
    }[];
    created_at: string;
    updated_at: string;
    pay_at: string | null;
  };
export type GetV1WebTransactionsByIdApiArg = {
  id: string;
};
export type PatchV1WebTransactionsByIdApproveApiResponse = unknown;
export type PatchV1WebTransactionsByIdApproveApiArg = {
  id: string;
};
export type PatchV1WebTransactionsByIdRejectApiResponse = unknown;
export type PatchV1WebTransactionsByIdRejectApiArg = {
  id: string;
};
export const {
  usePostUsersAndroidMutation,
  usePostUsersIosMutation,
  usePostUsersGuestMutation,
  usePostUsersGuestAndroidMutation,
  usePostUsersGuestIosMutation,
  useDeleteUsersLogoutMutation,
  useGetV1CashFlowQuery,
  useGetV1ChartsQuery,
  useGetV1ContactsDebtsTransactionsQuery,
  useGetV1ContactsDebtsTransactionsByIdQuery,
  useGetV1ContactsQuery,
  usePostV1ContactsMutation,
  useGetV1ContactsByIdQuery,
  usePatchV1ContactsByIdMutation,
  useDeleteV1ContactsByIdMutation,
  useGetV1CountriesQuery,
  useGetV1DebtsTransactionsQuery,
  useGetV1DebtsTransactionsByIdQuery,
  useGetV1DeliveryAreasQuery,
  usePostV1DeliveryAreasMutation,
  useGetV1DeliveryAreasByIdQuery,
  usePatchV1DeliveryAreasByIdMutation,
  useDeleteV1DeliveryAreasByIdMutation,
  usePostV1DebtsTransactionsByDebtsTransactionIdDepositsMutation,
  useDeleteV1DebtsTransactionsByDebtsTransactionIdDepositsAndIdMutation,
  usePostV1DeviceInfosMutation,
  usePostV1DirectUploadMutation,
  useGetV1ExpensesTransactionsQuery,
  usePostV1ExpensesTransactionsMutation,
  useGetV1ExpensesTransactionsByIdQuery,
  usePatchV1ExpensesTransactionsByIdMutation,
  useDeleteV1ExpensesTransactionsByIdMutation,
  useGetV1FcmQuery,
  useGetV1GamificationBasicRoadmapQuery,
  useGetV1IndustriesQuery,
  useGetV1NotificationsOrganizationQuery,
  useGetV1NotificationsPlatformQuery,
  usePostV1NotificationsByIdSeenMutation,
  useGetV1OrganizationSettingQuery,
  usePatchV1OrganizationSettingMutation,
  usePatchV1OrganizationsMutation,
  useGetV1ProductCategoriesQuery,
  usePostV1ProductCategoriesMutation,
  useGetV1ProductCategoriesByIdQuery,
  usePatchV1ProductCategoriesByIdMutation,
  useDeleteV1ProductCategoriesByIdMutation,
  usePatchV1ProductCategoriesByIdBatchUpdateMutation,
  useGetV1ProductCategoriesByIdProductsQuery,
  useDeleteV1ProductsByProductIdImagesAndIdMutation,
  useGetV1ProductsQuery,
  usePostV1ProductsMutation,
  useGetV1ProductsByIdQuery,
  usePatchV1ProductsByIdMutation,
  useDeleteV1ProductsByIdMutation,
  usePostV1ProductsByIdImagesOrderMutation,
  useGetV1ProductsUncategorizedQuery,
  useGetV1ReceiptsQuery,
  useGetV1ReceiptsByIdPdfQuery,
  useGetV1RolesQuery,
  usePostV1RolesMutation,
  useGetV1RolesByIdQuery,
  usePatchV1RolesByIdMutation,
  useDeleteV1RolesByIdMutation,
  useGetV1SalesTransactionsQuery,
  usePostV1SalesTransactionsMutation,
  useGetV1SalesTransactionsByIdQuery,
  usePatchV1SalesTransactionsByIdMutation,
  useDeleteV1SalesTransactionsByIdMutation,
  useGetV1StatsQuery,
  useGetV1TransactionCategoriesQuery,
  usePostV1TransactionCategoriesMutation,
  useGetV1TransactionCategoriesByIdQuery,
  usePatchV1TransactionCategoriesByIdMutation,
  useDeleteV1TransactionCategoriesByIdMutation,
  usePatchV1TransactionCategoriesByIdBatchUpdateMutation,
  useGetV1TransactionCategoriesByIdTransactionsQuery,
  useGetV1TransactionsQuery,
  useGetV1TransactionsDetailsQuery,
  useGetV1UserMeQuery,
  usePatchV1UserMutation,
  usePatchV1UserProgramDestroyMutation,
  useGetV1VersionQuery,
  useGetV1WebOrganizationsBySlugQuery,
  useGetV1WebOrganizationsBySlugProductCategoriesQuery,
  useGetV1WebOrganizationsBySlugProductsQuery,
  usePostV1WebSalesTransactionsByCodeRatingsMutation,
  usePostV1WebOrganizationsBySlugSalesTransactionsMutation,
  useGetV1WebTransactionsQuery,
  useGetV1WebTransactionsByIdQuery,
  usePatchV1WebTransactionsByIdApproveMutation,
  usePatchV1WebTransactionsByIdRejectMutation,
} = injectedRtkApi;
