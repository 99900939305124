import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { UserApi } from 'src/api-client';
import useAuth from 'src/hooks/useAuth';
import { useOnError } from 'src/hooks/useOnError';
import { usePermissions } from 'src/hooks/usePermissions';

export const PaidAccountSwitcher = () => {
  const { isAccountType, is } = usePermissions();
  const { user, updateUser: refreshUser } = useAuth();
  const [isPaid, setIsPaid] = useState(
    user?.current_organization?.has_paid_access ?? false
  );
  const { onError } = useOnError();

  useEffect(() => {
    if (user && !is('guestWithoutOrganization')) {
      refreshUser({
        ...user,
        current_organization: {
          ...user.current_organization,
          has_paid_access: isPaid,
        },
      });
    }
  }, [isPaid]);

  const { mutateAsync: toggleSubscription } = useMutation(
    async () => {
      await UserApi.v1UserSubscriptionPatch();
    },
    {
      onError,
    }
  );

  return process.env.REACT_APP_SENTRY_ENV &&
    process.env.REACT_APP_SENTRY_ENV !== 'production' &&
    isAccountType('business') &&
    is('owner') ? (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            checked={isPaid}
            onChange={(_, checked) => {
              setIsPaid(checked);
              toggleSubscription();
            }}
          />
        }
        label={`Cabal +`}
        sx={{
          '& .MuiFormControlLabel-label': {
            color: '#000',
          },
        }}
      />
    </FormGroup>
  ) : (
    <div />
  );
};
