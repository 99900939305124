import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { User } from 'src/@types/user';

import type { RootState } from '../store';

const initialState: User = {
  user: {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    time_zone: '',
    country: null,
    destroy_at: '',
    language: '',
    paid_customer: false,
  },
  current_organization: {
    id: 0,
    name: '',
    main_role: '',
    slug: '',
    money_symbol: 'dot',
    avatar: null,
    country: null,
    business_type: 'business',
    industry: null,
    primary_color: '',
    secondary_color: '',
    custom_image: '',
    has_paid_access: false,
    thank_you_message: '',
    unified_transactions: false,
    low_stock: 5,
    is_dollar: true,
    receipt_line_1: '',
    receipt_line_2: '',
    receipt_line_3: '',
    receipt_line_4: '',
    ticket_hide_logo: false,
  },
  organizations: [],
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload: data }: PayloadAction<User>) => {
      state.user = data.user;
      state.current_organization = data.current_organization;
    },
  },
});

export const { setUser } = slice.actions;

export default slice.reducer;

export const getUser = (state: RootState) => state.user;
export const getCurrency = (state: RootState) =>
  state.user.current_organization.money_symbol;
