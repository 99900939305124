import { m } from 'framer-motion';
import { Container, Typography } from '@mui/material';
import { MotionContainer, varBounce } from '../components/animate';
import { ForbiddenIllustration } from '../assets';
import { usePermissions } from 'src/hooks/usePermissions';
import { useLocation } from 'react-router';

type BusinessAccountGuardProps = {
  hasContent?: boolean;
  children: React.ReactNode;
};

export const BusinessAccountGuard = ({
  hasContent,
  children,
}: BusinessAccountGuardProps) => {
  const { has, isAccountType } = usePermissions();
  const { pathname } = useLocation();

  if (
    isAccountType('business') ||
    (isAccountType('person') &&
      has('multiple_organizations') &&
      pathname.includes('organizaciones/todas'))
  ) {
    return <>{children}</>;
  }

  return hasContent ? (
    <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
      <m.div variants={varBounce().in}>
        <Typography variant="h3" paragraph>
          Acceso denegado
        </Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <Typography sx={{ color: 'text.secondary' }}>
          No tienes permisos para ver esta página
        </Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
      </m.div>
    </Container>
  ) : null;
};
