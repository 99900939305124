export enum QueryTypes {
  ApiKeys = 'API_KEYS',
  AccountVerify = 'ACCOUNT_VERIFY',
  CabalCard = 'CABAL_CARD',
  CashFlow = 'CASH_FLOW',
  CashReconciliation = 'CASH_RECONCILIATION',
  Charts = 'CHARTS',
  Colors = 'DimensionColors',
  Contacts = 'CONTACTS',
  ContactsDebtsTransactions = 'CONTACTS_DEBTS_TRANSACTIONS',
  Countries = 'COUNTRIES',
  Coupons = 'COUPONS',
  DataImports = 'DataImports',
  DebtTransactions = 'DEBT_TRANSACTIONS',
  DeviceInfos = 'DEVICE_INFOS',
  Discounts = 'DISCOUNTS',
  DirectUpload = 'DIRECT_UPLOAD',
  Industries = 'INDUSTRIES',
  ExpensePersonalTransactions = 'EXPENSE_PERSONAL_TRANSACTIONS',
  ExpenseTransactions = 'EXPENSE_TRANSACTIONS',
  FinancialData = 'FINANCIAL_DATA',
  IncomeTransactions = 'INCOME_TRANSACTIONS',
  InitialBalanceAmounts = 'InitialBalanceAmounts',
  Organization = 'ORGANIZATION',
  OrganizationPaymentSetting = 'ORGANIZATION_PAYMENT_SETTING',
  OrganizationSetting = 'ORGANIZATION_SETTING',
  OrganizationUsers = 'ORGANIZATION_USERS',
  Products = 'PRODUCTS',
  ProductsV2 = 'PRODUCTS_V2',
  ProductCategories = 'PRODUCT_CATEGORIES',
  Modifiers = 'MODIFIERS',
  POSTransactions = 'POS_TRANSACTIONS',
  Quotes = 'QUOTES',
  Receipts = 'RECEIPTS',
  Reports = 'Reports',
  RolesPermissions = 'RolesPermissions',
  SaleTransactions = 'SALE_TRANSACTIONS',
  Services = 'Services',
  ServicesCategories = 'ServicesCategories',
  Sizes = 'DimensionSizes',
  Stats = 'STATS',
  Stripe = 'STRIPE',
  Styles = 'DimensionStyles',
  Subscriptions = 'UserSubscriptions',
  TopCategoriesExpense = 'TOP_CATEGORIES_EXPENSE',
  TopCategoriesSale = 'TOP_CATEGORIES_SALE',
  Transactions = 'TRANSACTIONS',
  TransactionCategories = 'TRANSACTION_CATEGORIES',
  TransactionDetails = 'TRANSACTION_DETAILS',
  User = 'USER',
  UserOrganizations = 'USER_ORGANIZATIONS',
  Users = 'USERS',
  VoiceCommands = 'VOICE_COMMANDS',
  WebTransactions = 'WEB_TRANSCTIONS',
  WhatsNew = 'WhatsNew',
}
