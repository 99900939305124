import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type IProps = {
  name: string;
  int?: boolean;
  'aria-label'?: string;
};

type Props = IProps & TextFieldProps;

export const exceptThisSymbols = ['e', 'E', '+', '-', '.'];

export default function RHFTextField({
  name,
  type,
  int,
  'aria-label': ariaLabel,
  ...other
}: Props) {
  const [visibility, setVisibility] = useState(false);
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          type={type === 'password' && !visibility ? 'password' : 'text'}
          fullWidth
          value={
            typeof field.value === 'number' && field.value === 0
              ? ''
              : field.value
          }
          error={!!error}
          helperText={error?.message}
          inputProps={{
            'aria-label': ariaLabel,
          }}
          InputProps={{
            endAdornment:
              type === 'password' ? (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    aria-label="toggle password visibility"
                    onClick={() => setVisibility((prev) => !prev)}
                    edge="end"
                  >
                    {visibility ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ) : null,
          }}
          onKeyDown={
            int
              ? (e) => exceptThisSymbols.includes(e.key) && e.preventDefault()
              : undefined
          }
          {...other}
        />
      )}
    />
  );
}
