import { ReactElement, cloneElement } from 'react';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';

import LoadingScreen from 'src/components/LoadingScreen';
import useAuth from 'src/hooks/useAuth';
import SignUp from 'src/pages/auth/SignUp';
import { PATH_DASHBOARD } from 'src/routes/paths';

type InvitesGuardProps = {
  children: ReactElement;
};

export const InvitesGuard: React.FC<InvitesGuardProps> = ({ children }) => {
  const { isAuthenticated, stepTwo, isInitialized } = useAuth();
  const { code } = useParams();
  const [searchParams] = useSearchParams();
  const organization = searchParams.get('organization');
  if (code && organization) {
    try {
      localStorage.setItem('join_code', code);
      localStorage.setItem('join_organization', organization);
    } catch (error) {
      console.log(error);
    }
  }

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    return <SignUp />;
  }

  if (isAuthenticated && stepTwo) {
    return <Navigate to={PATH_DASHBOARD.general.finishRegistration} />;
  }

  return children ? cloneElement(children, { code, organization }) : <div />;
};
