import { AxiosError } from 'axios';
import { OptionsObject, useSnackbar } from 'notistack';

import { RailsError } from 'src/@types/railsError';

export const useOnError = () => {
  const { enqueueSnackbar } = useSnackbar();

  const snackBarOptions: OptionsObject = {
    variant: 'error',
  };

  const onError = (error: AxiosError<RailsError>) => {
    if (error.response?.data.errors) {
      enqueueSnackbar(error.response?.data.errors.join(', '), snackBarOptions);
    } else if (error.response?.data.error) {
      enqueueSnackbar(error.response?.data.error, snackBarOptions);
    } else {
      enqueueSnackbar('Ocurrió un error inesperado', snackBarOptions);
    }
  };

  return {
    onError,
  };
};
