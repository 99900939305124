import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { CabalPlusForm } from './Form';
import { SubscriptionOption } from './JoinCabalPlus/data/subscriptionTypes';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);
type Props = {
  clientSecret: string;
  selectedPlan: SubscriptionOption;
  isDirectBuy?: boolean;
};

export const StripeElements = ({
  clientSecret,
  selectedPlan,
  isDirectBuy,
}: Props) => {
  console.log(clientSecret);
  return (
    <div data-testid="stripe-form" style={{ marginBottom: 20 }}>
      <Elements
        stripe={stripePromise}
        options={{
          clientSecret,
          locale: 'es',
        }}
      >
        <CabalPlusForm isDirectBuy={isDirectBuy} selectedPlan={selectedPlan} />
      </Elements>
    </div>
  );
};
