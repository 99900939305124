import { Grid } from '@mui/material';

interface Props<T> {
  data: T[];
  renderItem: (item: T, idx: number) => React.ReactNode;
  keyExtractor?: (item: T) => string | number;
  leading?: React.ReactNode;
  trailing?: React.ReactNode;
  leadingGridItemProps?: React.ComponentProps<typeof Grid>;
  trailingGridItemProps?: React.ComponentProps<typeof Grid>;
  gridProps?: React.ComponentProps<typeof Grid>;
  gridItemProps?: React.ComponentProps<typeof Grid>;
}

export const List = <T extends unknown>({
  renderItem,
  data,
  keyExtractor,
  gridProps = {},
  gridItemProps = {},
  leading,
  trailing,
  leadingGridItemProps,
  trailingGridItemProps,
}: Props<T> & React.HTMLAttributes<HTMLDivElement>) => {
  const leadingGIP = { ...gridItemProps, ...leadingGridItemProps };
  const trailingGIP = { ...gridItemProps, ...trailingGridItemProps };

  return (
    <Grid container spacing={2} {...gridProps}>
      {leading && (
        <Grid item {...leadingGIP}>
          {leading}
        </Grid>
      )}
      {data.map((item, idx) => (
        <Grid
          item
          {...gridItemProps}
          key={keyExtractor ? keyExtractor(item) : idx}
        >
          {renderItem(item, idx)}
        </Grid>
      ))}
      {trailing && (
        <Grid item {...trailingGIP}>
          {trailing}
        </Grid>
      )}
    </Grid>
  );
};
