import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

export const useErrorReport = () => {
  const location = useLocation();
  const { mutateAsync } = useMutation({
    mutationKey: ['ErrorBoundary'],
    mutationFn: (content: string) =>
      axios.post(process.env.REACT_APP_DISCORD_BUGS_WEBHOOK_URL as string, {
        content,
      }),
  });

  const reportError = (error: Error) => {
    let report = `${location.pathname} \n${error.message} \n${error.stack}`;
    report = report.substring(0, 500);
    mutateAsync(report);
  };

  return {
    reportError,
  };
};
