import {
  cabalApi,
  PostUsersAndroidApiArg,
  PostUsersIosApiArg,
} from './cabalApi';

export type PostUsersAndroidApiResponse = {
  accessToken: string;
  step_two: boolean;
};

export type PostUsersIosApiResponse = {
  accessToken: string;
  step_two: boolean;
};

const api = cabalApi.enhanceEndpoints({
  addTagTypes: [
    'Products',
    'Product',
    'ProductCategories',
    'ProductCategory',
    'TransactionCategories',
    'TransactionCategory',
    'Contacts',
    'Contact',
    'SaleTransaction',
    'ExpenseTransaction',
  ],
  endpoints: {
    // PRODUCTS
    getV1Products: {
      providesTags: ['Products'],
    },
    getV1ProductsById: {
      providesTags: ['Product'],
    },
    patchV1ProductsById: {
      invalidatesTags: ['Products', 'Product'],
    },
    postV1Products: {
      invalidatesTags: ['Products', 'Product'],
    },
    deleteV1ProductsById: {
      invalidatesTags: ['Products', 'Product'],
    },
    // PRODUCT CATEGORIES
    getV1ProductCategories: {
      providesTags: ['ProductCategories'],
    },
    getV1ProductCategoriesById: {
      providesTags: ['ProductCategory'],
    },
    postV1ProductCategories: {
      invalidatesTags: ['ProductCategories', 'ProductCategory'],
    },
    patchV1ProductCategoriesById: {
      invalidatesTags: ['ProductCategories', 'ProductCategory'],
    },
    deleteV1ProductCategoriesById: {
      invalidatesTags: ['ProductCategories', 'ProductCategory'],
    },
    // TRANSACTION CATEGORIES
    getV1TransactionCategories: {
      providesTags: ['TransactionCategories'],
    },
    getV1TransactionCategoriesById: {
      providesTags: ['TransactionCategory'],
    },
    postV1TransactionCategories: {
      invalidatesTags: ['TransactionCategories', 'TransactionCategory'],
    },
    patchV1TransactionCategoriesById: {
      invalidatesTags: ['TransactionCategories', 'TransactionCategory'],
    },
    deleteV1TransactionCategoriesById: {
      invalidatesTags: ['TransactionCategories', 'TransactionCategory'],
    },
    // CONTACTS
    getV1Contacts: {
      providesTags: ['Contacts'],
    },
    getV1ContactsById: {
      providesTags: ['Contact'],
    },
    postV1Contacts: {
      invalidatesTags: ['Contacts', 'Contact'],
    },
    patchV1ContactsById: {
      invalidatesTags: ['Contacts', 'Contact'],
    },
    deleteV1ContactsById: {
      invalidatesTags: ['Contacts', 'Contact'],
    },
    // SALE TRANSACTIONS
    getV1SalesTransactionsById: {
      providesTags: ['SaleTransaction'],
    },
    // EXPENSE TRANSACTIONS
    getV1ExpensesTransactionsById: {
      providesTags: ['ExpenseTransaction'],
    },
    // DEPOSITS
    postV1DebtsTransactionsByDebtsTransactionIdDeposits: {
      invalidatesTags: ['SaleTransaction', 'ExpenseTransaction'],
    },
  },
});

const injectedApi = api.injectEndpoints({
  endpoints: (build) => ({
    postUsersAndroid: build.mutation<
      PostUsersAndroidApiResponse,
      PostUsersAndroidApiArg
    >({
      query: (queryArg) => ({
        url: `/users/android`,
        method: 'POST',
        body: queryArg.body,
      }),
      transformResponse(_response: { step_two: boolean }, meta) {
        return {
          accessToken: meta?.response?.headers?.get('Authorization') as string,
          step_two: _response.step_two,
        };
      },
    }),
    postUsersIos: build.mutation<PostUsersIosApiResponse, PostUsersIosApiArg>({
      query: (queryArg) => ({
        url: `/users/ios`,
        method: 'POST',
        body: queryArg.body,
      }),
      transformResponse(_response: { step_two: boolean }, meta) {
        return {
          accessToken: meta?.response?.headers?.get('Authorization') as string,
          step_two: _response.step_two,
        };
      },
    }),
  }),
  overrideExisting: true,
});

export { api };
export const { usePostUsersAndroidMutation, usePostUsersIosMutation } =
  injectedApi;
