import { fetchAndActivate, getAll } from '@firebase/remote-config';
import { ReactNode, createContext, useEffect, useState } from 'react';

import {
  RemoteConfig,
  RemoteConfigSetting,
} from 'src/@types/CabalRemoteConfig';
import { remoteConfig } from 'src/firebase';

export const defaultRemoteConfig: RemoteConfig = {
  show_ai: false,
};

const RemoteConfigContext = createContext(defaultRemoteConfig);

type Props = {
  defaults: RemoteConfig;
  children: ReactNode;
};

const RemoteConfigProvider = ({ defaults, children }: Props) => {
  const [settings, setSettings] = useState<RemoteConfig>(defaults);

  useEffect(() => {
    fetchAndActivate(remoteConfig)
      .then((activated) => {
        if (!activated) console.log('not activated');
        return getAll(remoteConfig);
      })
      .then((remoteSettings) => {
        console.log('Remote settings load done...');
        let newSettings: Partial<RemoteConfig> = {};

        for (const [key, config] of Object.entries(remoteSettings)) {
          newSettings[key as RemoteConfigSetting] = config.asString();
        }

        setSettings(newSettings as unknown as RemoteConfig);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <RemoteConfigContext.Provider value={settings}>
      {children}
    </RemoteConfigContext.Provider>
  );
};

export { RemoteConfigProvider, RemoteConfigContext };
