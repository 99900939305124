import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';

import Logo from 'src/components/Logo';
import Page from 'src/components/Page';
import useResponsive from 'src/hooks/useResponsive';
import { SignUpManager } from 'src/sections/auth/SignUpManager';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    backgroundColor: theme.palette.background.neutral,
    minHeight: '100vh',
  },
}));

const SectionStyle = styled(Card)(({ theme }) => {
  return {
    borderRadius: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgb(148,43,246)',
    background: `url('/assets/referrals_bg.jpg')`,
    backgroundSize: 'cover',
    // background:
    //   'linear-gradient(202deg, rgba(148,43,246,1) 0%, rgba(60,9,108,1) 100%)',
    margin: theme.spacing(0, 0, 0, 0),
  };
});

const CustomButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(2, 2, 0, 0),
  padding: theme.spacing(4, 4, 4, 4),
  fontSize: 16,
}));

const CTACard = styled(Card)(({ theme }) => ({
  background: 'rgba(255,255,255,0.75)',
  boxShadow: 'none',
  width: '100%',
  maxWidth: 500,
}));

const ContentStyle = styled('div')(({ theme }) => {
  const downTo = theme.breakpoints.down('sm');

  return {
    maxWidth: 720,
    margin: 'auto',
    minHeight: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(downTo ? 8 : 12, 0),
    '#login-buttons button': {
      display: 'flex !important',
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '8px !important',
      overflow: 'hidden !important',
    },
    '#login-buttons button > div': {
      marginRight: '0 !important',
    },
  };
});

export default function SignIn() {
  const mdUp = useResponsive('up', 'md');
  const [showJoinOrganizationMessage, setShowJoinOrganizationMessage] =
    useState('');

  useEffect(() => {
    try {
      const code = localStorage.getItem('join_code');
      const organization = localStorage.getItem('join_organization');
      if (code && organization) setShowJoinOrganizationMessage(organization);
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <Page
      title="Crear Cuenta"
      simpleLayoutProps={{
        showLogo: false,
        fullWidth: true,
      }}
    >
      <RootStyle>
        <Container maxWidth="lg">
          <ContentStyle>
            <Box style={{ marginBottom: 20 }}>
              <Logo />
            </Box>

            {showJoinOrganizationMessage && (
              <Alert severity="info">
                Por favor inicia sesión o crea una cuenta para unirte a{' '}
                <strong>{showJoinOrganizationMessage}</strong>
              </Alert>
            )}
            <div style={{ marginTop: 20 }}>
              <SignUpManager />
            </div>
          </ContentStyle>
        </Container>

        <SectionStyle
          style={{ maxWidth: mdUp ? 550 : '100%', padding: mdUp ? 40 : 20 }}
        >
          <CTACard elevation={8}>
            <CardContent
              style={{
                padding: mdUp ? '60px 60px 0 60px' : '30px 30px 0 30px',
              }}
            >
              <Typography variant="h4" marginBottom={2}>
                ¡Gana con Cabal!
              </Typography>
              <Typography variant="body1" marginBottom={3}>
                Refiere a otros que utilicen Cabal y si ellos se convierten en
                Plus,{' '}
                <span style={{ textDecoration: 'underline' }}>
                  te llevas el 40% de comisión ¡todos los meses!
                </span>
              </Typography>
              <CustomButton
                href="https://cabalplus.tolt.io/login"
                variant="contained"
                color="primary"
                fullWidth
              >
                ¡Haz click y comienza a ganar!
              </CustomButton>
            </CardContent>
          </CTACard>
        </SectionStyle>
      </RootStyle>
    </Page>
  );
}
