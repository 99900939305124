// routes
import DeleteDialog from './components/DeleteDialog';
import NotistackProvider from './components/NotistackProvider';
import { ProgressBarStyle } from './components/ProgressBar';
import ScrollToTop from './components/ScrollToTop';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { ChartStyle } from './components/chart';
import { PageProvider } from './contexts/PageContext';
// components
// import ThemeSettings from './components/settings';
import Router from './routes';
// theme
import ThemeProvider from './theme';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        {/* <ThemeSettings> */}
        <NotistackProvider>
          <PageProvider>
            <ProgressBarStyle />
            <ChartStyle />
            <ScrollToTop />
            <Router />
            <DeleteDialog />
          </PageProvider>
        </NotistackProvider>
        {/* </ThemeSettings> */}
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
