import { TextField, TextFieldProps } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import { Controller, useFormContext } from 'react-hook-form';

type Props = {
  name: string;
  label: string;
  inputFormat?: string;
  disabled?: boolean;
  textFieldProps?: TextFieldProps;
  id?: string;
};

const RHFDateTimePicker = ({
  name,
  label,
  inputFormat = 'hh:mm a',
  disabled,
  textFieldProps,
  id,
}: Props) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <TimePicker
            {...field}
            minTime
            disabled={disabled}
            label={label}
            ampm
            inputFormat={inputFormat}
            value={field.value}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                error={!!error}
                helperText={error?.message}
                {...textFieldProps}
                id={id}
              />
            )}
          />
        );
      }}
    />
  );
};

export default RHFDateTimePicker;
