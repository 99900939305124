import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'moment/locale/es';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import 'react-image-lightbox/style.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import OneSignal from 'react-onesignal';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';
import 'simplebar/src/simplebar.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import App from './App';
import { ErrorFallback } from './components/ErrorFallback';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import { AuthProvider } from './contexts/JWTContext';
import { RemoteConfigProvider } from './contexts/RemoteConfig';
import { SettingsProvider } from './contexts/SettingsContext';
import './firebase';
import './locales/i18n';
import './printStyles.css';
import { persistor, store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// OneSignal
OneSignal.init({
  appId: process.env.REACT_APP_ONESIGNAL_APP_ID as string,
  allowLocalhostAsSecureOrigin: true,
}).then(() => {
  try {
    OneSignal.Slidedown.promptPush();
  } catch (error) {
    console.log(error);
  }
});

// Sentry
Sentry.init({
  dsn: 'https://48fac6422e654617a092aa55310dc927@o1124041.ingest.sentry.io/4504142479884288',
  environment: process.env.REACT_APP_SENTRY_ENV,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  enabled: process.env.NODE_ENV === 'production',
});

// ----------------------------------------------------------------------

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <ReduxProvider store={store}>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <HelmetProvider>
          <PersistGate loading={null} persistor={persistor}>
            <SettingsProvider>
              <CollapseDrawerProvider>
                <BrowserRouter>
                  <RemoteConfigProvider
                    defaults={{
                      show_ai: false,
                    }}
                  >
                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                      <App />
                    </ErrorBoundary>
                  </RemoteConfigProvider>
                </BrowserRouter>
              </CollapseDrawerProvider>
            </SettingsProvider>
          </PersistGate>
        </HelmetProvider>
      </AuthProvider>
    </QueryClientProvider>
  </ReduxProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
