import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

import { ActionButton as ActionButtonType } from 'src/@types/notifications';

type Props = {
  launchUrl: ActionButtonType['launchURL'];
  text: ActionButtonType['text'];
} & React.ComponentProps<typeof Button>;

export function ActionButton({ launchUrl, text, ...buttonProps }: Props) {
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => navigate(launchUrl as string)}
      color="primary"
      size="small"
      variant="contained"
      {...buttonProps}
    >
      {text}
    </Button>
  );
}
