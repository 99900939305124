import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { AskForCabalPlus } from 'src/components/AskForCabalPlus';
import { JoinCabalPlus } from 'src/components/CabalPlusCTA/JoinCabalPlus';
import { hasOffersEnabled } from 'src/components/CabalPlusCTA/JoinCabalPlus/data/subscriptionTypes';
import { usePermissions } from 'src/hooks/usePermissions';

type Props = {
  children: React.ReactNode;
  hasContent?: boolean;
};

export const CabalPlusGuard: React.FC<Props> = ({ hasContent, children }) => {
  const { is, has } = usePermissions();
  const enableOffers = hasOffersEnabled();

  if (is('owner') && !has('paid_access')) {
    return hasContent ? (
      <Card
        style={{
          maxWidth: 800,
          margin: '0 auto',
        }}
      >
        <CardContent>
          <JoinCabalPlus enableOffers={enableOffers} />
        </CardContent>
      </Card>
    ) : null;
  }

  if (is('guest') && !has('paid_access')) {
    return hasContent ? <AskForCabalPlus /> : null;
  }

  return <>{children}</>;
};
