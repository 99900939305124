// hooks
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar, { Props as AvatarProps } from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: AvatarProps) {
  const { user } = useAuth();

  return (
    <Avatar
      src={user?.current_organization?.avatar ?? ''}
      alt={user?.user.first_name as string}
      color={
        user?.current_organization?.avatar
          ? 'default'
          : createAvatar(user?.user.first_name as string).color
      }
      {...other}
    >
      {createAvatar(user?.current_organization?.name as string).name}
    </Avatar>
  );
}
