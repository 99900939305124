/**
 * This component MUST be used in all forms to prevent Google Translate to crash
 * Cabal.
 * SENTRY ISSUE: https://ebitdash-llc.sentry.io/issues/3735416619/?environment=production&project=4504142479884288&query=is%3Aarchived&referrer=issue-stream&statsPeriod=14d&stream_index=0&utc=true
 * FIX: https://github.com/facebook/react/issues/11538#issuecomment-390386520
 */
import { LoadingButton } from '@mui/lab';

type Props = Omit<React.ComponentProps<typeof LoadingButton>, 'children'> & {
  label: string;
};

export const SubmitButton = ({ label, ...props }: Props) => {
  return (
    <LoadingButton {...props}>
      <span>{label}</span>
    </LoadingButton>
  );
};
