import { Container, Typography } from '@mui/material';
import { m } from 'framer-motion';

import { MotionContainer, varBounce } from './animate';

export const AskForCabalPlus = () => (
  <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
    <m.div variants={varBounce().in}>
      <div style={{ width: 300, margin: '0 auto' }} />
      <Typography
        variant="h4"
        color="secondary"
        marginTop={-3}
        marginBottom={2}
      >
        Autorización requerida
      </Typography>
    </m.div>

    <m.div variants={varBounce().in}>
      <Typography>
        Por favor solicita acceso a{' '}
        <span style={{ fontWeight: 'bold' }}>Cabal Plus</span>
        <br />
        al dueño de la organización.
      </Typography>
    </m.div>
  </Container>
);
