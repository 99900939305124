import { PermissionDeniedMessage } from 'src/components/PermissionDeniedMessage';

import useAuth from '../hooks/useAuth';

type RoleBasedGuardProp = {
  hasContent?: boolean;
  roles?: string[];
  children: React.ReactNode;
};

export default function RoleBasedGuard({
  hasContent,
  roles,
  children,
}: RoleBasedGuardProp) {
  const { user } = useAuth();

  const currentRole = user?.current_organization?.main_role as string;

  if (typeof roles !== 'undefined' && !roles.includes(currentRole)) {
    return hasContent ? <PermissionDeniedMessage /> : null;
  }

  return <>{children}</>;
}
